<template>
  <section class="legal">
    <div class="legal__title">
      <h1 class="font--h3" v-html="$t('legal.terms.page_title')"></h1>
      <h2 class="font--b5">
        {{
          $t("legal.terms.page_update_date", {
            date: new Intl.DateTimeFormat("en-US").format(new Date())
          })
        }}
      </h2>
    </div>
    <section class="legal__info">
      <legal-sidebar
        v-if="!$device.isMobile"
        ref="sideBarRef"
        :titles="headers"
        @click-title="handleClickTitle"
      />
      <base-separator
        v-if="!$device.isMobile"
        vertical
        :style="{ height: sideBarNode?.sideBarRef?.clientHeight + 'px' }"
      />
      <div class="text">
        <span class="text__paragraph" id="0">
          <h5 class="font--h4">{{ $t("legal.common.intro") }}</h5>
          <p class="font--b2" v-html="$t('legal.terms.paragraphs.0.text')"></p>
        </span>
        <span class="text__paragraph" id="1">
          <h5 class="font--h4" v-html="$t('legal.terms.headers.1')"></h5>
          <span>
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.1.text')"
            ></p>
            <ul class="ul-style ul-style--dots font--b2-4" v-html="$t('legal.terms.ul.0')">
            </ul>
            <br />
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.2.text')"
            ></p>
            <ul class="ul-style ul-style--dots font--b2-4" v-html="$t('legal.terms.ul.1')"></ul>
            <br />
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.3.text')"
            ></p>
          </span>
        </span>
        <span class="text__paragraph" id="2">
          <h5 class="font--h4" v-html="$t('legal.terms.headers.2')"></h5>
          <span>
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.4.text')"
            ></p>
            <ul class="ul-style ul-style--num font--b2-4" v-html="$t('legal.terms.ul.2')"></ul>
            <br />
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.5.text')"
            ></p>
          </span>
        </span>
        <span class="text__paragraph" id="3">
          <h5 class="font--h4" v-html="$t('legal.terms.headers.3')"></h5>
          <span>
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.6.text')"
            ></p>
            <ul class="ul-style ul-style--dots font--b2-4" v-html="$t('legal.terms.ul.3')"></ul>
            <br />
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.7.text')"
            ></p>
            <ul class="ul-style ul-style--dots font--b2-4" v-html="$t('legal.terms.ul.4')"></ul>
            <br />
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.9.text')"
            ></p>
          </span>
        </span>
        <span class="text__paragraph" id="4">
          <h5 class="font--h4" v-html="$t('legal.terms.headers.4')"></h5>
          <span>
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.10.text')"
            ></p>
            <ul class="ul-style ul-style--dots font--b2-4" v-html="$t('legal.terms.ul.5')"></ul>
            <br />
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.11.text')"
            ></p>
          </span>
        </span>
        <span class="text__paragraph" id="5">
          <h5 class="font--h4" v-html="$t('legal.terms.headers.5')"></h5>
          <span>
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.12.text')"
            ></p>
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.13.text')"
            ></p>
          </span>
        </span>
        <span class="text__paragraph" id="6">
          <h5 class="font--h4" v-html="$t('legal.terms.headers.6')"></h5>
          <span>
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.14.text')"
            ></p>
            <ul class="ul-style ul-style--dots font--b2-4" v-html="$t('legal.terms.ul.6')"></ul>
            <br />
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.15.text')"
            ></p>
          </span>
        </span>
        <span class="text__paragraph" id="7">
          <h5 class="font--h4" v-html="$t('legal.terms.headers.7')"></h5>
          <span>
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.16.text')"
            ></p>
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.17.text')"
            ></p>
          </span>
        </span>
        <span class="text__paragraph" id="8">
          <h5 class="font--h4" v-html="$t('legal.terms.headers.8')"></h5>
          <span>
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.18.text')"
            ></p>
            <ul class="ul-style ul-style--dots font--b2-4" v-html="$t('legal.terms.ul.7')"></ul>
            <br />
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.19.text')"
            ></p>
          </span>
        </span>
        <span class="text__paragraph" id="9">
          <h5 class="font--h4" v-html="$t('legal.terms.headers.9')"></h5>
          <span>
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.20.text')"
            ></p>
            <ul class="ul-style ul-style--dots font--b2-4" v-html="$t('legal.terms.ul.8')"></ul>
          </span>
        </span>
        <span class="text__paragraph" id="10">
          <h5 class="font--h4" v-html="$t('legal.terms.headers.10')"></h5>
          <span>
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.21.text')"
            ></p>
          </span>
        </span>
        <span class="text__paragraph" id="11">
          <h5 class="font--h4" v-html="$t('legal.terms.headers.11')"></h5>
          <span>
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.22.text')"
            ></p>
          </span>
        </span>
        <span class="text__paragraph" id="12">
          <h5 class="font--h4" v-html="$t('legal.terms.headers.12')"></h5>
          <span>
            <p
              class="font--b2"
              v-html="$t('legal.terms.paragraphs.23.text', {email:'hi@rwa-estate.com '})"
            ></p>
          </span>
        </span>
      </div>
    </section>
  </section>
</template>

<script setup lang="ts">
const { tm } = useI18n()

const sideBarNode = useTemplateRef("sideBarRef")

const handleClickTitle = (val: string) => {
  if (val) {
    const element = document.getElementById(val)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }
}

const headers = computed(() => {
  const headersList = tm("legal.terms.nav") as unknown as {
    title: string
  }[]
  return headersList ?? []
})
</script>

<style scoped lang="scss"></style>
