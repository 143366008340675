<template>
  <div class="filter__butts">
    <span class="filter__butts-label font--b6 text-black-monochrome">{{
      props.filterLabel
    }}</span>
    <span
      class="filter__butts-flex"
      :class="{ 'filter-butts-mobile': props.isMobile }"
    >
      <button
        v-for="item in props.buttsArray"
        class="butt font--b6"
        :class="{ 'butt-active': valueArray.includes(item.value) }"
        @click="handleButtClick(item.value)"
      >
        {{ item.label }}
      </button>
    </span>
  </div>
</template>

<script setup lang="ts">
interface Props {
  buttsArray?: {
    label: string;
    value: string | number;
  }[];
  filterLabel: string;
  isMobile?: boolean;
}
interface Emits {
  (event: "change-butts", value: any): void;
}
const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<Props>(), {
  buttsArray: () => [
    {
      label: "Any",
      value: "",
    },
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3+",
      value: 3,
    },
  ],
});

const valueArray = ref<any[]>([]);

const handleButtClick = (value: string | number) => {
  const index = valueArray.value.findIndex((item) => item == value);
  if (index !== -1) {
    valueArray.value.splice(index, 1);
  } else {
    valueArray.value.push(value);
  }
  emit("change-butts", { filter: valueArray.value.filter((item)=>item), type: props.filterLabel });
};
</script>

<style scoped lang="scss">
.filter__butts {
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: flex-start;
  gap: 8px;

  &-flex {
    height: fit-content;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    .butt {
      outline: none;
      width: fit-content;
      min-width: 28px;
      height: 25px;
      border-radius: 6px;
      border: 1px solid var(--black-monochrome-60);
      background-color: transparent;
      padding: 6px 8px;
      transition:
        box-shadow 0.3s ease,
        background-color 0.1s ease-in-out;
      cursor: pointer;
      &-active {
        color: var(--white-monochrome);
        background-color: var(--violet-main);
        border-color: transparent;
      }
      &:hover {
        box-shadow: var(--shadow-light);
      }
    }
  }
  .filter-butts-mobile {
    gap: 16px;

    .butt {
      min-width: 56px;
      height: 41px;
      padding: 14px 16px;
      border-radius: 8px;
      background: var(--white-monochrome);
      &-active {
        color: var(--white-monochrome);
        background-color: var(--violet-main);
        border-color: transparent;
      }
    }
  }
}
</style>
