<template>
  <section class="provider">
    <div class="provider__header">
      <h5 class="title-underlined font--h4 text-black-monochrome">
        {{ $t("unit_page.un_provider_title") }}
      </h5>
      <button v-close-popup class="close-butt hover-rotate">
        <Suspense>
          <base-icon name="base/Close" size="100%" />
        </Suspense>
      </button>
    </div>
    <Suspense>
      <div class="provider__redirect">
        <div>
          <base-icon name="Logo_light" class="logo" />
        </div>
        <div class="icon-redirect">
          <base-icon
            name="base/Redirect"
            size="96px"
            class="icon-redirect"
            filled
          />
        </div>
        <div class="logo-provider">
          <img :src="data.logoFile" alt="logo" />
        </div>
      </div>
    </Suspense>
    <div class="provider__image">
      <img v-if="imageArray" :src="imageArray" alt="coming-soon" />
    </div>
    <Suspense>
      <div class="promo-items">
        <div class="promo-items__item">
          <div class="promo-items__item-icon">
            <base-icon name="base/Check" size="19px" filled />
          </div>
          <div class="promo-items__item-title">
            {{ $t("unit_page.un_provider_1") }}
          </div>
        </div>
        <div class="promo-items__item">
          <div class="promo-items__item-icon">
            <base-icon name="base/Check" size="19px" filled />
          </div>
          <div class="promo-items__item-title">
            {{ $t("unit_page.un_provider_2") }}
          </div>
        </div>
        <div class="promo-items__item">
          <div class="promo-items__item-icon">
            <base-icon name="base/Check" size="19px" filled />
          </div>
          <div class="promo-items__item-title">
            {{ $t("unit_page.un_provider_3") }}
          </div>
        </div>
      </div>
    </Suspense>
    <div class="provider__buttons">
      <Suspense>
        <base-button
          v-close-popup
          class="butt"
          size="lg"
          font="b5"
          icon-name="base/Back"
          icon-size="16px"
          :text="$t('unit_page.un_provider_back')"
          variant="dark_ghost"
          style="width: 100%"
        />
      </Suspense>
      <Suspense>
        <base-button
          class="butt"
          size="lg"
          font="b5"
          icon-name="base/Continue"
          icon-size="16px"
          :text="$t('unit_page.un_provider_continue')"
          variant="dark"
          style="width: 100%"
          icon-position="right"
          @click="handleSignClick"
        />
      </Suspense>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Notification } from "~/services/notifications/toast"
import { gtagReachGoal } from "~/services/SEO/goals/createCustomGoal"
import noImage from "~/assets/img/no-image.svg"
import type { UnitFileDTO } from "~/services/swagger/Api"

interface IProps {
  data: {
    url: string
    source: keyof typeof gtagReachGoal
    images: UnitFileDTO[]
    logoFile: string
  }
}

const props = defineProps<IProps>()
const emit = defineEmits(["hide_popup"])

const handleSignClick = () => {
  if (props.data.url) {
    window.open(props.data.url, "_blank")
  } else {
    new Notification({
      type: "system",
      message: "We currently do not have a link to this provider.",
      timeout: 5000
    }).createNewToast()
  }
  emit("hide_popup", "ca")
  if (props.data.source) {
    gtagReachGoal[props.data.source]({
      value: props.data.url
    })
  }
}

const imageArray = computed((): string => {
  if (!props.data.images) return noImage
  const filteredImages = props.data.images
    .filter(
      (item) =>
        (item.type == "image" || item.type == "covers") &&
        !item.file.url?.endsWith("pdf")
    )
    .sort((a, b) => {
      if (a.type === "covers" && b.type !== "covers") return 1
      if (a.type !== "covers" && b.type === "covers") return -1
      return 0
    })
    .map((item) => item.file.url)
  return filteredImages.at(-1)
})
</script>

<style scoped lang="scss">
.provider {
  position: relative;
  display: inline-flex;
  min-width: 492px;
  max-width: 492px;
  height: fit-content;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border-radius: 24px;
  border: 1px solid var(--gray-40);
  background: var(--white-contrast);
  max-height: 90dvh;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-40);
    border-radius: 6px;
    border: 2px solid transparent;
  }

  @supports not selector(::-webkit-scrollbar) {
    * {
      scrollbar-color: var(--violet-secondary-light)
        var(--violet-secondary-light);
    }
  }
  @media (max-width: 65rem) {
    min-width: unset;
    width: 90vw;
  }
  &__header {
    font-size: 32px;
    text-align: center;
    width: fit-content;
    margin: auto;
    .title-underlined {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 4px;
        bottom: -1px;
        left: 0;
        background-color: var(--violet-main);
      }
    }
    .close-butt {
      position: absolute;
      right: 16px;
      top: 16px;
      width: 28px;
      height: 28px;
      margin: 0;
      outline: none;
      border: none;
      border-radius: 50%;
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      background: var(--Monochrome-White, #e9ebf8);
      cursor: pointer;
    }
  }
  &__image {
    width: 100%;
    height: 200px;
    border-radius: 24px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__redirect {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    max-width: 398px;
    margin: 0 auto;
    .logo {
      width: 155px;
      height: 20px;
      :deep(.icon) {
        width: 155px;
        height: 20px;
        svg {
          width: 155px;
          height: 20px;
        }
      }
    }
  }
  .promo-items {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    opacity: 0.3;
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
    }
    &__item-icon {
      margin-bottom: 0;
      margin-right: 12px;
    }
    &__item-title {
      font-weight: 600;
      font-size: 16px;
    }
  }
  .icon-redirect {
    width: 96px;
    margin: 0 auto;
    height: 20px;
    :deep(.icon) {
      width: 96px;
      height: 20px;
      svg {
        width: 96px;
        height: 20px;
      }
    }
  }
  .logo-provider {
    img {
      max-width: 120px;
      height: auto;
      margin-left: auto;
    }
  }
  &__text {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
  }
  &__buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 24px;
    .button {
      font-weight: 600;
    }
  }
}
@media (max-width: 65rem) {
  .provider {
    gap: 30px;
  }
  .provider .logo-provider img {
    margin: auto;
  }
  .provider__redirect {
    grid-template-columns: 1fr;
    gap: 12px;
  }
  .provider .promo-items__item-icon {
    position: relative;
    top: 3px;
  }
  .provider .icon-redirect {
    display: flex;
    height: 96px;
    transform: rotate(45deg);
    margin: 0 auto;
    .base-icon .icon {
      margin: auto;
    }
  }
  .provider .promo-items__item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    white-space: nowrap;
  }
  .provider .promo-items__item-title {
    text-wrap: initial;
  }
  @include mobile-fonts;
}

@media (max-width: 32rem) {
  @include mobile-fonts;
  .provider {
    padding: 32px 16px;
    &__image {
      overflow: visible;
      img {
        border-radius: 24px;
      }
    }
  }
}
</style>
