<template>
  <section class="chart-wrapper">
    <div class="radar-chart">
      <Radar :data="chartDataset" :options="options" />
    </div>
    <div class="chart-values">
      <span
        v-for="item in labelsData"
        :class="['item', `item__${item.class}`, 'font--b5-3']"
      >
        {{ item.label }}
        <br />
        <span class="font--b4-semi-bold">{{ item.value ?? 0 }}</span>
      </span>
    </div>
  </section>
</template>

<script setup lang="ts">
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "vue-chartjs";
import type { ChartsResponseDTO, RadarDTO } from "~/services/swagger/Api";

interface IProps {
  chartData: ChartsResponseDTO;
}

const props = defineProps<IProps>();

interface LabelData {
  label: string;
  class: string;
  value: number;
  key: string;
}

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const backendData = computed(() => {
  const obj: RadarDTO = props.chartData?.radar;
  nextTick(() => {
    updateFloatingLabels();
  });
  return {
    APR: obj?.apr,
    "Value growth": obj?.valueGrowth,
    "Token price": obj?.tokenPriceRate,
    Location: obj?.location,
    Security: obj?.security,
    Popularity: obj?.popularity,
  };
});

const updateFloatingLabels = () => {
  const obj: RadarDTO = props.chartData?.radar;
  if (!obj) return;
  Object.keys(obj).forEach((key) => {
    const index = labelsData.value.findIndex((item) => key === item.key);
    if (index === -1) return;
    labelsData.value[index].value = Math.trunc(obj[key as keyof RadarDTO] ?? 0) ;
  });
};

const labelsData = ref<LabelData[]>([
  {
    label: "APR",
    class: "apr",
    value: 0,
    key: "apr",
  },
  {
    label: "Popularity",
    class: "pop",
    value: 0,
    key: "popularity",
  },
  {
    label: "Value growth",
    class: "grow",
    value: 0,
    key: "valueGrowth",
  },
  {
    label: "Security",
    class: "sec",
    value: 0,
    key: "security",
  },
  {
    label: "Token price",
    class: "price",
    value: 0,
    key: "tokenPriceRate",
  },
  {
    label: "Location",
    class: "loc",
    value: 0,
    key: "location",
  },
]);

const labels = computed(() => Object.keys(backendData.value));
const values = computed(() => Object.values(backendData.value));

const chartDataset = computed(() => {
  return {
    labels: labels.value,
    datasets: [
      {
        label: "Dataset",
        backgroundColor: "rgba(90, 47, 207, 0.4)",
        borderColor: "rgba(107, 91, 207, 0.8)",
        pointBackgroundColor: "#6b5bcf",
        pointBorderColor: "#fff",
        pointBorderWidth: 1,
        data: values.value,
        pointRadius: 3,
        borderWidth: 1,
      },
    ],
  };
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    r: {
      border: {
        dash: [7, 7],
      },
      min: 0,
      max: 100,
      ticks: {
        display: false,
        stepSize: 25,
      },
      grid: {
        color: "#9CA3AF",
        lineWidth: 1,
      },
      angleLines: {
        color: "#9CA3AF",
        lineWidth: 1,
        borderDash: [],
      },

      pointLabels: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};
</script>

<style scoped lang="scss">
.chart-wrapper {
  position: relative;
  width: 282px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  .radar-chart {
    width: 124px;
    height: 132px;
  }
  .chart-values {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    .item {
      text-align: center;
      &__apr {
        grid-column: span 3;
        margin-bottom: 5%;
      }
      &__pop,
      &__sec {
        grid-column: span 2;
        justify-self: flex-start;
      }

      &__loc {
        grid-column: span 3;
        justify-self: center;
      }
      &__grow,
      &__price {
        justify-self: flex-end;
      }
    }
  }
}
</style>
