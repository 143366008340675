<template>
  <section class="custom__calculator">
    <span class="custom__calculator-butt font--b5-2 text-black-monochrome">
      {{ $t("unit_page.un_calculator") }}
    </span>
    <div class="custom__calculator-amount">
      <span class="info-flexbox">
        <p class="font--b4 text-black-monochrome">
          {{ $t("unit_page.un_amount") }}
        </p>
        <base-tooltip :tooltip-text="$t('calculator.calc_invest_amount')">
          <template #icon>
            <base-icon
              name="base/Info"
              size="16px"
              color="var(--black-monochrome-60)"
            />
          </template>
        </base-tooltip>
      </span>
      <q-input
        v-model="amountInput"
        class="custom__calculator-amount__input"
        type="text"
        outlined
        :disabled="!props.tokenPrice"
        :placeholder="
          !props.tokenPrice
            ? `Calculator dosen't work if token price is zero`
            : '$100'
        "
        :maxlength="10"
        @update:model-value="
          () => {
            $nextTick(() => {
              const newVal = onlyNumbers(amountInput, true);
              amountInput = newVal ? newVal : undefined;
            });
          }
        "
      >
      </q-input>
      <p
        v-if="amountInput != null"
        class="font--b4 text-black-monochrome"
        :class="{
          'text-negative-sec': !checkIfCanInvestMore.canMore || investIsToSmall,
        }"
      >
        {{
          !checkIfCanInvestMore.canMore
            ? $t("unit_page.un_reach_max_invest", {
                amount: checkIfCanInvestMore.finalDifference,
              })
            : $t("unit_page.un_small_invest")
        }}
      </p>
    </div>

    <div class="custom__calculator-slider">
      <span class="info-flexbox">
        <p class="font--b4 text-black-monochrome">
          {{ $t("unit_page.un_period") }}
        </p>
        <base-tooltip :tooltip-text="$t('calculator.calc_invest_period')">
          <template #icon>
            <base-icon
              name="base/Info"
              size="16px"
              color="var(--black-monochrome-60)"
            />
          </template>
        </base-tooltip>
      </span>

      <span class="slider-tips">
        <p
          class="font--b5 text-black-monochrome"
          :style="{ opacity: range >= 16 ? 1 : 0.1 }"
        >
          <strong>3</strong>/{{ $t("unit_page.un_months") }}
        </p>
        <p
          class="font--b5 text-black-monochrome"
          :style="{ opacity: range <= 49 ? 1 : 0.1 }"
        >
          <strong>5</strong>/{{ $t("unit_page.un_years") }}
        </p>
      </span>

      <vue-slider
        v-model="range"
        :min="3"
        :max="60"
        :dot-size="10"
        :height="4"
        tooltip="always"
      >
        <template #tooltip>
          <div class="tooltip">
            <p class="font--b5 text-black-monochrome">{{ tooltipFormatter }}</p>
          </div>
        </template></vue-slider
      >
    </div>
    <base-separator />
    <div class="custom__calculator-investments">
      <span class="flexbox">
        <p class="font--b5 text-black-monochrome">
          {{ $t("unit_page.un_tokens_you_will_get") }}
        </p>
        <p class="font--b3-2 text-violet-main">
          {{ tokensWillGet }}
        </p>
      </span>
      <span class="flexbox">
        <p class="font--b5 text-black-monochrome">
          {{ $t("unit_page.un_actual_investment_amount") }}
        </p>
        <p class="font--b3-2 text-violet-main">
          {{
            BASE_CURRENCY.symbol +
            formatCalcNumber(investmentAmount, isLocalCurrency)
          }}
        </p>
      </span>
    </div>
    <base-separator />
    <div class="custom__calculator-projected">
      <div class="projected-box">
        <span class="info-flexbox">
          <p class="font--b5 text-black-monochrome">
            {{ $t("unit_page.un_projected_total_income") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.ProjectedTotalIncome')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <p class="font--b5-1 text-black-monochrome">
          {{
            BASE_CURRENCY.symbol +
            formatCalcNumber(rentalIncome + appreciation, isLocalCurrency)
          }}
        </p>
      </div>
      <div class="projected-box">
        <span class="info-flexbox">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_projected_rental_income") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.ProjectedRentalIncome')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <p class="font--b5-1 text-black-monochrome">
          {{
            BASE_CURRENCY.symbol +
            formatCalcNumber(rentalIncome, isLocalCurrency)
          }}
        </p>
      </div>
      <div class="projected-box">
        <span class="info-flexbox">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_projection_appreciation") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.ProjectionAppreciation')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <p class="font--b5-1 text-black-monochrome">
          {{
            BASE_CURRENCY.symbol +
            formatCalcNumber(appreciation, isLocalCurrency)
          }}
        </p>
      </div>
    </div>
    <base-separator />
    <div class="custom__calculator-final">
      <p class="font--b5 text-black-monochrome">
        {{ $t("unit_page.un_final_balance") }}
      </p>
      <p class="font--b3-2 text-black-monochrome">
        {{
          BASE_CURRENCY.symbol + formatCalcNumber(finalAmount, isLocalCurrency)
        }}
      </p>
    </div>
    <base-separator />
    <div class="custom__calculator-warning">
      <p class="font--b5 text-orange-base">
        {{ $t("unit_page.un_data_based_on_partners") }}
      </p>
    </div>
  </section>
</template>

<script setup lang="ts">
import VueSlider from "vue-3-slider-component";
import { onlyNumbers } from "~/utilities/helpers/format-data/onlyNumbers";
import { formatPrice } from "~/utilities/helpers/format-data/number";
import { BASE_CURRENCY } from "~/composables/CURRENCIES";

interface IProps {
  tokenPrice?: number | string;
  rentalIncome?: number | string;
  appreciation?: number | string;
  minInvestAmount: number | null | undefined;
  availableTokens: number | null | undefined;
  propertyPrice: number | null | undefined;
  currency?: string | null | undefined;
}

const props = withDefaults(defineProps<IProps>(), {
  tokenPrice: 0,
  rentalIncome: 0,
  appreciation: 0,
});
const { t } = useI18n();

const isLocalCurrency = computed(
  () => props?.currency !== BASE_CURRENCY.value.code
);

const amountInput = ref<number | string | null>(null);
const range = ref<number>(36);
const tooltipFormatter = computed(() => {
  if (range.value % 12 === 0) {
    return `${range.value / 12}/${t("unit_page.un_years")}`;
  } else {
    return `${range.value}/${t("unit_page.un_months")}`;
  }
});

const tokensWillGet = computed(() => {
  if (amountInput.value && props.tokenPrice) {
    const result =
      parseFloat(amountInput.value.toString()) /
      parseFloat(props.tokenPrice.toString());
    return parseFloat(result.toFixed(2)) >= 1 ? Math.trunc(result) : 0;
  } else {
    return 0;
  }
});
const investmentAmount = computed(() => {
  if (props.tokenPrice) {
    const result =
      parseFloat(tokensWillGet.value.toString()) *
      parseFloat(props.tokenPrice.toString());
    return result;
  } else {
    return 0;
  }
});

const rentalIncome = computed(() => {
  if (props.rentalIncome && investmentAmount.value) {
    const result =
      (parseFloat(props.rentalIncome.toLocaleString()) / 12) * range.value;
    const percentageOfInvestment = investmentAmount.value * (result / 100);
    return percentageOfInvestment;
  } else {
    return 0;
  }
});
const appreciation = computed(() => {
  if (props.appreciation && investmentAmount.value) {
    const result =
      (parseFloat(props.appreciation.toString()) / 12) * range.value;
    const percentageOfInvestment = investmentAmount.value * (result / 100);
    return percentageOfInvestment;
  } else {
    return 0;
  }
});
const finalAmount = computed(() => {
  if (rentalIncome.value && appreciation.value && amountInput.value) {
    const result =
      rentalIncome.value +
      appreciation.value +
      parseInt(amountInput.value.toString());
    return result;
  } else {
    return 0;
  }
});

const formatCalcNumber = (
  value: string | number | undefined,
  convert?: boolean
): string | number => {
  if (value === undefined) return 0;

  let numberValue = typeof value === "string" ? parseFloat(value) : value;

  if (isNaN(numberValue)) return 0;

  if (convert) {
    numberValue *= BASE_CURRENCY.value.rate;
  }

  if (numberValue >= 1_000_000) {
    const millions = (numberValue / 1_000_000).toFixed(2);
    return `${millions}M`;
  }

  return formatPrice(numberValue);
};

const checkIfCanInvestMore = computed(() => {
  if (!props.propertyPrice || !props.tokenPrice) {
    return { canMore: false, priceDifference: "0" };
  }
  const displayTokens =
    typeof props.availableTokens === "number"
      ? Math.min(Math.max(props.availableTokens, 0), 100)
      : 0;
  const tokensLeft =
    (props.propertyPrice / parseFloat(props.tokenPrice.toString())) *
    (displayTokens / 100);
  const tokensToInvest = parseFloat(tokensWillGet.value.toString());
  const canMore = tokensLeft >= tokensToInvest;
  const priceDifference = !canMore
    ? (tokensToInvest - tokensLeft) * parseFloat(props.tokenPrice.toString())
    : 0;
  const finalDifference =
    priceDifference > 1000 ? "a lot" : priceDifference.toFixed(0) + "$";

  return { canMore, finalDifference };
});

const investIsToSmall = computed(() => {
  return !parseFloat(amountInput.value?.toString())
    ? false
    : (props.minInvestAmount ?? 0) > parseFloat(amountInput.value.toString());
});
</script>

<style scoped lang="scss">
.custom__calculator {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  background: var(--white-contrast);
  box-shadow: var(--shadow-light);
  p {
    cursor: default;
  }
  @media (max-width: 59rem) {
    max-width: 345px;
  }
  &-butt {
    display: flex;
    padding: 8px 58px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    border-radius: 30px;
    background: var(--gray-monochrome-40);
  }
  &-amount {
    width: 100%;
    display: grid;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
    &__input {
      max-height: 48px;
      :deep(.q-field__control) {
        width: 100%;
        padding: 10px 24px;
        border-radius: 12px;
        border: 1px solid var(--black-monochrome-60);
        background: var(--white-contrast);
        max-height: 100%;
        &::after {
          max-height: 100%;
        }
      }
      :deep(.q-field__control-container) {
        max-height: 100%;
      }
      :deep(.q-field__native) {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        max-height: 100%;

        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        text-align: center;
        padding: 0;
      }
    }
    input {
    }
  }
  &-slider {
    width: 100%;
    margin-top: 10px;
    display: grid;
    align-items: center;
    gap: 10px;
    :deep(.vue-slider-process) {
      background: var(--violet-main);
    }
    :deep(.vue-slider-dot-handle) {
      background: var(--violet-main);
      border: 1px solid white;
    }
    .slider-tips {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        transition: opacity ease 0.2s;
      }
    }
    .tooltip {
      position: relative;
      display: inline-flex;
      padding: 2px 8px;
      align-items: center;
      gap: 10px;
      border-radius: 12px;
      border: 1px solid var(--violet-main);
      background: var(--violet-secondary-light);
      &::after {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 6px 6px 0;
        border-style: solid;
        border-color: var(--violet-main) transparent transparent transparent;
      }
    }
  }
  &-investments {
    width: 100%;
    display: grid;
    align-items: center;
    gap: 16px;
    .flexbox {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &-projected {
    display: grid;
    align-items: center;
    gap: 16px;
    width: 100%;
    .projected-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &-final {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .info-flexbox {
    display: flex;
    align-items: center;
    gap: 6px;
    p {
      white-space: nowrap;
    }
  }
}
</style>
