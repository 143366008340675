export const cleanQuery = (obj: any): any => {
  const cleanedObj: any = {};

  const convertToArray = (key: string, value: any) => {
    if (
      key.includes("[") &&
      key.includes("]") &&
      !key.includes("gte") &&
      !key.includes("lte") &&
      !key.includes("apr") &&
      !key.includes("irr")
    ) {
      const baseKey = key.split("[")[0];
      const index = parseInt(key.split("[")[1].split("]")[0], 10);

      if (!cleanedObj[baseKey]) {
        cleanedObj[baseKey] = [];
      }

      cleanedObj[baseKey][index] = value;

      return true; // Indicates that the key has been processed
    }
    return false; // Indicates that the key hasn't been processed
  };

  if (typeof obj !== "undefined") {
    Object.keys(obj).forEach((key) => {
      if (convertToArray(key, obj[key])) {
        return;
      }

      let value = obj[key];

      if (
        value &&
        typeof value === "object" &&
        Object.getPrototypeOf(value) !== Object.prototype
      ) {
        value = Array.from(value);
      }

      // Removing properties with empty objects
      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        !(Array.isArray(value) && value.length === 0) &&
        !(typeof value === "object" && Object.keys(value).length === 0)
      ) {
        if (Array.isArray(value)) {
          cleanedObj[key] = value;
        } else if (typeof value === "object") {
          const nestedCleanedObj = cleanQuery(value);
          if (Object.keys(nestedCleanedObj).length > 0) {
            cleanedObj[key] = nestedCleanedObj;
          }
        } else if (
          typeof value === "string" &&
          !isNaN(parseFloat(value.replace(/[^0-9.-]/g, "")))
        ) {
          cleanedObj[key] = value.includes("+")
            ? value
            : parseFloat(value.replace(/[^0-9.-]/g, ""));
        } else {
          cleanedObj[key] = value;
        }
      }
    });
  }

  return cleanedObj;
};
