<template>
  <section class="base-gallery animate__animated animate__fadeIn">
    <header class="base-gallery-head">
      <span></span>
      <span class="font--b1 text-white-contrast">
        {{ currentImgIndex + 1 }}/{{ totalImg }}
      </span>
      <span class="other-butts" style="justify-self: flex-end">
        <button v-close-popup class="font--b1 text-white-contrast">
          <Suspense>
            <base-icon
              name="base/Close"
              size="20px"
              color="var(--white-contrast)"
            />
          </Suspense>
          Close
        </button>
      </span>
    </header>
    <div class="base-gallery-imgbox">
      <button @click="prevImage" class="control">
        <Suspense>
          <base-icon
            name="base/ChevronLeft"
            size="20px"
            color="var(--white-contrast)"
          />
        </Suspense>
      </button>
      <div class="image">
        <NuxtImg
          v-if="photosLoaded[currentImgIndex]"
          :src="props.data?.imgs[currentImgIndex]"
        />
        <base-loader v-else></base-loader>
      </div>
      <button @click="nextImage" class="control">
        <Suspense>
          <base-icon
            name="base/ChevronRight"
            size="20px"
            color="var(--white-contrast)"
          />
        </Suspense>
      </button>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps<{
  data: { imgs: string[]; pickedPhotoIndex?: number }
}>()

const currentImgIndex = ref(0)
const totalImg = computed(() => props.data?.imgs?.length)
const photosLoaded = ref(Array(props.data.imgs?.length).fill(false))

watch(
  () => props.data.imgs,
  (newPhotos: string[]) => {
    currentImgIndex.value = props.data?.pickedPhotoIndex ?? 0
    newPhotos?.forEach((photo, index) => {
      const img = new Image()
      img.src = photo
      img.onload = () => {
        photosLoaded.value[index] = true
      }
    })
  },
  { immediate: true }
)

const nextImage = () => {
  currentImgIndex.value = (currentImgIndex.value + 1) % props.data?.imgs?.length
}

const prevImage = () => {
  currentImgIndex.value =
    (currentImgIndex.value - 1 + props.data?.imgs?.length) %
    props.data?.imgs?.length
}
</script>

<style scoped lang="scss">
.base-gallery {
  height: 100%;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  gap: 10px;
  &-head {
    width: 90%;
    max-width: 1400px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    button {
      @include drop-button-styles;
      display: flex;
      align-items: center;

      gap: 4px;
    }
  }
  &-imgbox {
    display: grid;
    grid-template-columns: 0.1fr 1fr 0.1fr;
    justify-items: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 600px;
    max-width: 1400px;
    .image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;
      img {
        object-fit: cover;
        border-radius: 5px;
        max-width: 1135px;
        max-height: 600px;
      }
    }
    .control {
      @include drop-button-styles;
      display: inline-flex;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      border: 1px solid var(--white-contrast);
      align-items: center;
      justify-content: center;
      justify-self: center;
    }
  }
}
</style>
