<template>
  <span class="sold-out-wrapper">
    <span class="card-sold-out">
      <span class="card-sold-out-text font--b5-5 text-white-contrast"
        >Sold out</span
      >
     
    </span>
    <span class="right-wrap"/>
    <span class="left-wrap"/>
  </span>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.sold-out-wrapper {
  position: absolute;
  top: -2px;
  left: -2px;
  .right-wrap,
  .left-wrap {
    content: "";
    position: absolute;
    width: 5px;
    height: 2px;
    background-color: rgba(18, 18, 18, 0.6);
    clip-path: polygon(
      100% 100%,
      0% 100%,
      42% 0%,
      58% 0%,
      76% 13%,
      88% 29%,
      100% 58%,
      100% 100%
    );
  }

  .right-wrap {
    top: 0;
    left: 96%;
  }

  .left-wrap {
    transform: scaleX(-1) rotate(90deg);
    bottom: -1px;
    right: 94%;

  }
}
.card-sold-out {
  position: relative;
  display: block;
  width: 65px;
  height: 65px;
  background-color: #ef4444;
  clip-path: polygon(0% 100%, 100% 0%, 44% 0%, 0% 44%);
  z-index: 9999;
  &-text {
    position: absolute;
    transform: rotate(-45deg);
    top: 15px;
  }
  
}
</style>
