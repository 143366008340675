<template>
  <section class="faq-primary--wrapper">
    <div class="faq-primary">
      <h1 class="font--h3 mobile-font--h5">Frequently Asked Questions</h1>
      <div class="faq-primary--box">
        <BaseFaq v-for="item in faqs" :key="item.title">
          <template #title>{{ $rt(item.title) }} </template>
          <template #details>
            {{  $rt(item.description) }}
          </template>
        </BaseFaq>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { tm } = useI18n();

const faqs = computed(() => {
  const faqList = tm("faq.list") as unknown as {
    title: string;
    description: string;
  }[];
  return faqList;
});
</script>

<style scoped lang="scss">
.faq-primary {
  margin: 0 auto;
  max-width: 1440px;
  min-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 45px 0 0;

  &--wrapper {
    width: 100dvw;
    min-width: 320px;
    margin: 0;
  }

  &--box {
    max-width: 690px;
    padding: 40px 0 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}

@media (max-width: 32rem) {
  @include mobile-fonts;

  .faq-primary {
    align-items: flex-start;
    padding: 40px 24px 160px;

    &--box {
      padding: 24px 0 0;
    }
  }
}
</style>
