<template>
  <div v-if="loading" class="profile-container__portfolio">
    <div class="top-blocks">
      <q-skeleton width="314px" height="106px" />
      <q-skeleton width="314px" height="106px" />
      <q-skeleton width="314px" height="106px" />
    </div>
    <div class="charts">
      <q-skeleton width="652px" height="312px" />
      <q-skeleton width="314px" height="312px" />
    </div>
  </div>
  <div
    v-else-if="!isNoPortfolio"
    class="profile-container__portfolio animate__animated animate__fadeIn"
  >
    <div class="top-blocks">
      <portfolio-top-block
        title="$213,240"
        subtitle="Portfolio Balance"
        is-badge
        is-majot
        :badge-value="9"
        is-up
      />
      <portfolio-top-block
        title="$678,000"
        subtitle="Projected Monthly Cashflow"
      />
      <portfolio-top-block title="6 units" subtitle="Total No. of Units" />
    </div>
    <div class="charts">
      <portfolio-charts-total-invest />
      <portfolio-charts-total-tokens />
    </div>
    <portfolio-investments />
    <section v-if="UNIT_TEST">
      <div v-if="!$device.isMobile" class="cards-grid">
        <portfolio-property-card
          v-for="unit in UNIT_TEST"
          :unit="unit"
          :key="unit.id"
        />
      </div>
      <div v-else class="cards-grid">
        <portfolio-property-card-mobile
          v-for="unit in UNIT_TEST"
          :unit="unit"
          :key="unit.id"
        />
      </div>
    </section>
  </div>
  <div
    v-else-if="!$device.isMobile"
    class="profile-container__portfolio profile-container__portfolio--nodata animate__animated animate__fadeIn"
  >
    <search-page-no-objects>
      <template #default>
        <span style="max-width: 307px; text-align: center">
          <h3 class="font--h7 mobile-font--h4 text-black-monochrome">
            {{ $t("profile.p_no_portfolio.no_props") }}
          </h3>
          <p class="font--b2 mobile-font--b2 text-black-monochrome">
            {{ $t("profile.p_no_portfolio.browse") }}
          </p>
        </span>

        <base-button
          :text="$t('profile.p_no_favorite.directory')"
          variant="dark"
          size="sg"
          icon-name="base/Right"
          icon-position="right"
          icon-size="20px"
          @click="goSearch"
        />
      </template>
    </search-page-no-objects>
    <img
      src="~/assets/img/profile/portfolioNoData.png"
      class="nodata-img"
      alt="no data"
    />
  </div>
  <div
    v-else-if="$device.isMobile"
    class="profile-container__portfolio profile-container__portfolio--nodata animate__animated animate__fadeIn"
  >
    <div class="mobile-no-portfolio">
      <img
        src="~/assets/img/profile/mobilePortfolioNoData.png"
        class="nodata-img"
        alt="no data"
      />
      <span class="mobile-no-portfolio__text">
        <h3 class="font--h7 mobile-font--h4 text-black-monochrome">
          {{ $t("profile.p_no_portfolio.no_props") }}
        </h3>
        <p class="font--b2 mobile-font--b2 text-black-monochrome">
          {{ $t("profile.p_no_portfolio.browse") }}
        </p>
      </span>

      <base-button
        :text="$t('profile.p_no_favorite.directory')"
        variant="dark"
        size="sg"
        icon-name="base/Right"
        icon-position="right"
        icon-size="20px"
        @click="goSearch"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UpdatedUnitResponseDTO } from "~/store/unitPage";
import { userStore } from "~/store/user";

interface IProps {
  TEST_UNIT: UpdatedUnitResponseDTO[];
}

const UNIT_TEST = computed(() => props.TEST_UNIT);
const useUserStore = userStore();
const localePath = useLocalePath();

const props = defineProps<IProps>();

const loading = ref(true);
const isNoPortfolio = ref(false);

const fetchPortfolio = async () => {
  loading.value = true;
  const res = await useUserStore.getUserPortfolio();
  if (!res) {
    // isNoPortfolio.value = true;
  }
  loading.value = false;
};

const goSearch = () => {
  return navigateTo(localePath("/directory"));
};

onMounted(() => {
  if (
    !useUserStore.userPortfolio ||
    Object.keys(useUserStore.userPortfolio).length === 0
  ) {
    fetchPortfolio();
  }
});
</script>

<style scoped lang="scss">
.profile-container__portfolio {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  width: 100%;
  height: fit-content;
  gap: 24px;
  &--nodata {
    grid-template-columns: 1fr 1fr;
    .search-no-objects {
      height: 100%;
      gap: 24px;
    }
    .nodata-img {
      scale: 1.04;
    }
    .mobile-no-portfolio {
      display: flex;
      max-width: 345px;
      padding: 24px 24px 40px 24px;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      border-radius: 16px;
      background: var(--white-contrast);
      box-shadow: var(--shadow-light);
      grid-column: span 2;

      &__text {
        text-align: center;
      }
    }
  }
  .top-blocks {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 24px;
  }
  .charts {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .cards-grid {
    display: grid;
    align-items: center;
    gap: 16px;
    width: 100%;
  }
}
@media (max-width: 65rem) {
  @include mobile-fonts;
  .profile-container__portfolio {
    max-width: 100dvw;
    .top-blocks {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      .portfolio__top-block--major {
        grid-column: span 2;
        max-width: 100%;
      }
      // flex-direction: column;
    }
    .charts {
      flex-direction: column;
      gap: 16px;
    }
  }
}
</style>
