<template>
  <button
    :class="['add-favorite', props.class]"
    @click="addToFavorite"
    opovertarget="popover"
  >
    <base-icon
      :name="`base/${isFavorite ? 'HeartFilled' : 'Heart'}`"
      :size="props.iconSize"
      color="var(--purple-main)"
    />
  </button>
</template>

<script setup lang="ts">
import { userStore } from "~/store/user";
import { debounce } from "lodash";
import { PopUpServices } from "~/services/PopUp/callPopUp";

const route = useRoute();
const useUserStore = userStore();
const callPopUp = PopUpServices();

const currentPath = route.path;

interface FavoriteProps {
  id?: string;
  favorite?: boolean;
  iconSize?: string;
  class?: string;
  addFunc: (id: string) => Promise<boolean>;
}

const props = defineProps<FavoriteProps>();

const isFavorite = ref<boolean>(props.favorite);
const loading = ref<boolean>(false);

const addToFavorite = async () => {
  if (!props.id) return;
  if (loading.value) {
    return;
  }
  if (useUserStore.isInSystem) {
    if (currentPath !== "/profile") {
      loading.value = true;
      const res = await props.addFunc(props.id);
      if (res) {
        isFavorite.value = !isFavorite.value;
      }
      loading.value = false;
    } else {
      debounceRemoveFavorite(props.id);
    }
  } else {
    debouncedLoginUser();
  }
};

const loginUser = async () => {
  useUserStore.loginUserWeb3();
};

const debouncedLoginUser = debounce(() => {
  loginUser();
}, 300);

const confirmRemoveFavorite = async (id: string) => {
  callPopUp.callPopUp({
    componentName: "favorite-favorite-pop-up",
    persistent: false,
    data: { id: id, func: props.addFunc },
  });
};

const debounceRemoveFavorite = debounce((id: string) => {
  confirmRemoveFavorite(id);
}, 300);
</script>

<style scoped lang="scss">
.add-favorite {
  position: absolute;
  background: var(--white-monochrome);
  right: 20px;
  top: 20px;
  z-index: 2;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 0;
  will-change: scale;
  cursor: pointer;
  &:hover {
    .base-icon {
      animation: pulsate-bck 0.5s ease-in-out infinite both;
      @keyframes pulsate-bck {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1);
        }
      }
    }
  }
}
</style>
