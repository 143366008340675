<template>
  <div v-if="loadingState" class="loader-backdrop">
    <div class="base-logo-loader">
      <span class="left-block">
        <base-icon name="Logo_light" size="100%" />
      </span>
      <span class="right-block">
        <h5 class="font--h5">{{ $t('general.search') }}<span class="dots"/></h5>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
interface IProps {
  isLoading: boolean;
}
const props = defineProps<IProps>();
const loadingState = computed(() => props.isLoading);

watch(loadingState, () => {
  if (loadingState.value) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }
});
</script>

<style lang="scss">
.loader-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  background: var(--black-monochrome-60);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;

  .base-logo-loader {
    display: grid;
    grid-template-columns: 245px 1fr;
    max-width: 654px;
    width: 100%;
    height: 93px;
    flex-shrink: 0;
    background: var(--Monochrome-White, #e9ebf8);
    border-radius: 16px;
    overflow: hidden;
    @media (max-width: 1000px) {
      grid-template-columns: 1fr 170px;
      height: 60px;
      width: 90%;
      margin-left: 32px;
      margin-right: 32px;
    }
    .left-block {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 40px;

      @media (max-width: 1000px) {
        padding: 12px 24px;
        .base-icon {
          max-height: 14px;
        }
      }

    }
    .right-block {
      background: var(--black-monochrome);
      padding-left: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 20px 40px;
      @media (max-width: 1000px) {
        padding: 12px 24px;
      }

      h5 {
        color: #fff;
        @media (max-width: 1000px) {
          font-size: 16px;
          font-weight: 500;
        }
        .dots {
          display: inline-block;
          width: 1em;
          text-align: left;

          &::after {
            content: ".";
            animation: dot-ellipsis 1.5s steps(5, end) infinite;
          }
        }
      }
    }
  }
}

@keyframes AnimationName {
  0% {
    background-position: 5% 0%;
  }
  50% {
    background-position: 96% 100%;
  }
  100% {
    background-position: 5% 0%;
  }
}

@keyframes dot-ellipsis {
  0%,
  20% {
    content: "";
  }
  40% {
    content: ".";
  }
  50% {
    content: "..";
  }
  60% {
    content: "...";
  }
  70% {
    content: "..";
  }
  80% {
    content: ".";
  }
  90%,
  100% {
    content: "";
  }
}
</style>
