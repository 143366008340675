<template>
  <div
    class="base-price-range"
    :class="[
      `base-price-range--${props.styleType}`,
      { 'base-price-range-main': props.isMain },
    ]"
  >
    <div class="chart-wrapper">
      <span v-if="!max" class="no-objs text-black-monochrome font--b5"
        >No matches =( <br />
        Check out later.
      </span>
      <Bar
        v-else
        ref="priceChart"
        :data="chartData"
        :options="chartOptions"
        style="justify-self: center"
      />
      <vue-slider
        v-if="max"
        v-model="range"
        :enableCross="false"
        :min="min"
        :max="max"
        :tooltip="tooltipFormatter"
        :dot-size="10"
        :height="1"
        :interval="0.1"
        @drag-end="sendRange"
        @change="updateChart()"
        @dragging="handleDragging"
      />
    </div>
    <div v-if="max" class="price-range">
      <span
        class="price-input"
        :style="{ '--filter-after-content': `'${BASE_CURRENCY.symbol}'` }"
      >
        <input
          v-model.number="InputminPrice"
          type="text"
          :maxlength="20"
          @update:model-value="updateMinInputVal"
          @blur="updateByInput"
        />
      </span>
      <base-separator />
      <span
        class="price-input"
        :style="{ '--filter-after-content': `'${BASE_CURRENCY.symbol}'` }"
      >
        <input
          v-model.number="InputmaxPrice"
          type="text"
          :maxlength="20"
          @update:model-value="updateMaxInputVal"
          @blur="updateByInput"
        />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar } from "vue-chartjs";
import { onlyNumbers } from "~/utilities/helpers/format-data/onlyNumbers";
import VueSlider from "vue-3-slider-component";
import eventBus from "~/utilities/composables/eventBus";
import { isEqual } from "lodash";
import { BASE_CURRENCY } from "~/composables/CURRENCIES";

ChartJS.register(...registerables);

interface EmitValue {
  tokenPrice: {
    gte: number;
    lte: number;
  };
}

interface Emits {
  (event: "emitRange", value: EmitValue): void;
}

interface Props {
  minPrice: number | undefined;
  maxPrice: number | undefined;
  range: { [key: number]: number } | undefined;
  styleType?: "base" | "filters" | "mobile";
  dropFunc?: () => Function;
  updateFunc?: () => Function;
  noDelay?: boolean;
  isMain?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  styleType: "base",
  minPrice: 0,
  maxPrice: 100,
});

const emit = defineEmits<Emits>();
const route = useRoute();

const getQueryRange = () => {
  const gte = parseInt(route.query["tokenPrice[gte]"] as string);
  const lte = parseInt(route.query["tokenPrice[lte]"] as string);
  if (!isNaN(gte) && !isNaN(lte)) {
    return [gte, lte];
  }
  return [Math.trunc(props.minPrice || 0), Math.trunc(props.maxPrice || 100)];
};

const range = ref(getQueryRange());
const priceChart = ref();
const tooltipFormatter = "";
const InputminPrice = ref<number | undefined>(100);
const InputmaxPrice = ref<number | undefined>(500);
const isDragging = ref(false);

const updateMinInputVal = () => {
  const newVal = onlyNumbers(InputminPrice.value);
  nextTick(() => {
    InputminPrice.value = newVal;
  });
};
const updateMaxInputVal = () => {
  const newVal = onlyNumbers(InputmaxPrice.value);
  nextTick(() => {
    InputmaxPrice.value = newVal;
  });
};

// interface Pair {
//   label: number;
//   price: number;
// }

// const sortedPairs = computed(() => {
//   // Extract and parse labels and prices
//   const pairs: Pair[] = labelsComputed.value.map((label, index) => ({
//     label: parseFloat(label.toString()),
//     price: parseFloat(pricesComputed.value[index].toString()),
//   }));

//   // Sort pairs by price
//   pairs.sort((a, b) => a.price - b.price);

//   // Calculate min, max, and step
//   const minPrice = pairs[0]?.price || 0;
//   const maxPrice = pairs[pairs.length - 1]?.price || 0;
//   const step = pairs[1]?.price - pairs[0]?.price || 1; // Ensure step is at least 1

//   // Generate additional pairs based on the price range
//   const additionalPairs: Pair[] = [];
//   for (let price = minPrice; price <= maxPrice; price += step) {
//     additionalPairs.push({ label: 0, price: parseFloat(price.toFixed(2)) });
//   }

//   // Combine pairs and remove duplicates
//   const allPairs = [...additionalPairs, ...pairs];
//   const uniquePairs = Array.from(
//     new Map(allPairs.map((pair) => [pair.price, pair])).values()
//   );

//   // Sort unique pairs by price
//   uniquePairs.sort((a, b) => a.price - b.price);

//   // Return sorted labels and prices
//   return {
//     sortedLabels: uniquePairs.map((pair) => pair.label),
//     sortedPrices: uniquePairs.map((pair) => pair.price),
//   };
// });

const sortedPairs = computed(() => {
  const pairs = labelsComputed.value.map((label, index) => ({
    label: parseFloat(label.toString()),
    price: parseFloat(pricesComputed.value[index].toString()),
  }));

  // Сортируем пары по цене
  pairs.sort((a, b) => a.price - b.price);

  // Находим минимальную и максимальную цену
  const minPrice = pairs[0]?.price;
  const maxPrice = pairs[pairs.length - 1]?.price;

  // Рассчитываем шаг интервала
  const step = (maxPrice - minPrice) / 10;

  // Инициализируем интервальные пары
  const intervalPairs = Array.from({ length: 10 }, (_, i) => ({
    label: 0, // Счетчик объектов
    price: parseFloat((minPrice + (i + 1) * step).toFixed(2)), // Верхняя граница
  }));

  // Распределяем объекты по соответствующим интервалам
  pairs.forEach((pair) => {
    const interval = intervalPairs.find(
      (interval) =>
        pair.price >= interval.price - step && pair.price <= interval.price
    );

    if (interval) {
      interval.label += 1; // Увеличиваем счетчик в соответствующем интервале
    }
  });

  // Объединяем интервалы с исходными парами
  const allPairs = [...intervalPairs];

  // Сортируем все пары по цене
  allPairs.sort((a, b) => a.price - b.price);
  return {
    sortedLabels: allPairs.map((pair) => pair.label),
    sortedPrices: allPairs.map((pair) =>
      parseFloat((pair.price * BASE_CURRENCY.value.rate).toFixed(1))
    ),
  };
});

const pricesComputed = computed(() =>
  props.range ? Object.keys(props.range).map(Number) : []
);

const labelsComputed = computed(() =>
  props.range ? Object.values(props.range) : []
);
const sortedLabels = computed(() => sortedPairs.value.sortedLabels);
const sortedPrices = computed(() => sortedPairs.value.sortedPrices);

const min = ref(0);
const max = ref(0);
const savedRange = ref<number[]>();
const chartData = ref({
  labels: sortedPrices.value,
  datasets: [
    {
      label: "Price",
      data: sortedLabels.value,
      fill: false,
      backgroundColor: "#B7A2F3",
      barThickness: "flex",
      // maxBarThickness: 8,
    },
  ],
});

const chartOptions = ref({
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      display: false,
      barPercentage: 1.4,
    },
    y: {
      display: false,
      beginAtZero: true,
    },
  },
});

let timeout: ReturnType<typeof setTimeout>;

const updateChart = (byInput?: number[]) => {
  nextTick(() => {
    let [minVal, maxVal] = range.value;
    if (Array.isArray(byInput)) {
      [minVal, maxVal] = byInput;
      range.value = [minVal, maxVal];
      min.value = minVal;
      max.value = maxVal;
      sendRange();
    }

    InputminPrice.value = minVal || 0;
    InputmaxPrice.value = maxVal || 1;

    chartData.value.labels = sortedPrices.value.map((price) =>
      price.toFixed(2)
    );
    chartData.value.datasets[0].data = sortedLabels.value;
    chartData.value.datasets[0].backgroundColor = sortedPrices.value.map(
      (price) => (price >= minVal && price <= maxVal ? "#9EA7B9" : "#E0E0E0")
    );

    if (
      priceChart.value?.chart &&
      typeof priceChart.value.chart.update === "function"
    ) {
      priceChart.value.chart.data = chartData.value;
      priceChart.value.chart.update();
    }
  });
};

const sendRange = () => {
  isDragging.value = false;
  if (isEqual(savedRange.value, range.value)) return;

  timeout = setTimeout(
    () => {
      if (isDragging.value) {
        clearTimeout(timeout);
        return;
      }

      emit("emitRange", {
        tokenPrice: {
          gte: parseFloat(
            (range.value[0] * BASE_CURRENCY.value.usdRate).toFixed(1)
          ),
          lte: parseFloat(
            (range.value[1] * BASE_CURRENCY.value.usdRate).toFixed(1)
          ),
        },
      });
      savedRange.value = [range.value[0], range.value[1]];
    },
    props.noDelay ? 0 : 1500
  );
};

const updateByInput = () => {
  if (InputmaxPrice.value && InputmaxPrice.value > max.value) {
    InputmaxPrice.value = max.value;
  }
  const arr = [InputminPrice.value ?? 0, InputmaxPrice.value ?? 0];
  updateChart(arr);
};

const chartW = computed(() => 8 * sortedPrices.value.length);

const handleDragging = (event: any) => {
  isDragging.value = true;
};

const dropPriceRange = (numbers: number[]) => {
  nextTick(() => {
    // numbers = numbers?.map((n) => {
    //   n = n;
    //   return n;
    // }) || [0, 0];
    range.value[0] = numbers[0] || 0;
    range.value[1] = numbers[1] || 50;
    min.value = range.value[0];
    max.value = range.value[1];
    updateChart();
  });
};

const gatherCurrencies = () => {
  const baseRate = BASE_CURRENCY.value.rate;
  nextTick(() => {
    let gte =
      typeof route.query[`tokenPrice[gte]`] === "string"
        ? route.query[`tokenPrice[gte]`]
        : props.minPrice;
    gte = parseFloat((parseFloat(gte.toString()) * baseRate).toFixed(1));
    let lte =
      typeof route.query[`tokenPrice[lte]`] === "string"
        ? route.query[`tokenPrice[lte]`]
        : props.maxPrice;

    lte = parseFloat((parseFloat(lte.toString()) * baseRate).toFixed(1));

    min.value = parseFloat((props.minPrice * baseRate).toFixed(1));
    max.value = parseFloat((props.maxPrice * baseRate).toFixed(1));
    range.value = [Number(gte), Number(lte)];
    InputminPrice.value = Number(gte);
    InputmaxPrice.value = Number(lte);
    savedRange.value = [range.value[0], range.value[1]];
    updateChart();
  });
};

onMounted(() => {
  nextTick(() => {
    // const gte =
    //   typeof route.query[`tokenPrice[gte]`] === "string"
    //     ? route.query[`tokenPrice[gte]`]
    //     : props.minPrice;
    // const lte =
    //   typeof route.query[`tokenPrice[lte]`] === "string"
    //     ? route.query[`tokenPrice[lte]`]
    //     : props.maxPrice;
    // min.value = props.minPrice;
    // max.value = props.maxPrice;
    // range.value = [Number(gte), Number(lte)];
    // savedRange.value = [range.value[0], range.value[1]];
    gatherCurrencies();
  });
  if (typeof props.dropFunc === "function") {
    const drop = props.dropFunc();
    drop(dropPriceRange);
  }
  eventBus.on("change-currency", gatherCurrencies);
});
</script>

<style scoped lang="scss">
.base-price-range {
  position: relative;
  display: grid;
  width: v-bind(chartW);
  min-width: 260px;
  max-width: 100%;
  gap: 12px;
  .chart-wrapper {
    position: relative;
    text-align: center;

    .no-objs {
      text-align: center;
      margin: auto;
      width: 100%;
      left: 0;
      top: 1.1rem;
    }
    canvas {
      max-height: 80px !important;
    }

    :deep(.vue-slider-process) {
      background: var(--black-monochrome);
    }
    :deep(.vue-slider-dot-handle) {
      background: var(--black-monochrome);
      border: 1px solid white;
    }
  }
  .price-range {
    display: flex;
    align-items: center;
    gap: 9px;
    .price-input {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 110px;
      width: 110px;
      height: 40px;
      border-radius: 12px;
      border: 1px solid var(--black-monochrome-60);
      background: white;
      input {
        height: 100%;
        outline: none;
        border: none;
        background: transparent;
        color: #000;
        font-family: "DM Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
      &::after {
        content: var(--filter-after-content);
        position: relative;
        color: var(--Monochrome-Black-60, rgba(18, 18, 18, 0.6));
        font-family: "DM Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  &--filters {
    width: fit-content;
    min-width: 189px;
    max-width: 189px;
    display: grid;
    justify-items: center;
    .chart-wrapper {
      width: 100%;
      max-width: 175px;
      display: grid;
      justify-items: stretch;
      canvas {
        max-height: 35px !important;
        max-width: 175px !important;
      }
      .vue-slider {
        max-width: 175px !important;
      }
    }
    .price-range {
      width: 100%;
      gap: unset;
      justify-content: space-between;
      .base-separator {
        width: 7px;
      }
      .price-input {
        width: 75px;
        height: 32px;
        // padding: 6px 8px;
        padding-right: 6px;
        border-radius: 6px;
        background: transparent;
        input {
          padding: 6px 8px;
          max-width: 75px;
          min-width: 14px;
          color: var(--black-monochrome-60);
          font-variant-numeric: tabular-nums;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  &--mobile {
    min-width: 313px;
    max-width: 313px;
    .price-range {
      .base-separator {
        width: 16px;
      }
      .price-input {
        max-width: 140px;
        width: 100%;
      }
    }
  }
  &-main {
    .price-range {
      .price-input {
        min-width: 110px;
        width: 100%;
        padding: 10px;
        &::after {
          font-size: 14px;
        }
        input {
          max-width: 65px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
