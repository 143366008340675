<template>
  <admin-search-card :inputsMapBlocked="inputsMapBlocked"></admin-search-card>
</template>

<script setup lang="ts">
const inputsMapBlocked = reactive<Record<string, boolean>>({
  name: false,
  location: false,
  irr: false,
  apr: false,
  currency: false,
  price: false,
  coords: false,
  tags: false,
  priceUSD: false,
  description: false,
  stage: false,
  finance: false,
  partners: false,
  utilities: false,
  currentUse: false,
});
</script>

<style scoped></style>
