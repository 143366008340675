<template>
  <section class="holders-popup">
    <q-tabs v-model="currentTab" @update:model-value="handleTabsChange">
      <q-tab
        v-for="tab in tabs"
        :name="tab.name"
        :label="tab.label"
        :disable="tab.disable"
      />
    </q-tabs>
    <TransitionGroup name="fade" tag="div" style="width: 100%">
      <div v-if="currentTab === 'partner'" class="holders-popup__partner">
        <base-button
          text="Add partner"
          size="xs"
          variant="prime"
          class="add-item-btn"
          @click="handleClickAddEntry"
        />
        <q-select
          filled
          v-model="pickedPartner"
          label="Partners"
          :options="allPartners"
          emit-value
          option-label="name"
          style="width: 100%"
          @filter="getPartners"
        >
          <template v-slot:option="{ itemProps, opt }">
            <q-item v-bind="itemProps">
              <q-item-section>
                <q-item-label v-html="opt.name" />
              </q-item-section>
              <q-item-section side>
                <span style="display: flex">
                  <q-avatar size="40px">
                    <q-img :src="opt.logoFile?.url" fit="cover" />
                  </q-avatar>
                  <q-btn
                    flat
                    label="Delete"
                    color="red"
                    @click="deleteEntry(opt.id)"
                  />
                  <q-btn
                    flat
                    label="Edit"
                    color="primary"
                    @click="editExistingPartner(opt)"
                  />
                </span>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
        <q-dialog v-model="partnersSubDialog" persistent>
          <q-card>
            <q-card-section style="display: grid; gap: 10px; width: 100%">
              <q-input filled v-model="newPartner.name" label="Name" />
              <q-input filled v-model="newPartner.subtitle" label="Subtitle" />
              <q-file filled v-model="partnerLogo" label="Logo" />
              <q-input filled v-model="newPartner.url" label="Website" />
            </q-card-section>

            <q-card-actions align="right">
              <q-btn flat label="Cancel" color="primary" v-close-popup />
              <q-btn
                flat
                :label="currentMode"
                :loading="createAndUpdateLoader"
                color="primary"
                @click="handleOptClick"
              />
            </q-card-actions>
          </q-card>
        </q-dialog>
      </div>
      <div v-if="currentTab === 'issuer'" class="holders-popup__issuer">
        <base-button
          text="Add issuer"
          size="xs"
          variant="prime"
          class="add-item-btn"
          @click="handleClickAddEntry"
        />
        <q-select
          filled
          v-model="pickedIssuers"
          label="Issuers"
          :options="allIssuers"
          emit-value
          option-label="name"
          style="width: 100%"
          @filter="getIssuers"
        >
          <template v-slot:option="{ itemProps, opt }">
            <q-item v-bind="itemProps">
              <q-item-section>
                <q-item-label v-html="opt.name" />
              </q-item-section>
              <q-item-section side>
                <span style="display: flex">
                  <q-avatar size="40px">
                    <q-img :src="opt.logoFile?.url" fit="cover" />
                  </q-avatar>
                  <q-btn
                    flat
                    label="Delete"
                    color="red"
                    @click.stop="deleteEntry(opt.id)"
                  />
                  <q-btn
                    flat
                    label="Edit"
                    color="primary"
                    @click.stop="editExistingIssuer(opt)"
                  />
                </span>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
        <q-dialog v-model="issuerssSubDialog" persistent>
          <q-card>
            <q-card-section style="display: grid; gap: 10px; width: 100%">
              <q-input filled v-model="newIssuer.name" label="Name" />
              <q-input
                filled
                v-model="newIssuer.description"
                label="Description"
              />
              <!-- <q-input filled v-model="newIssuer.logo" label="Logo" /> -->
              <q-file filled v-model="issuerLogo" label="Logo" />
              <q-input filled v-model="newIssuer.url" label="Website" />
              <q-input filled v-model="newIssuer.foundingYear" label="Year" />
              <q-input filled v-model="newIssuer.industry" label="Industry" />
            </q-card-section>

            <q-card-actions align="right">
              <q-btn flat label="Cancel" color="primary" v-close-popup />
              <q-btn
                flat
                :label="currentMode"
                :loading="createAndUpdateLoader"
                color="primary"
                @click="handleOptClick"
              />
            </q-card-actions>
          </q-card>
        </q-dialog>
      </div>
    </TransitionGroup>
  </section>
</template>

<script setup lang="ts">
import type {
  CreatePartnerDTO,
  PartnerResponseDTO,
} from "~/services/swagger/Api";
import { useAdminStore, type CompanyResponseDTO } from "~/store/admin";
import { Notification } from "~/services/notifications/toast";

const adminStore = useAdminStore();
const $q = useQuasar();
const holders = adminStore.getHoldersClass();
const partnersSubDialog = ref(false);
const issuerssSubDialog = ref(false);

const tabs = [
  {
    name: "partner",
    label: "Partners",
    disable: false,
  },
  {
    name: "issuer",
    label: "Issuers",
    disable: false,
  },
];

const createAndUpdateLoader = ref(false);
const allPartners = ref<PartnerResponseDTO[] | undefined>([]);
const pickedPartner = ref<PartnerResponseDTO | undefined>(undefined);
const allIssuers = ref<CompanyResponseDTO[] | undefined>([]);
const pickedIssuers = ref<CompanyResponseDTO | undefined>(undefined);
const currentMode = ref<"Create" | "Update">("Create");
const currentTab = ref<"partner" | "issuer">("partner");
const issuerLogo = ref<File | null | undefined>(null);
const partnerLogo = ref<File | null | undefined>(null);
const notif = new Notification({
  type: "system",
});

const newPartner: CreatePartnerDTO & { id: string } = reactive({
  ...{
    name: "",
    subtitle: "",
    logo: "",
    url: "",
  },
  id: "",
});
const newIssuer: CompanyResponseDTO = reactive({
  description: "",
  foundingYear: "",
  id: "",
  industry: "",
  logo: "",
  name: "",
  url: "",
});

const handleTabsChange = (val: typeof currentTab.value) => {
  currentTab.value = val;
  createAndUpdateLoader.value = false;
  partnersSubDialog.value = false;
  clearPartnerObj();
  clearIssuerObj();
};

const createNewPartner = async () => {
  createAndUpdateLoader.value = true;
  currentMode.value = "Create";
  const copy = { ...newPartner };
  delete copy.id;
  if (partnerLogo.value) {
    const fileRes = await adminStore.uploadFile(partnerLogo.value);
    copy.logo = fileRes.data.id;
  }
  const res = await holders.createNewPartner(copy);
  if (res) {
    partnersSubDialog.value = false;
    notif.createNewToast("partner added");
    clearPartnerObj();
  } else {
    notif.createNewToast("error adding");
  }
  createAndUpdateLoader.value = false;
};

const createNewIssuer = async () => {
  createAndUpdateLoader.value = true;
  currentMode.value = "Create";
  const copy = { ...newIssuer };
  delete copy.id;
  if (issuerLogo.value) {
    const fileRes = await adminStore.uploadFile(issuerLogo.value);
    copy.logo = fileRes.data.id;
  }
  const res = await holders.createNewCompany(copy);
  if (res) {
    issuerssSubDialog.value = false;
    notif.createNewToast("issuer added");
    clearIssuerObj();
  } else {
    notif.createNewToast("error adding");
  }
  createAndUpdateLoader.value = false;
};

const updatePartner = async () => {
  createAndUpdateLoader.value = true;
  const copy = { ...newPartner };
  const id = copy.id;
  if (partnerLogo.value) {
    const fileRes = await adminStore.uploadFile(partnerLogo.value);
    copy.logo = fileRes.data.id;
  } else {
    delete copy.logo;
  }
  delete copy.id;
  const res = await holders.updateExistingPartner(id, copy);
  if (res) {
    partnersSubDialog.value = false;
    notif.createNewToast("partner updated");
    clearPartnerObj();
  } else {
    notif.createNewToast("error updating");
  }
  createAndUpdateLoader.value = false;
  allPartners.value = undefined;
  pickedPartner.value = undefined;
};

const updateIssuer = async () => {
  createAndUpdateLoader.value = true;
  const copy = { ...newIssuer };
  const id = copy.id;
  if (issuerLogo.value) {
    const fileRes = await adminStore.uploadFile(issuerLogo.value);
    copy.logo = fileRes.data.id;
  } else {
    delete copy.logo;
  }
  delete copy.id;
  const res = await holders.updateExistingCompany(id, copy);
  if (res) {
    issuerssSubDialog.value = false;
    notif.createNewToast("issuer updated");
    clearIssuerObj();
  } else {
    notif.createNewToast("error updating");
  }
  createAndUpdateLoader.value = false;
  allIssuers.value = undefined;
};

const editExistingPartner = (partner: PartnerResponseDTO) => {
  currentMode.value = "Update";
  Object.keys(newPartner).forEach((key) => {
    const partnerValue = partner[key as keyof PartnerResponseDTO];
    if (partnerValue !== undefined) {
      newPartner[key as keyof typeof newPartner] = partnerValue;
    }
  });
  partnersSubDialog.value = true;
};

const editExistingIssuer = (issuer: CompanyResponseDTO) => {
  currentMode.value = "Update";
  Object.keys(newIssuer).forEach((key) => {
    const issuerValue = issuer[key as keyof CompanyResponseDTO];
    if (issuerValue !== undefined) {
      newIssuer[key as keyof typeof newIssuer] = issuerValue;
    }
  });
  issuerssSubDialog.value = true;
};

const deleteEntry = async (id: string) => {
  if (currentTab.value === "partner") {
    const res = await holders.deletePartner(id);
    if (res) {
      allPartners.value = allPartners.value?.filter((item) => item.id !== id);
    }
  } else if (currentTab.value === "issuer") {
    const res = await holders.deleteIssuer(id);
    if (res) {
      allIssuers.value = allIssuers.value?.filter((item) => item.id !== id);
    }
  }
};

const handleOptClick = () => {
  if (currentMode.value === "Create") {
    currentTab.value === "partner"
      ? createNewPartner()
      : currentTab.value === "issuer"
        ? createNewIssuer()
        : null;
  } else if (currentMode.value === "Update") {
    currentTab.value === "partner"
      ? updatePartner()
      : currentTab.value === "issuer"
        ? updateIssuer()
        : null;
  }
};

const clearPartnerObj = () => {
  Object.keys(newPartner).forEach((key) => {
    newPartner[key as keyof typeof newPartner] = "";
  });
  partnerLogo.value = null;
};

const clearIssuerObj = () => {
  Object.keys(newIssuer).forEach((key) => {
    newIssuer[key as keyof typeof newIssuer] = "";
  });
  issuerLogo.value = null;
};

const getPartners = async (val: any, update: any, abort: any) => {
  if (allPartners.value?.length) {
    update();
    return;
  }
  const res = await holders.fetchAllPartners();
  if (!res) return;
  update(() => {
    allPartners.value = res;
  });
};

const getIssuers = async (val: any, update: any, abort: any) => {
  if (allIssuers.value?.length) {
    update();
    return;
  }
  const res = await holders.fetchAllCompanies();
  if (!res) return;
  update(() => {
    allIssuers.value = res;
  });
};

const handleClickAddEntry = () => {
  if (currentTab.value === "partner") {
    partnersSubDialog.value = true;
  } else {
    issuerssSubDialog.value = true;
  }
  currentMode.value = "Create";
};
</script>

<style scoped lang="scss">
.holders-popup {
  position: relative;
  width: 600px;
  max-width: 100dvw;
  padding: 16px;
  border-radius: 12px;
  background-color: var(--white-monochrome);
  box-shadow: var(--shadow-base);
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 20px;
  overflow: hidden;
  &__issuer,
  &__partner {
    width: 100%;
    display: grid;
    gap: 10px;
  }
  .add-item-btn {
    width: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
