<script setup lang="ts">
const props = defineProps<{
  field: string;
  label: string;
}>();

const emits = defineEmits(['blockField']);
</script>

<template>
  <div class="title-container">
    <label :for="field">{{ label }}</label>
    <div class="field-checkbox">
      <label :for="'block-' + field">Block field</label>
      <input
          :id="'block-' + field"
          type="checkbox"
          @change="emits('blockField', props.field)"
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.field-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

label {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 600;
}

.field-checkbox label {
  text-wrap: nowrap;
  margin-bottom: 0;
  font-size: 12px;
}

.field-checkbox input {
  margin-bottom: 0;
}
</style>
