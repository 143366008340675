<template>
  <span class="custom-select-label custom-select-ellipsis">
    <span class="select-dot rotateable">
      <base-icon name="base/Acreage" size="13px" color="var(--white-contrast)" />
    </span>
    <p
      :class="[
        'font--b4',
        props.propertyFilter ? 'text-black-monochrome' : 'text-gray-monochrome',
      ]"
    >
      {{
        props.propertyFilter ? props.propertyFilter.label : $t("search_filters.fr_acreage")
      }}
    </p>
  </span>
  <base-icon
    name="base/ChevronDown"
    size="18px"
    color="var(--gray-monochrome)"
    :rot="props.currentSelectCard === 2 ? '180deg' : ''"
    class="arrow"

  />
  <transition name="fade">
    <div v-if="props.currentSelectCard === 2" class="select__card">
      <div class="wrapper">
        <span class="custom-select-label">
          <span class="select-dot">
            <base-icon
              name="base/House"
              size="14px"
              color="var(--white-contrast)"
            />
          </span>

          <p class="font--b2 text-black-monochrome">
            {{ $t("search_filters.fr_by_stage") }}
          </p>
        </span>
        <span class="cards">
          <span
            class="card"
            @click="props.pickPropertyFilter({ label: 'All', key: '' })"
          >
            <span class="card-icon" style="flex-wrap: wrap">
              <base-icon name="stage/complete" :filled="true" size="50px" />
              <base-icon name="stage/in_progress" :filled="true" size="50px" />
              <p class="font--b4 text-black-monochrome-60">All</p>
            </span>
          </span>
          <span
            class="card"
            @click="
              props.pickPropertyFilter({
                label: 'Completed',
                key: 'complete',
              })
            "
          >
            <span class="card-icon">
              <base-icon name="stage/complete" :filled="true" size="50px" />
              <p class="font--b4 text-black-monochrome-60">
                {{ $t("search_filters.fr_stages.complete") }}
              </p>
            </span>
          </span>
          <span
            class="card"
            @click="
              props.pickPropertyFilter({
                label: 'In progress',
                key: 'in_progress',
              })
            "
          >
            <span class="card-icon">
              <base-icon name="stage/in_progress" :filled="true" size="50px" />
              <p class="font--b4 text-black-monochrome-60">
                {{ $t("search_filters.fr_stages.progress") }}
              </p>
            </span>
          </span>
        </span>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import type { SelectCardProps } from "~/components/base/custom-select/types";

const props = defineProps<SelectCardProps>();
</script>

<style scoped lang="scss">
@import url(~/assets/styles/custom-select/index.scss);
</style>
