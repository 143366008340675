export const updateSearchPageSeo = () => {
  const title =
    "Explore Tokenized Properties | RWA Estate - Properties, Villas, Apartments, Houses & More Listings";
  const descriptions =
    "Browse RWA Estate's tokenized real estate listings. Discover property prices, investment returns, token availability, and more. Explore trending properties, value growth, and investment opportunities with real-time data.";
  useSchemaOrg([
    defineWebSite({
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "RWA Estate",
      url: "https://www.rwa-estate.com/directory",
    }),
  ]);

  useSeoMeta({
    ogTitle: title,
    ogDescription: descriptions,
    keywords: [
      "Tokenized Real Estate Listings",
      "Property Investment",
      "Tokenized Properties",
      "Real Estate IRR",
      "APR Tokenized Real Estate",
      "Property Token Prices",
      "Real Estate Value Growth",
      "Real Estate Token Investment",
      "Trending Tokenized Properties",
    ].join(", "),
    ogImage: "https://www.rwa-estate.com/hero.png",
    ogUrl: "https://www.rwa-estate.com/directory",
    twitterCard: "summary_large_image",
    twitterTitle: title,
    twitterDescription: descriptions,
    twitterImage: "https://www.rwa-estate.com/hero.png",
  });

  useHead({
    title: title,
    link: [
      {
        rel: "canonical",
        href: `https://www.rwa-estate.com/directory`,
      },
    ],
  });
};
