export function onlyNumbers(
  inputValue: string | number | null | undefined,
  allowDot: boolean = false
): string {
  if (inputValue === null || inputValue === undefined) return "";

  let result = inputValue.toString().replace(/[^0-9.]/g, "");

  if (allowDot) {
    const parts = result.split(".");
    result = parts[0] + (parts.length > 1 ? "." + parts.slice(1).join("") : "");

  }

  return result;
}
