<template>
  <section class="base-serach-card">
    <div class="base-serach-card--info">
      <div class="title">
        <span class="title-address">
          <span class="flexbox">
            <span class="tagbox">
              <p>Applied tags</p>
              <q-option-group
                v-model="currentItem.tags"
                :options="[
                  {
                    label: 'New',
                    value: 'new',
                  },
                  {
                    label: 'Trending',
                    value: 'trending',
                  },
                ]"
                color="green"
                type="checkbox"
              />
            </span>

            <q-input
              v-model="currentItem.name"
              class="q-inputs"
              standout="bg-indigo-14 text-white"
              label="Property name"
              type="text"
              style="width: 100%"
              :rules="[
                (val) => val.length >= 3 || 'Please use minimum 3 characters',
              ]"
            />
          </span>

          <span class="flexbox">
            <q-input
              v-model.number="currentItem.coords[0]"
              class="q-inputs"
              standout="bg-indigo-14  text-white"
              label="Coords LAT"
              type="number"
              style="width: 100%"
            />
            <q-input
              v-model.number="currentItem.coords[1]"
              class="q-inputs"
              standout="bg-indigo-14  text-white"
              label="Coords LONG"
              type="number"
              style="width: 100%"
            />
            <div class="title-rating">
              <q-input
                v-model="currentItem.rating"
                class="q-inputs"
                standout="bg-indigo-14  text-white"
                label="Rating"
                type="number"
                style="width: 100%"
              />
            </div>
          </span>
        </span>
        <span class="flexbox" style="width: 100%">
          <!-- <q-select
            v-model="currentItem.tags"
            standout
            :options="['new', 'trending']"
            label="Tag"
            multiple
            style="width: 150px"
          /> -->

          <base-button
            ref="blockChangesButtRef"
            text="Block changes"
            size="xs"
            variant="light_bordered"
            style="width: 100%"
          />
          <div v-if="blockChangesButtRef">
            <q-menu
              :target="blockChangesButtRef"
              class="sorting-menu"
              anchor="bottom left"
              self="top left"
              :offset="[0, 15]"
            >
              <div class="menu">
                <h3
                  class="font--b4 text-black-monochrome"
                  style="font-weight: 700"
                >
                  Block from changes
                </h3>

                <ul class="list-container">
                  <li v-for="(value, key) in blockedFields" :key="key">
                    <q-checkbox
                      v-model="blockedFields[key]"
                      size="xs"
                      :label="key"
                      @click="blockField(key)"
                    />
                  </li>
                </ul>
              </div>
            </q-menu>
          </div>
        </span>
      </div>
      <base-separator class="title-separator" />
      <div class="stats__bubbles">
        <span class="stats__bubbles-big">
          <p class="font--b2 mobile-font--b4 text-black-monochrome">
            Property Price
          </p>
          <span class="flexbox">
            <q-input
              v-model="currentItem.price"
              class="q-inputs"
              standout="bg-indigo-14  text-white"
              type="number"
            />
          </span>
        </span>
        <span class="stats__bubbles-big small-bubble">
          <p class="font--b2 mobile-font--b4 text-black-monochrome">
            Token Price
          </p>
          <span class="flexbox">
            <q-input
              v-model="currentItem.token.price"
              class="q-inputs"
              standout="bg-indigo-14  text-white"
              type="number"
            />
          </span>
        </span>
        <slot name="bubbles" :currentItem="currentItem" />
        <span class="stats__bubbles-small full-bubble">
          <span class="flexbox">
            <p class="font--b2 mobile-font--b4 text-black-monochrome">
              Minimum Investment
            </p>
          </span>
          <q-input
            v-model="currentItem.minimumInvestment"
            class="q-inputs"
            standout="bg-indigo-14  text-white"
            type="number"
            style="width: 100%"
          />
        </span>
        <q-select
          v-model="currentItem.currency"
          standout
          :options="Object.values(CURRENCIES)"
          label="Currency"
          style="width: 100%"
          option-value="code"
          option-label="code"
          emit-value
          map-options
        />

        <span class="flexxbox" style="width: 100%">
          <p class="font--b2 mobile-font--b4 text-black-monochrome">External Link</p>
          <q-input
            v-model="currentItem.externalUrl"
            class="q-inputs"
            standout="bg-indigo-14  text-white"
            type="text"
            style="width: 100%"
          />
        </span>
        <q-input
          v-model="permanentLinkInput"
          class="q-inputs"
          :maxlength="40"
          standout="bg-indigo-14  text-white"
          type="text"
          label="Page link"
          bottom-slots
          style="width: 100%"
          :readonly="adminStore.formMode === 'edit'"
          @update:model-value="getSanitizedName"
        >
          <template #hint>
            <strong>
              Link will be /{{ currentItem.sanitizeName }}
            </strong></template
          ></q-input
        >
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useAdminStore } from "~/store/admin";
import { CURRENCIES } from "~/composables/CURRENCIES";

interface IProps {
  inputsMapBlocked: Record<string, boolean>;
}

const props = defineProps<IProps>();

const blockedFields = ref(props.inputsMapBlocked)

const adminStore = useAdminStore();
const currentItem = computed(() => {
  if (adminStore.currentItem) {
    checkBlockedFields();
    permanentLinkInput.value = adminStore.currentItem.sanitizeName;
  }
  return adminStore.currentItem;
});

const permanentLinkInput = ref("");
const blockChangesButtRef = ref<HTMLElement | null>(null);

const blockField = (name: string) => {
  if (!name) return;

  const index = adminStore.currentItem.blockedField.indexOf(name);
  if (index !== -1) {
    adminStore.currentItem.blockedField.splice(index, 1);
    blockedFields.value[name] = false

  } else {
    adminStore.currentItem.blockedField.push(name);
    blockedFields.value[name] = true
  }
};

const checkBlockedFields = () => {
  Object.keys(props.inputsMapBlocked).forEach((key) => {
    if (adminStore.currentItem?.blockedField?.includes(key)) {
      blockedFields.value[key] = true;
    }
  });
};

const getSanitizedName = (val: string | number | null) => {
  nextTick(() => {
    currentItem.value.sanitizeName =
      val
        ?.toString()
        .replace(/[^a-zA-Z0-9]+/g, "-")
        .toLowerCase() ?? "";
  });
};
</script>

<style scoped lang="scss">
.base-serach-card {
  position: relative;
  width: 1130px;
  height: fit-content;
  padding: 24px;
  border-radius: 12px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 548px 1fr;
  box-shadow: var(--shadow-light);
  background-color: var(--white-contrast);
  gap: 24px;
  :deep(.q-inputs) {
    .q-field {
      max-height: 45px;
      &__append,
      &__control,
      &__control-container,
      &__native {
        max-height: 45px;
      }
    }
    :deep(.q-field__label) {
      top: 12px;
    }
  }

  @media (max-width: 1430px) {
    width: fit-content;
    grid-template-columns: 470px 1fr;
  }
  .no-price-butt {
    outline: none;
    border: none;
    background: transparent;
    width: fit-content;
    cursor: pointer;
    padding: 0;
    p {
      cursor: pointer;
      text-decoration: underline;
      font-weight: 500;
    }
    @media (max-width: 65rem) {
      p {
        font-size: 12px;
      }
      :deep(.base-icon) {
        width: 16px;
        height: 16px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .tagbox {
    padding: 5px;
    border: 1px gray solid;
  }
  .flexbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    .location-butt {
      border: none;
      padding: 0;
      outline: none;
      background: transparent;
      cursor: default;
      display: flex;
      gap: 10px;
      &-pointer {
        cursor: pointer;
      }
    }
  }
  &--gallery {
    width: 548px;
    height: 432px;
    border-radius: 12px;
    background-color: var(--white-contrast-66);
    .with-backdrop {
      display: grid;
      justify-items: center;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        filter: blur(10px);
        background-image: var(--before-url);
        z-index: -1;
        transition: background-image 0.3s ease;
      }
    }
    @media (max-width: 1430px) {
      width: 470px;
    }
  }
  &--mobile-gallery {
    display: none;
  }
  &--info {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: flex-start;
    gap: 12px;
    align-content: space-between;

    .title {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      &-address {
        display: grid;
        align-items: center;
        justify-items: flex-start;
      }
      &-rating {
        display: none;
      }
    }
    .title-separator {
      display: none;
    }
    .stats__bubbles {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: center;
      gap: 12px;

      @media (max-width: 1410px) {
        width: 100%;
      }
      .full-bubble {
        max-width: 100%;
        width: 100%;
        grid-column: span 2;
      }
      &-locale {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        margin-top: -10px;
      }
      &-big {
        display: grid;
        align-items: flex-end;
        gap: 8px;
        width: 240px;
        padding: 24px 16px;
        border: 1px solid var(--violet-40);
        border-radius: 12px;
        position: relative;
        .flexbox {
          align-items: flex-end;
        }
      }
      &-small {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 240px;
        padding: 8px 24px 8px 24px;
        border: 1px solid var(--violet-40);
        border-radius: 12px;

        .flexbox {
          gap: 4px;
          align-items: center;
        }
      }
    }
    .bubbles-separator {
      display: none;
    }
    .value {
      &-growth {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;
      }
      &-progress {
        width: 100%;
        height: 100%;
        padding-top: 3px;
      }
    }
    .value-growth-separator {
      display: none;
    }

    .card-butts {
      width: 100%;
      .flexbox {
        justify-content: space-between;
        gap: 24px;
        .base-button {
          width: 100%;
        }
      }
    }
  }
  p,
  h5 {
    cursor: default;
  }
}
.sold-out {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: var(--white--contrast-72);
  p {
    transform: rotate(45deg);
    text-transform: capitalize;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
@media (max-width: 1430px) {
  @include small-search-card;
}

@media (max-width: 65rem) {
  @include mobile-fonts;

  .base-serach-card {
    width: 345px;
    padding: 16px;
    height: auto;
    grid-template-columns: 1fr;
    gap: 17px;
    .sold-out {
      display: flex !important;
      top: 0;
      max-height: 310px;
    }
    .base-tag {
      position: absolute;
      top: 28px;
      left: 27px;
      background-color: var(--white-contrast);
    }

    .flexbox {
      gap: 3px;

      p,
      h5 {
        line-height: 100%;
      }
    }

    &--gallery {
      display: none;
    }

    &--mobile-gallery {
      display: unset;
    }

    &--info {
      gap: 23px;

      .title {
        h5 {
          font-weight: 400;
        }

        &-address {
          gap: 0;
        }

        &-rating {
          margin: 0 0 0 auto;
        }
      }

      .stats__bubbles {
        justify-content: space-between;
        width: 100%;
        display: grid;
        justify-items: center;

        &-big {
          gap: 13px;
          width: 100%;
          padding: 12px;
          max-width: 146px;
        }

        &-small {
          max-width: 146px;
          padding: 8px 16px;
        }
      }

      .card-butts {
        .flexbox {
          .base-button {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.sorting-menu {
  border-radius: 24px;
  border: 1px solid var(--Monochrome-Gray_40, rgba(158, 167, 185, 0.4));
  background: var(--Monochrome-White_contrast, #f3f7fe);
  padding: 24px;
  height: auto;
  width: 316px;
  max-height: 252px;
  .menu {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    display: flex;
    width: 100%;
    height: 100%;
    h3 {
      margin-bottom: 6px;
    }
    .list-container {
      display: grid;
      gap: 12px;
      padding-left: 25px;
      width: 100%;
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        width: 100%;
        button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: transparent;
          outline: none;
          border: none;
          padding: 0;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
