<template>
  <base-loader v-if="isLoader" class="loader" />
  <div class="container">
    <div class="header">
      <p
        class="header-title"
        :class="{ active: fileType === 'image' || fileType === 'cover' }"
        @click="fileType = 'image'"
      >
        Images
      </p>
      <p
        class="header-title"
        :class="{ active: fileType === 'document' }"
        @click="fileType = 'document'"
      >
        Docs
      </p>
      <p
        class="header-title"
        :class="{ active: fileType === 'link' }"
        @click="fileType = 'link'"
      >
        Links
      </p>
    </div>
    <div
      v-if="(images.length || newImages.length) && fileType === 'image'"
      class="images"
    >
      <div
        v-for="(file, index) in [...images, ...newImages]"
        :key="file.fileId"
        class="image"
        @click="showImgPreview(file.url)"
      >
        <q-img :src="file.url" />
        <button class="remove-button" @click.stop="removeFile(file.fileId)">
          ✖
        </button>
        <q-badge
          v-if="file.type === 'covers'"
          floating
          color="blue"
          style="left: 0; right: unset"
        >
          Cover
        </q-badge>
        <q-badge
          v-else
          floating
          color="red"
          style="left: 0; right: unset; cursor: pointer"
          @click.stop="updateCover(index)"
        >
          Pick as cover
        </q-badge>
      </div>
      <!-- <div
        v-for="image,index in newImages"
        :key="image.fileId"
        class="image"
        @click="showImgPreview(image.url)"
      >
        <q-img v-if="image.url" :src="image.url" />
        <button class="remove-button" @click.stop="removeFile(image.fileId)">
          ✖
        </button>
        <q-badge
          v-if="newImages.length && index + 1 === newImages.length"
          floating
          color="blue"
          style="left: 0; right: unset"
        >
          Cover
        </q-badge>
      </div> -->
    </div>

    <div
      v-else-if="(docs.length || newDocs.length) && fileType === 'document'"
      class="docs-container"
    >
      <span v-for="doc in docs" class="doc">
        <base-icon
          name="base/ClipboardText"
          size="24px"
          color="var(--black-monochrome)"
        />
        <p class="text-black-monochrome">
          {{ doc.name }}
        </p>
        <button class="remove-button" @click="removeFile(doc.fileId)">
          ✖
        </button>
      </span>
      <span v-for="doc in newDocs" class="doc">
        <base-icon
          name="base/ClipboardText"
          size="24px"
          color="var(--black-monochrome)"
        />
        <p class="text-black-monochrome">
          {{ doc.name }}
        </p>
        <button class="remove-button" @click="removeFile(doc.fileId)">
          ✖
        </button>
      </span>
    </div>

    <div v-else-if="fileType === 'link'" class="docs-container">
      <span
        v-for="(link, index) in adminStore.currentItem.documents"
        class="doc"
      >
        <base-icon
          name="base/ClipboardText"
          size="24px"
          color="var(--black-monochrome)"
        />
        <p class="text-black-monochrome">
          {{ link.name }}
        </p>
        <p class="text-black-monochrome">
          {{ truncateString(link.url, 30) }}
        </p>
        <button class="remove-button" @click="removeLink(index)">✖</button>
      </span>
      <!-- <span v-for="newLink in newLinks" class="doc">
        <base-icon
          name="base/ClipboardText"
          size="24px"
          color="var(--black-monochrome)"
        />
        <p class="text-black-monochrome">
          {{ newLink.name }}
        </p>
        <p class="text-black-monochrome">
          {{ truncateString(newLink.url, 30) }}
        </p>
        <button class="remove-button">✖</button>
      </span> -->
      <span class="add-link">
        <q-input
          v-model="NewLinkName"
          standout="bg-black text-white"
          type="text"
          label="File name"
        />
        <q-input
          v-model="NewLinkUrl"
          standout="bg-black text-white"
          type="text"
          label="URL"
        />
        <q-btn
          color="white"
          text-color="black"
          label="add link"
          @click="addLink"
        />
      </span>
    </div>

    <h3 v-else class="placeholder">
      There is no
      {{
        fileType === "image"
          ? "images"
          : fileType === "document"
            ? "documents"
            : "links"
      }}
      now
    </h3>

    <div v-if="fileType !== 'link'" class="file-input-container">
      <input
        id="images-upload"
        type="file"
        multiple
        class="file-input"
        @change="handleFilesChange($event, fileType)"
      />
      <label for="images-upload" class="file-input-label">
        Upload {{ fileType === "image" ? "Images" : "Documents" }}
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAdminStore } from "~/store/admin";
import { truncateString } from "~/utilities/helpers/strings/truncateString";
import { PopUpServices } from "~/services/PopUp/callPopUp";

const adminStore = useAdminStore();
const callPopUp = PopUpServices();

const fileType = ref<"image" | "document" | "link" | "covers">("image");
const isLoader = ref(false);

const images = shallowRef<
  { fileId: string; type: "image" | "document" | "covers" }[]
>([]);
// const images = computed(() => adminStore.currentItemFiles("image"));
const docs = shallowRef<
  { fileId: string; type: "image" | "document" | "covers" }[]
>([]);
// const docs = computed(() => adminStore.currentItemFiles("document"));
const newImages = computed(() => adminStore.images);
const newDocs = computed(() => adminStore.docs);
const NewLinkName = ref("");
const NewLinkUrl = ref("");
const removeFile = (fileId: string) => {
  adminStore.removeFile(fileId, fileType.value);
  if (fileType.value === "image" || fileType.value === "covers") {
    images.value = images.value.filter((i) => i.fileId !== fileId);
  } else if (fileType.value === "document") {
    docs.value = docs.value.filter((i) => i.fileId !== fileId);
  }
};
const addLink = () => {
  if (!adminStore.currentItem.documents) {
    adminStore.currentItem.documents = [] as { name: string; url: string }[];
  }
  adminStore.currentItem.documents.push({
    name: NewLinkName.value,
    url: NewLinkUrl.value,
  });
  NewLinkName.value = "";
  NewLinkUrl.value = "";
};
const removeLink = (index: number) => {
  if (!adminStore.currentItem.documents) return;
  adminStore.currentItem.documents.splice(index, 1);
  NewLinkName.value = "";
  NewLinkUrl.value = "";
};

const handleFilesChange = async (event: Event, type: "image" | "document") => {
  const input = event.target as HTMLInputElement;
  if (input.files?.length) {
    isLoader.value = true;
    const filesArray = Array.from(input.files);
    try {
      await adminStore.uploadFiles(filesArray, fileType.value);
    } catch (error) {
      alert(error?.error?.message || error?.message);
    } finally {
      isLoader.value = false;
    }
  }
};

const showImgPreview = async (link: string) => {
  if (!link) return;
  callPopUp.callPopUp({
    componentName: "base-files-uploader-img-preview",
    persistent: false,
    data: link,
    maximized: true,
  });
};

const updateCover = (index: number) => {
  if (typeof index !== "number" || !images.value || !images.value[index])
    return;
  images.value.forEach((i) => {
    i.type = "image";
  });
  images.value[index].type = "covers";
};
onMounted(() => {
  isLoader.value = true;
  setTimeout(() => {
    images.value = [
      ...adminStore.currentItemFiles("image"),
      ...adminStore.currentItemFiles("covers"),
    ];
    docs.value = adminStore.currentItemFiles("document");
    isLoader.value = false;
  }, 1500);
});
</script>

<style scoped lang="scss">
.loader {
  opacity: 0.4 !important;
  right: 0;
  top: 0;
}

.container {
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header {
  display: flex;
  gap: 20px;
  font-size: 20px;
  font-weight: 600;

  &-title {
    cursor: pointer;
  }

  &-title.active {
    text-decoration: underline;
    color: var(--violet-main);
  }
}

.images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  width: 100%;
  margin: 20px 0;
}

.image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.remove-button {
  position: absolute;
  top: -12px;
  right: -12px;
  background: rgba(255, 0, 0, 0.8);
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  font-size: 15px;
  cursor: pointer;
  opacity: 0;
  padding: 2px 0 0 0;
  transition: opacity 0.3s ease;
}

.image:hover .remove-button,
.doc:hover .remove-button {
  opacity: 1;
}

.docs-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin: 20px 0;
}
.add-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.doc {
  display: flex;
  align-items: center;
  gap: 16px;
  background: var(--white-contrast);
  border-radius: 16px;
  box-shadow: var(--shadow-light);
  max-width: 100%;
  min-width: 100px;
  padding: 24px;
  width: 100%;
  position: relative;
}

.doc .remove-button {
  position: initial;
  margin-left: auto;
}

.placeholder {
  margin: 50px auto;
}

.file-input-container {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.file-input {
  width: 100%;
  height: 30px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin-bottom: 0;
}

.file-input-label {
  display: inline-block;
  width: 100%;
  height: 30px;
  line-height: 30px;
  background-color: #5a2fcf;
  color: #e9ebf8;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 0;
}
</style>
