<template>
  <section v-if="props.unitPageData?.finance" class="current__tab-finance">
    <div
      v-if="props.unitPageData?.finance?.projectTotalIncome?.value"
      class="wrapper"
    >
      <div class="flexbox">
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome">
            {{ $t("unit_page.un_projected_total_income") }}
          </p>

          <base-tooltip :tooltip-text="$t('finance.ProjectedTotalIncome')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <p class="font--b5-1 text-black-monochrome">
          {{
            formatPercentage(
              props.unitPageData?.finance?.projectTotalIncome?.value
            )
          }}%
        </p>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.projectTotalIncome?.subfields
            ?.projectedRentalIncome
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_projected_rental_income") }}
          </p>

          <base-tooltip :tooltip-text="$t('finance.ProjectedRentalIncome')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <p class="font--b5-1 text-black-monochrome">
          {{
            formatPercentage(
              props.unitPageData?.finance?.projectTotalIncome?.subfields
                ?.projectedRentalIncome
            )
          }}%
        </p>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.projectTotalIncome?.subfields
            ?.projectionAppreciation
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_projection_appreciation") }}
          </p>

          <base-tooltip :tooltip-text="$t('finance.ProjectionAppreciation')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <p class="font--b5-1 text-black-monochrome">
          {{
            formatPercentage(
              props.unitPageData?.finance?.projectTotalIncome?.subfields
                ?.projectionAppreciation
            )
          }}%
        </p>
      </div>
    </div>
    <div
      v-if="props.unitPageData?.finance?.totalInvestmentValue"
      class="wrapper"
    >
      <div class="flexbox">
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome">
            {{ $t("unit_page.un_total") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.TotalInvestmentValue')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.totalInvestmentValue?.value
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.totalInvestmentValue?.value
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.totalInvestmentValue?.subfields
            ?.underlyingAssetPrice
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_underlying") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.UnderlyingAssetPrice')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome-60 text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.totalInvestmentValue?.subfields
                  ?.underlyingAssetPrice
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.totalInvestmentValue?.subfields
                ?.underlyingAssetPrice
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.totalInvestmentValue?.subfields
            ?.legalFees
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_legal_fees") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.LegalFees')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome-60 text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.totalInvestmentValue?.subfields
                  ?.legalFees
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.totalInvestmentValue?.subfields
                ?.legalFees
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.totalInvestmentValue?.subfields
            ?.platformFees
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_platform_fees") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.PlatformFees')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome-60 text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.totalInvestmentValue?.subfields
                  ?.platformFees
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.totalInvestmentValue?.subfields
                ?.platformFees
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.totalInvestmentValue?.subfields
            ?.auditFees
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_audit_fees") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.AuditFees')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome-60 text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.totalInvestmentValue?.subfields
                  ?.auditFees
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.totalInvestmentValue?.subfields
                ?.auditFees
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.totalInvestmentValue?.subfields
            ?.registrationTax
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_reg_tax") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.RegistrationTax')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome-60 text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.totalInvestmentValue?.subfields
                  ?.registrationTax
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.totalInvestmentValue?.subfields
                ?.registrationTax
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.totalInvestmentValue?.subfields
            ?.upfrontDAOLLCFees
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_upfr_fees") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.UpfrontDAOLLCFees')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome-60 text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.totalInvestmentValue?.subfields
                  ?.upfrontDAOLLCFees
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.totalInvestmentValue?.subfields
                ?.upfrontDAOLLCFees
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.totalInvestmentValue?.subfields
            ?.propertyUpgrades
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_prop_upg") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.PropertyUpgrade')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome-60 text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.totalInvestmentValue?.subfields
                  ?.propertyUpgrades
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.totalInvestmentValue?.subfields
                ?.propertyUpgrades
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
    </div>
    <div v-if="props.unitPageData?.finance.annualCashFlow" class="wrapper">
      <div class="flexbox">
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome">
            {{ $t("unit_page.un_an_cash_flow") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.AnnualCashFlow')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome text-right">
            ${{ formatPrice(props.unitPageData?.finance.annualCashFlow) || 0 }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="props.unitPageData?.finance.annualCashFlow"
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
    </div>
    <div v-if="props.unitPageData?.finance.monthCashFlow" class="wrapper">
      <div class="flexbox">
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome">
            {{ $t("unit_page.un_mo_cash_flow") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.MonthlyCashFlow')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome text-right">
            ${{ formatPrice(props.unitPageData?.finance.monthCashFlow) || 0 }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="props.unitPageData?.finance.monthCashFlow"
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
    </div>
    <div v-if="props.unitPageData?.finance.annualGrossIncome" class="wrapper">
      <div class="flexbox">
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome">
            {{ $t("unit_page.un_an_gross_in") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.AnnualGrossIncome')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome text-right">
            ${{
              formatPrice(props.unitPageData?.finance.annualGrossIncome) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="props.unitPageData?.finance.annualGrossIncome"
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
    </div>
    <div v-if="props.unitPageData?.finance?.maintainingCosts?.value" class="wrapper">
      <div class="flexbox">
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome">
            {{ $t("unit_page.un_maintain_c") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.MaintainingCosts')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.maintainingCosts?.value
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.maintainingCosts?.value
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.maintainingCosts?.subfields
            ?.propertyManagement
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_prop_man") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.PropertyManagement')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome-60 text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.maintainingCosts?.subfields
                  ?.propertyManagement
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.maintainingCosts?.subfields
                ?.propertyManagement
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.maintainingCosts?.subfields
            ?.maintenanceReserve
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_man_res") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.MaintenanceReserve')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome-60 text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.maintainingCosts?.subfields
                  ?.maintenanceReserve
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.maintainingCosts?.subfields
                ?.maintenanceReserve
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.maintainingCosts?.subfields?.utilities
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_utils") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.Utilities')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome-60 text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.maintainingCosts?.subfields
                  ?.utilities
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.maintainingCosts?.subfields
                ?.utilities
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.maintainingCosts?.subfields
            ?.annualDAOLLCAdministrationAndFilingFees
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_an_admin") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.AnnualDAOLLCFees')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome-60 text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.maintainingCosts?.subfields
                  ?.annualDAOLLCAdministrationAndFilingFees
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.maintainingCosts?.subfields
                ?.annualDAOLLCAdministrationAndFilingFees
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.maintainingCosts?.subfields
            ?.annualDAOAccountingService
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_an_serv") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.AnnualDAOAccounting')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome-60 text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.maintainingCosts?.subfields
                  ?.annualDAOAccountingService
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.maintainingCosts?.subfields
                ?.annualDAOAccountingService
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
      <div
        v-if="
          props.unitPageData?.finance?.maintainingCosts?.subfields
            ?.homeownersInsurance
        "
        class="flexbox"
      >
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome-60">
            {{ $t("unit_page.un_own_ins") }}
          </p>
          <base-tooltip :tooltip-text="$t('finance.HomeownersInsurance')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip>
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome-60 text-right">
            ${{
              formatPrice(
                props.unitPageData?.finance?.maintainingCosts?.subfields
                  ?.homeownersInsurance
              ) || 0
            }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="
              props.unitPageData?.finance?.maintainingCosts?.subfields
                ?.homeownersInsurance
            "
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
    </div>
    <div v-if="props.unitPageData?.finance?.otherFees" class="wrapper">
      <div class="flexbox">
        <span class="icon-flex">
          <p class="font--b5 text-black-monochrome">
            {{ $t("unit_page.un_other_fee") }}
          </p>
          <!-- <base-tooltip :tooltip-text="$t('finance.MaintainingCosts')">
            <template #icon>
              <base-icon
                name="base/Info"
                size="16px"
                color="var(--black-monochrome-60)"
              />
            </template>
          </base-tooltip> -->
        </span>
        <div>
          <p class="font--b5-1 text-black-monochrome text-right">
            ${{ formatPrice(props.unitPageData?.finance?.otherFees) || 0 }}
          </p>
          <NativeCurrency
            v-if="props.unitPageData.currency !== BASE_CURRENCY.code"
            :currency-value="props.unitPageData?.finance?.otherFees"
            :currency="props.unitPageData.currency"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type {
  EstateFinanceDTO,
  LandFinanceDTO,
  UnitResponseDTO,
} from "~/services/swagger/Api";
import { BASE_CURRENCY } from "~/composables/CURRENCIES";
import {
  formatPrice,
  formatPercentage,
} from "~/utilities/helpers/format-data/number";

interface UpdatedUnitResponseDTO extends Omit<UnitResponseDTO, "finance"> {
  finance: LandFinanceDTO & EstateFinanceDTO;
}

interface IProps {
  unitPageData: UpdatedUnitResponseDTO;
}

const props = defineProps<IProps>();
</script>

<style scoped lang="scss">
.current__tab-finance {
  display: grid;

  .wrapper {
    display: grid;
    align-items: center;
    justify-items: flex-start;
    gap: 16px;
    border-bottom: 1px solid var(--gray-monochrome-40);
    padding: 16px 0;
    &:last-child{
      border: none;
    }
    .flexbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 10px;

      .icon-flex {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
      }
    }
  }

  .list-flexbox {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    cursor: default;

    .wrapper-flex {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .text-right {
    text-align: right;
  }
}
</style>
