import { writePersistentLocalStorage } from "~/services/LocalStorage/localStorage";

export type AreaUnit = {
  code: "m2" | "ft2";
  name: string;
  toM2: (value: number) => number;
  fromM2: (value: number) => number;
};

export const AREA_UNITS: { [key: string]: AreaUnit } = {
  m2: {
    code: "m2",
    name: "Square Meters",
    toM2: (value: number) => value,
    fromM2: (value: number) => value,
  },
  ft2: {
    code: "ft2",
    name: "Square Feet",
    toM2: (value: number) => Math.trunc(value / 10.7639),
    fromM2: (value: number) => Math.trunc(value * 10.7639),
  },
} as const;

const baseAreaUnit = ref<AreaUnit>(AREA_UNITS.m2);

export const BASE_AREA_UNIT = computed(() => baseAreaUnit.value);

export const getCurrentAreaUnit = (key: string | null | undefined) => {
  if (!key) return AREA_UNITS[BASE_AREA_UNIT.value.code];
  return AREA_UNITS[key] || AREA_UNITS.m2;
};

export const setBaseAreaUnit = (key: string) => {
  if (AREA_UNITS[key]) {
    baseAreaUnit.value = AREA_UNITS[key];
  } else {
    baseAreaUnit.value = AREA_UNITS.m2;
  }
  writePersistentLocalStorage("user_area_unit", baseAreaUnit.value.code);
};

export const convertArea = (
  value: number,
  fromUnit: AreaUnit,
  toUnit: AreaUnit
): number => {
  const valueInM2 = fromUnit.toM2(value);
  return toUnit.fromM2(valueInM2);
};
