<template>
  <section class="base-serach-card">
    <base-search-card-sold-out-tag v-if="isSold" />
    <base-favorite-add
      v-if="$device.isMobile"
      :id="props.propertyInfo.id"
      :favorite="props.propertyInfo.favorite"
      :add-func="UNIT?.addToFavorite"
      :icon-size="'18px'"
      class="add-favorite-mobile"
    />
    <span class="tags">
      <base-tag v-if="tag" :variant="tag" size="search" />

      <span
        v-if="props.propertyInfo?.provider?.logoFile?.url"
        v-show="!partnerLogoError"
        class="issuer-logo"
        :title="`Partner: ${props.propertyInfo?.provider?.name ?? ''}`"
        @click="handleProviderClick"
      >
        <img
          :data-src="props.propertyInfo.provider?.logoFile?.url"
          alt="logo"
          v-image-error="{
            onImageError: hideParnterLogo,
            onImageLoad: showPartnerLogo,
          }"
        />
      </span>
    </span>
    <div v-if="!$device.isMobile" class="swiper-wraper">
      <base-favorite-add
        :id="props.propertyInfo.id"
        :favorite="props.propertyInfo.favorite"
        :add-func="UNIT?.addToFavorite"
        :icon-size="'18px'"
        class="add-favorite"
      >
      </base-favorite-add>
      <swiper-gallery
        class="base-serach-card--gallery"
        :go-to-unit="`${props.propertyInfo.sanitizeName}`"
      >
        <div
          v-for="(item, index) in imageArray"
          class="with-backdrop"
          :style="getBeforeStyle(item.file.url)"
        >
          <span
            v-if="
              !loadedImages[index] && !checkIfImgLoaded(item.file.url, index)
            "
            class="loader"
          />
          <NuxtImg
            :src="item.file.url"
            lazy
            alt="property photo"
            :id="item.file.url"
            :style="{ display: loadedImages[index] ? 'block' : 'none' }"
            @load="onImageLoad(index)"
          />
        </div>
      </swiper-gallery>
    </div>
    <mobile-swiper-gallery
      v-if="$device.isMobile"
      class="base-serach-card--mobile-gallery"
      :go-to-unit="props.propertyInfo.sanitizeName"
    >
      <div v-for="file in imageArray">
        <NuxtImg :src="file.file.url" alt="property photo" lazy />
      </div>
    </mobile-swiper-gallery>
    <div class="base-serach-card--info">
      <span class="title-block">
        <span
          class="title"
          :style="{ maxWidth: $device.isMobile ? '180px' : '305px' }"
        >
          <button
            class="title-butt font--b1-2 text-black-monochrome"
            @click="navigateToUnitPage(props.propertyInfo.sanitizeName)"
          >
            {{ propertyInfo.name || $t("search_card.fr_noname") }}
          </button>
          <span class="title-flexbox">
            <button @click="openGoogleMap(props.propertyInfo?.coords)">
              <base-icon name="base/MapPin" size="16px" />
            </button>
            <button
              class="font--b5 text-black-monochrome-60"
              style="width: 190px; text-align: left"
              @click="openGoogleMap(props.propertyInfo?.coords)"
            >
              {{ propertyInfo.location || $t("search_card.fr_loc") }}
            </button>
          </span>
        </span>
        <span class="title-block__rating">
          <base-icon
            class="icon-animated"
            name="base/StarFull"
            color="#F59914"
            size="13px"
          />
          <p v-if="propertyInfo.rating" class="font--b5">{{ propertyInfo.rating }}</p>
          <p v-else class="font--b5">-</p>
        </span>
      </span>
      <q-separator class="separator" />
      <span class="list-flex">
        <span class="icon-flex">
          <p class="font--b4 text-black-monochrome">
            {{ getPriceTitle }}
          </p>
        </span>
        <span class="local-price-flex">
          <span
            class="font--b3-2 text-black-monochrome"
            style="display: flex; align-items: center"
          >
            <p
              v-if="isLocalCurrency"
              class="font--b5-3"
              style="margin-top: 3px"
            >
              ≈
            </p>
            {{
              BASE_CURRENCY.symbol +
              formatPrice(propertyInfo.priceUSD, true, isLocalCurrency, {
                price: propertyInfo.price,
                currency: propertyInfo?.currency,
              })
            }}
          </span>
          <span v-if="isLocalCurrency" class="icon-flex">
            <p class="font--b5-3 text-black-monochrome-60">
              {{ currentCurrency.symbol + formatPrice(propertyInfo.price) }}
            </p>
            <base-tooltip
              :tooltip-text="`${$t('search_card.fr_operates', {
                currency: currentCurrency.code,
              })} <br> ${$t('search_card.fr_rate')}`"
              style="margin-bottom: 1px"
            >
              <template #icon>
                <suspense>
                  <base-icon
                    name="base/Info"
                    size="16px"
                    color="var(--black-monochrome-60)"
                  />
                </suspense> </template
            ></base-tooltip>
          </span>
        </span>
      </span>
      <q-separator class="separator" />
      <span class="two-column-lust">
        <q-separator vertical class="separator separator-abs" />

        <span class="list-flex">
          <span class="icon-flex">
            <p class="font--b5 text-black-monochrome">
              {{ $t("search_card.fr_token") }}
            </p>
          </span>
          <p class="font--b5-1 text-black-monochrome">
            {{
              BASE_CURRENCY.symbol +
              formatPrice(
                propertyInfo?.token?.priceUSD,
                false,
                isLocalCurrency,
                {
                  price: propertyInfo.token?.price,
                  currency: propertyInfo?.currency,
                }
              )
            }}
          </p>
        </span>
        <span class="list-flex">
          <span class="icon-flex">
            <p class="font--b5 text-black-monochrome">
              {{ $t("search_card.fr_min_invest") }}
            </p>
            <base-tooltip
              v-if="isLocalCurrency"
              :tooltip-text="`${$t('search_card.fr_operates', {
                currency: currentCurrency.code,
              })} <br> ${$t('search_card.fr_rate')}`"
              style="margin-bottom: 1px"
            >
              <template #icon>
                <suspense>
                  <base-icon
                    name="base/Info"
                    size="16px"
                    color="var(--black-monochrome-60)"
                  />
                </suspense> </template
            ></base-tooltip>
          </span>
          <span
            class="font--b5-1 text-black-monochrome"
            style="display: flex; align-items: center"
          >
            <p
              v-if="isLocalCurrency"
              class="font--b5-3"
              style="margin-top: 3px"
            >
              ≈
            </p>
            {{
              BASE_CURRENCY.symbol +
              formatPrice(
                propertyInfo?.minimumInvestmentUSD,
                true,
                isLocalCurrency,
                {
                  price: propertyInfo?.minimumInvestment,
                  currency: propertyInfo?.currency,
                }
              )
            }}
          </span>
        </span>
        <span v-if="props.propertyType !== 'lands'" class="list-flex">
          <span class="icon-flex">
            <p class="font--b5 text-black-monochrome">IRR</p>
            <base-tooltip
              :tooltip-text="$t('finance.IRR')"
              style="margin-bottom: 1px"
            >
              <template #icon>
                <suspense>
                  <base-icon
                    name="base/Info"
                    size="16px"
                    color="var(--black-monochrome-60)"
                  />
                </suspense> </template
            ></base-tooltip>
          </span>
          <p v-if="propertyInfo?.irr" class="font--b5-1 text-black-monochrome">
            {{ formatPercentage(propertyInfo?.irr) }}%
          </p>
          <base-apr-irr v-else @click="openIssuer"></base-apr-irr>
        </span>
        <span class="list-flex">
          <span class="icon-flex">
            <p class="font--b5 text-black-monochrome">
              {{ $t("search_card.fr_growth") }}
            </p>
          </span>
          <p class="font--b5-1 text-black-monochrome">
            {{
              formatPercentage(
                propertyInfo.finance?.projectTotalIncome?.subfields
                  ?.projectionAppreciation
              )
            }}%
          </p>
        </span>
        <span v-if="props.propertyType !== 'lands'" class="list-flex">
          <span class="icon-flex">
            <p class="font--b5 text-black-monochrome">APR</p>
            <base-tooltip
              :tooltip-text="$t('finance.APR')"
              style="margin-bottom: 1px"
            >
              <template #icon>
                <suspense>
                  <base-icon
                    name="base/Info"
                    size="16px"
                    color="var(--black-monochrome-60)"
                  />
                </suspense> </template
            ></base-tooltip>
          </span>
          <p v-if="propertyInfo?.apr" class="font--b5-1 text-black-monochrome">
            {{ formatPercentage(propertyInfo?.apr) }}%
          </p>
          <base-apr-irr v-else @click="openIssuer"></base-apr-irr>
        </span>
        <span class="list-flex">
          <span class="icon-flex">
            <p class="font--b5 text-black-monochrome">
              {{ $t("search_card.fr_tokens_avail") }}
            </p>
            <base-tooltip
              :tooltip-text="$t('finance.TokensAvailable')"
              style="margin-bottom: 1px"
            >
              <template #icon>
                <suspense>
                  <base-icon
                    name="base/Info"
                    size="16px"
                    color="var(--black-monochrome-60)"
                  />
                </suspense> </template
            ></base-tooltip>
          </span>
          <p class="font--b5-1 text-black-monochrome">
            {{
              formatPercentage(propertyInfo?.token?.availableSupplyPercentage)
            }}%
          </p>
        </span>
      </span>
      <q-separator class="separator" />
      <base-search-card-visitors
        v-if="propertyInfo?.metrics"
        :day="propertyInfo?.metrics.viewsPerDay"
        :all-time="propertyInfo?.metrics?.viewsForAllTime"
      />
      <q-separator class="separator" />
      <nav class="controls">
        <base-button
          size="xss"
          icon-name="base/CompareThink"
          icon-size="16px"
          class="btn-compare"
          :round="true"
          :variant="isAvaliableToCompare ? 'dark_ghost' : 'dark'"
          @click="addDeleteComparison($event, props.propertyInfo.id)"
        />
        <base-button
          variant="dark_ghost"
          :text="$t('search_card.fr_visit')"
          size="xss"
          @click.stop="navigateToUnitPage(props.propertyInfo?.sanitizeName)"
        />
        <base-button
          variant="prime"
          :text="$t('search_card.fr_contact')"
          size="xss"
          @click="openIssuer"
        />
      </nav>
    </div>
  </section>
</template>

<script setup lang="ts">
import openGoogleMap from "~/utilities/helpers/map/openGoogleMap";
import type { PropertyItem } from "~/types/search-page-items";
import noImage from "~/assets/img/no-image.svg";
import {
  formatPrice,
  formatPercentage,
} from "~/utilities/helpers/format-data/number";
import { getCurrentCurrency, BASE_CURRENCY } from "~/composables/CURRENCIES";
import {
  getLocalStorage,
  writePersistentLocalStorage,
} from "~/services/LocalStorage/localStorage";
import { Notification } from "~/services/notifications/toast";
import eventBus from "~/utilities/composables/eventBus";
import type { FilterCheckbox, PropertyTypes } from "../navigator/types";
import { unitPageStore, type UpdatedUnitResponseDTO } from "~/store/unitPage";
import { redirectByProviderName } from "~/utilities/helpers/strings/redirectByProviderName";
import { getThumbnail } from "~/utilities/helpers/thumbnails/getThumbnail";

interface Props {
  propertyInfo: UpdatedUnitResponseDTO;
  isSmall: boolean;
  noVisitors: boolean;
  propertyType: PropertyTypes;
}

const route = useRoute();

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(["click-provider"]);

const useUnitpageStore = unitPageStore();

const UNIT = useUnitpageStore.createUnit();

const localePath = useLocalePath();

const { t } = useI18n();

const compareNotif = new Notification({
  type: "compare",
});

const dynamicRoute = localePath(`/comparison`);

const isSold = computed(() => props.propertyInfo?.status === "sold_out");

const partnerLogoError = ref(true);

const currentCurrency = computed(() =>
  getCurrentCurrency(props.propertyInfo?.currency)
);

const isLocalCurrency = computed(
  () => currentCurrency.value.code !== BASE_CURRENCY.value.code
);

const tag = computed(() => {
  if (isSold.value) return "";
  if (props.propertyInfo.tags?.length && props.propertyInfo.tags[0]) {
    return props.propertyInfo.tags[0];
  }
  return "";
});

const loadedImages = ref<boolean[]>([]);

const hideParnterLogo = () => {
  partnerLogoError.value = true;
};

const showPartnerLogo = (src: string) => {
  if (!src || src.includes("no-icon.svg")) {
    partnerLogoError.value = true;
    return;
  }
  partnerLogoError.value = false;
};

const imageArray = computed(() => {
  if (props.propertyInfo?.files) {
    const filteredImages = props.propertyInfo.files
      .filter(
        (item) =>
          (item.type == "image" || item.type == "covers") &&
          !item.file.url?.endsWith("pdf")
      )
      .sort((a, b) => {
        if (a.type === "covers" && b.type !== "covers") return 1;
        if (a.type !== "covers" && b.type === "covers") return -1;
        return 0;
      });
    loadedImages.value = Array(filteredImages.length).fill(false);
    filteredImages.forEach((item) => {
      item.file.url = getThumbnail(item.file, "thumbnail_470x370");
    });
    return filteredImages.length
      ? filteredImages.reverse()
      : [{ file: { url: noImage } }];
  }
});

const onImageLoad = (index: number) => {
  loadedImages.value[index] = true;
};

const getBeforeStyle = (url: string) => {
  return {
    "--before-url": `url(${url})`,
  };
};

const openIssuer = () => {
  redirectByProviderName({
    providerName: props.propertyInfo?.provider?.name,
    providerLink: props.propertyInfo?.provider?.url,
    utmLink: props.propertyInfo?.referralLink?.link,
    externalUrl: props.propertyInfo?.externalUrl,
    source: "directory__open_ref",
    images: props.propertyInfo?.files,
    logoFile: props.propertyInfo?.provider?.logoFile?.url,
  });
};

const isAvaliableToCompare = ref(true);

const checkIfInMatching = () => {
  const storageKey =
    props.propertyType === "estates" ? "match_estates" : "match_lands";
  const propsIds: string[] | null = getLocalStorage(storageKey);
  if (propsIds && Array.isArray(propsIds) && propsIds.length) {
    isAvaliableToCompare.value = !propsIds.includes(
      props.propertyInfo.id || ""
    );
  }
};

const navigateToUnitPage = (id: string | null | undefined) => {
  if (!id) return;
  const route = localePath(`/property/${id}`);
  window.open(window.location.origin + route, "_blank");
};

const addDeleteComparison = (
  event: MouseEvent,
  id: string | null | undefined
) => {
  if (!id) return;
  const storageKey =
    props.propertyType === "estates" ? "match_estates" : "match_lands";
  let propsIds: string[] | null = getLocalStorage(storageKey);
  if (!propsIds || (Array.isArray(propsIds) && !propsIds.includes(id))) {
    if (!checkAmountComparison(propsIds)) return;
    propsIds = propsIds ? [...propsIds, id] : [id];
    writePersistentLocalStorage(storageKey, propsIds);
    isAvaliableToCompare.value = false;
    compareNotif.createCompareToast({
      has: propsIds?.length,
      path: dynamicRoute,
    });
  } else if (propsIds) {
    propsIds = propsIds.filter((q) => q !== id);
    writePersistentLocalStorage(storageKey, propsIds);
    isAvaliableToCompare.value = true;
  }
  eventBus.emit("compare-counter", propsIds?.length || 0);
};

const checkAmountComparison = (estateIds: string[] | null): boolean => {
  if (!estateIds) return true;
  // eventBus.emit("is-compare-badge", estateIds.length > 0);

  if (Array.isArray(estateIds) && estateIds.length >= 4) {
    compareNotif.createCompareToast({
      has: estateIds?.length,
      path: dynamicRoute,
    });
    return false;
  } else return true;
};

const checkIfImgLoaded = (src: string | null | undefined, index: number) => {
  if (!src) return false;
  const img = document.getElementById(src) as HTMLImageElement;
  if (img?.complete) {
    onImageLoad(index);
    return true;
  }
  return false;
};

const getPriceTitle = computed(() => {
  switch (props.propertyType) {
    case "estates":
      return t("search_card.fr_price");
    case "lands":
      return t("search_card.fr_price_land");
  }
});

const handleProviderClick = () => {
  const provider = props.propertyInfo.provider;
  if (!provider || !provider.id) return;
  const filter: FilterCheckbox = {
    label: provider.name,
    count: 0,
    checked: false,
    server_label: provider.id,
  };
  if (route.path !== "/directory") {
    const route = localePath(`/directory?providerIds[0]=${provider.id}`);
    window.open(window.location.origin + route, "_blank");
  } else {
    emit("click-provider", filter);
  }
};

onMounted(() => {
  checkIfInMatching();
});
</script>

<style scoped lang="scss">
.base-serach-card {
  position: relative;
  width: 736px;
  height: fit-content;
  height: fit-content;
  max-height: 340px;
  padding: 16px;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 332px 1fr;
  box-shadow: var(--shadow-light);
  background-color: var(--white-contrast);
  gap: 24px;
  .swiper-wraper {
    position: relative;
    .add-favorite {
      opacity: 0;
      right: 12px;
      top: 12px;
      z-index: 2;
      width: 32px;
      height: 32px;
      transition: opacity 0.3s ease-in-out;
      will-change: opacity;
    }
  }
  &:hover {
    .swiper-wraper {
      .add-favorite {
        opacity: 1;
      }
    }
  }

  .sold-out-wrapper {
    position: absolute;
    top: -2px;
    left: -2px;
  }
  .tags {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 999;
    display: flex;
    align-items: center;
    width: fit-content;
    justify-content: flex-start;
    gap: 10px;
    .issuer-logo {
      display: flex;
      height: 30px;
      max-width: 110px;
      width: fit-content;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 40px;
      border: 1px solid var(--gray-40);
      background: var(--white-contrast);
      cursor: pointer;
      img {
        height: 20px;
        width: 100%;
        // max-width: 90px;
        // object-fit: cover;
      }
    }
  }
  .separator {
    width: 100%;
    &-abs {
      position: absolute;
      left: 50%;
      height: 100%;
      width: 1px;
    }
  }
  &--gallery {
    width: 332px;
    height: 100%;
    max-height: 274px;
    border-radius: 12px;
    background-color: var(--white-contrast-66);
    .with-backdrop {
      position: relative;
      display: grid;
      justify-items: center;
      align-items: center;
      height: 100%;

      .loader {
        width: 48px;
        height: 48px;
        border: 5px solid var(--violet-main);
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
      }

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      img {
        max-height: 100%;
        height: 100%;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        filter: blur(10px);
        background-image: var(--before-url);
        z-index: -1;
        transition: background-image 0.3s ease;
      }
    }
  }
  &--info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    height: fit-content;
    .title-block {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 2px;
        p,
        button {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &-butt {
          @include drop-button-styles;
          max-width: 100%;
        }

        &-flexbox {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 4px;
          max-width: 100%;
          button {
            @include drop-button-styles;
          }
        }
      }
      &__rating {
        display: flex;
        gap: 4px;
        cursor: default;
        align-items: center;
        line-height: 1;
        &:hover {
          .icon-animated {
            rotate: 1turn;
          }
        }
        .icon-animated {
          transition: rotate 0.5s ease;
        }
      }
    }
    .two-column-lust {
      position: relative;
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      column-gap: 16px;
      row-gap: 12px;
    }
    .controls {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      width: 100%;
      .base-button {
        width: 100%;
      }
      .btn-compare {
        min-width: 36px;
        width: 36px;
        height: 36px;
      }
    }
  }
  .list-flex {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .icon-flex {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .local-price-flex {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  :deep(.search-card__visitors) {
    p {
      font-family: "DM Sans";
      font-size: 14px;
      font-style: normal;
      line-height: normal;
    }
    .day-counter {
      font-weight: 400;
    }
    .alltime-counter {
      font-weight: 700;
    }
  }
}
.sold-out {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: var(--white--contrast-72);
  p {
    transform: rotate(45deg);
    text-transform: capitalize;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
@media (max-width: 59rem) {
  @include mobile-fonts;
  .base-serach-card {
    display: flex;
    flex-direction: column;
    width: 345px;
    height: fit-content;
    max-height: none;
  }
}
.swiper-wraper {
  position: relative;
}
.add-favorite {
  right: 12px;
  top: 12px;
  z-index: 2;
  width: 32px;
  height: 32px;
  &-mobile {
    right: 30px;
    top: 30px;
  }
}
</style>
