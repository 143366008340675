export const transformQueryToObject = (
  input: Record<string, any>
): Record<string, any> => {
  if (!input) return {};
  const result: Record<string, any> = {};

  for (const key in input) {
    const match = key.match(/^(.*)\[(.*)\]$/); // Match keys like property[index]

    if (match) {
      const [_, propertyName, propertyKey] = match;

      // Check if propertyKey is a number
      const isNumericIndex = /^\d+$/.test(propertyKey);

      if (!result[propertyName]) {
        result[propertyName] = isNumericIndex ? [] : {};
      }

      if (isNumericIndex) {
        // Convert propertyKey to a number and assign to array
        result[propertyName][parseInt(propertyKey)] = !isNaN(
          parseInt(Number(input[key]))
        )
          ? parseInt(Number(input[key]))
          : input[key] === "null"
            ? null
            : input[key];
      } else {
        // Assign to object if propertyKey is not a number
        result[propertyName][propertyKey] = input[key];
      }
    } else {
      result[key] = input[key];
    }
  }

  return result;
};
