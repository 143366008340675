export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.client) {
    nuxtApp.hooks.hookOnce("page:finish", (page) => {
      // const script = document.createElement("script");
      //   script.async = true;
      //   script.defer = true;
      //   script.src = "https://accounts.google.com/gsi/client";
      //   document.head.appendChild(script);
    });
  }
  
});
