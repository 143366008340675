<template>
  <div class="base-swiper-gallery">
    <swiper
      :modules="[Navigation]"
      :slides-per-view="props.slidesPerView"
      :loop="true"
      :allowTouchMove="false"
      v-bind="$attrs"
      @swiper="onSwiper"
    >
      <template v-if="$slots.default">
        <swiper-slide
          v-for="(child, index) in $slots.default()[0]?.children ?? []"
          :key="index"
          @click="navigateToUnitPage"
        >
          <component :is="child" />
        </swiper-slide>
      </template>
    </swiper>
    <div class="controls">
      <button class="controls-butt hover-scale-light" @click="slidePrev">
        <base-icon name="base/ChevronLeft" size="18px" />
      </button>
      <button class="controls-butt hover-scale-light" @click="slideNext">
        <base-icon name="base/ChevronRight" size="18px" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

//look https://swiperjs.com/swiper-api#parameters for avaliable props

interface Props {
  slidesPerView?: number | "auto" | undefined;
  goToUnit: string | undefined | null;
}

const props = withDefaults(defineProps<Props>(), {
  slidesPerView: 1,
});

const swiperInst = ref<typeof Swiper | null>(null);
const onSwiper = (swiper: any) => {
  swiperInst.value = swiper;
};
const localePath = useLocalePath();

const slideNext = () => {
  swiperInst.value?.slideNext();
};
const slidePrev = () => {
  swiperInst.value?.slidePrev();
};
const navigateToUnitPage = () => {
  window.open(
    window.location.origin + localePath(`/property/${props.goToUnit}`),
    "_blank"
  );
};

watch(
  () => props.slidesPerView,
  (newVal) => {
    if (swiperInst.value) {
      swiperInst.value.params.slidesPerView = newVal;
      swiperInst.value.update();
    }
  }
);
</script>

<style scoped lang="scss">
.base-swiper-gallery {
  position: relative;
  .swiper {
    border-radius: 12px;
    max-width: 100%;
    height: 100%;
    // max-height: 100%;
  }

  .swiper-slide {
    justify-content: stretch;
    align-items: stretch;
    :deep(img) {
      max-width: 100%;
      // height: 100%;
      object-fit: cover;
    }
  }
  .controls {
    position: absolute;
    top: 45%;
    left: 0;
    z-index: 3;
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    padding: 0 24px;
    justify-content: space-between;
    &-butt {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      outline: none;
      background-color: var(--white--contrast-72);
      border: 1px solid var(--gray-monochrome-40);
      cursor: pointer;
      padding: 0;
      align-items: center;
      justify-content: center;
      display: flex;
      &:hover {
        border-color: var(--gray-monochrome);
      }
    }
  }
}
</style>
