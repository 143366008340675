export const updateCommonSeo = () => {
  const descriptions =
    "Discover the first tokenized real estate aggregator, RWA Estate. Explore trending properties, real-time tokenization insights, and top investment destinations worldwide. Unlock new offers and track property growth rates in just a few clicks.";
  const title =
    "RWA Estate | Explore Tokenized Real Estate - Trending Properties & Investment Opportunities";
  useSchemaOrg([
    defineWebSite({
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "RWA Estate",
      url: "https://www.rwa-estate.com",
    }),
  ]);

  // useSeoMeta({
  //   ogTitle: title,
  //   ogDescription: descriptions,
  //   keywords: [
  //     "Tokenized Real Estate",
  //     "Property Investment",
  //     "Trending Properties",
  //     "Real Estate Aggregator",
  //     "Real Estate Tokenization",
  //     "Investment Opportunities",
  //     "New Property Offers",
  //     "Real Estate Blockchain",
  //     "Property Growth Rates",
  //   ].join(", "),
  //   ogImage: "https://www.rwa-estate.com/hero.png",
  //   ogUrl: "https://www.rwa-estate.com",
  //   twitterCard: "summary_large_image",
  //   twitterTitle: title,
  //   twitterDescription: descriptions,
  //   twitterImage: "https://www.rwa-estate.com/hero.png",
  // });
  useHead({
    title: title,
    meta: [
      {
        property: "og:title",
        content: title,
      },
      {
        property: "og:description",
        content: descriptions,
      },
      {
        name: "keywords",
        content: [
          "Tokenized Real Estate",
          "Property Investment",
          "Trending Properties",
          "Real Estate Aggregator",
          "Real Estate Tokenization",
          "Investment Opportunities",
          "New Property Offers",
          "Real Estate Blockchain",
          "Property Growth Rates",
        ].join(", "),
      },
      {
        property: "og:image",
        content: "https://www.rwa-estate.com/hero.png",
      },

      {
        property: "og:url",
        content: "https://www.rwa-estate.com",
      },
      { name: "twitter:card", content: "summary_large_image" },
      {
        name: "twitter:title",
        content: title,
      },
      {
        name: "twitter:description",
        content: descriptions,
      },
      {
        name: "twitter:image",
        content: "https://www.rwa-estate.com/hero.png",
      },
    ],
  });
};
