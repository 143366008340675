<template>
  <section class="legal">
    <div class="legal__title">
      <h1 class="font--h3"  v-html="$t('legal.policy.page_title')"></h1>
      <h2 class="font--b5">
        {{
          $t("legal.policy.page_update_date", {
            date: new Intl.DateTimeFormat("en-US").format(new Date())
          })
        }}
      </h2>
    </div>
    <section class="legal__info">
      <legal-sidebar
        v-if="!$device.isMobile"
        ref="sideBarRef"
        :titles="headers"
        @click-title="handleClickTitle"
      />
      <base-separator
        v-if="!$device.isMobile"
        vertical
        :style="{ height: sideBarNode?.sideBarRef?.clientHeight + 'px' }"
      />
      <div class="text">
        <span class="text__paragraph" id="0">
          <h5 class="font--h4" v-html="$t('legal.policy.headers.0')"></h5>
          <p class="font--b2"  v-html="$t('legal.policy.paragraphs.0.text')"></p>
          <p class="font--b2"  v-html="$t('legal.policy.paragraphs.1.text')"></p>
          <p class="font--b2"  v-html="$t('legal.policy.paragraphs.2.text')"></p>
        </span>
        <span class="text__paragraph" id="1">
          <h5 class="font--h4" v-html="$t('legal.policy.headers.1')"></h5>
          <span>
            <p class="font--b2"  v-html="$t('legal.policy.paragraphs.3.text')"></p>
          <p class="font--b2"  v-html="$t('legal.policy.paragraphs.4.text')"></p>
            <ul class="ul-style ul-style--dots font--b2-4" v-html="$t('legal.policy.ul.0')"></ul>
            <br />
            <p class="font--b2"  v-html="$t('legal.policy.paragraphs.5.text')"></p>
          </span>
        </span>
        <span class="text__paragraph" id="2">
          <h5 class="font--h4" v-html="$t('legal.policy.headers.2')"></h5>
          <span>
            <p class="font--b2" v-html="$t('legal.policy.paragraphs.6.text')"></p>
            <ul class="ul-style ul-style--dots font--b2-4"  v-html="$t('legal.policy.ul.1')"></ul>
            <br />
            <p class="font--b2"  v-html="$t('legal.policy.paragraphs.7.text')"></p>
          </span>
        </span>
        <span class="text__paragraph" id="3">
          <h5 class="font--h4" v-html="$t('legal.policy.headers.3')"></h5>
          <span>
            <p class="font--b2"  v-html="$t('legal.policy.paragraphs.8.text')"></p>
            <br />
            <p class="font--b2-4 text-violet-main" v-html="$t('legal.policy.sub_headers.0')">
            </p>
            <p class="font--b2"  v-html="$t('legal.policy.paragraphs.9.text')">
            </p>
            <ul class="ul-style ul-style--dots font--b2-4"  v-html="$t('legal.policy.ul.2')"></ul>
            <br />
            <br />
            <p class="font--b2"  v-html="$t('legal.policy.paragraphs.10.text')"></p>
            <ul class="ul-style ul-style--dots font--b2-4"  v-html="$t('legal.policy.ul.3')"></ul>
            <br />
            <br />
            <p class="font--b2-4 text-violet-main" v-html="$t('legal.policy.sub_headers.1')"></p>
            <p class="font--b2"  v-html="$t('legal.policy.paragraphs.11.text')"></p>
            <ul class="ul-style ul-style--dots font--b2-4"  v-html="$t('legal.policy.ul.4')"></ul>
            <br />
            <br />
            <p class="font--b2-4 text-violet-main" v-html="$t('legal.policy.sub_headers.2')"></p>
            <p class="font--b2"  v-html="$t('legal.policy.paragraphs.12.text')"></p>
            <ul class="ul-style ul-style--dots font--b2-4"  v-html="$t('legal.policy.ul.5')"></ul>
          </span>
        </span>
        <span class="text__paragraph" id="4">
          <h5 class="font--h4" v-html="$t('legal.policy.headers.4')"></h5>
          <span>
            <p class="font--b2"  v-html="$t('legal.policy.paragraphs.13.text')"></p>
            <ul class="ul-style ul-style--dots font--b2-4"  v-html="$t('legal.policy.ul.6')"></ul>
            <br />
            <br />
            <p class="font--b2"  v-html="$t('legal.policy.paragraphs.14.text')"></p>
          </span>
        </span>
        <span class="text__paragraph" id="5">
          <h5 class="font--h4" v-html="$t('legal.policy.headers.5')"></h5>
          <span>
            <p class="font--b2"  v-html="$t('legal.policy.paragraphs.15.text')"></p>
            <ul class="ul-style ul-style--dots font--b2-4"  v-html="$t('legal.policy.ul.7')"></ul>
            <br />
            <br />
            <p class="font--b2"  v-html="$t('legal.policy.paragraphs.16.text')"></p>
          </span>
        </span>
        <span class="text__paragraph" id="6">
          <h5 class="font--h4" v-html="$t('legal.policy.headers.6')"></h5>
          <span>
            <p class="font--b2"  v-html="$t('legal.policy.paragraphs.17.text')"></p>
            <ul class="ul-style ul-style--dots font--b2-4"  v-html="$t('legal.policy.ul.8')"></ul>
          </span>
        </span>
        <span class="text__paragraph" id="7">
          <h5 class="font--h4" v-html="$t('legal.policy.headers.7')"></h5>
          <p class="font--b2"  v-html="$t('legal.policy.paragraphs.18.text')"></p>
        </span>
        <span class="text__paragraph" id="8">
          <h5 class="font--h4" v-html="$t('legal.policy.headers.8')"></h5>
          <p class="font--b2"  v-html="$t('legal.policy.paragraphs.19.text')"></p>
        </span>
        <span class="text__paragraph" id="9">
          <h5 class="font--h4" v-html="$t('legal.policy.headers.9')"></h5>
          <p class="font--b2"  v-html="$t('legal.policy.paragraphs.20.text')"></p>
        </span>
        <span class="text__paragraph" id="10">
          <h5 class="font--h4" v-html="$t('legal.policy.headers.10')"></h5>
          <p class="font--b2"  v-html="$t('legal.policy.paragraphs.21.text')"></p>
        </span>
        <span class="text__paragraph" id="11">
          <h5 class="font--h4" v-html="$t('legal.policy.headers.11')"></h5>
          <p class="font--b2"  v-html="$t('legal.policy.paragraphs.22.text')"></p>
          <p class="font--b2"  v-html="$t('legal.policy.paragraphs.23.text')"></p>
        </span>

        <span class="text__paragraph" id="12">
          <h5 class="font--h4" v-html="$t('legal.policy.headers.12')"></h5>
          <p class="font--b2"  v-html="$t('legal.policy.paragraphs.24.text', {email:'hi@rwa-estate.com '})"></p>
        </span>
      </div>
    </section>
  </section>
</template>

<script setup lang="ts">
const { tm } = useI18n()

const sideBarNode = useTemplateRef("sideBarRef")

const handleClickTitle = (val: string) => {
  if (val) {
    const element = document.getElementById(val)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }
}

const headers = computed(() => {
  const headersList = tm("legal.policy.nav") as unknown as {
    title: string
  }[]
  return headersList ?? []
})
</script>

<style scoped lang="scss"></style>
