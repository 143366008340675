<template>
  <section class="timeline-container" :class="[{ isEdit: props.isEdit }]">
    <div class="timeline">
      <span
        v-for="(point, index) in props.isEdit
          ? timeLineArray
          : timeLineArray.filter((q) => q.isActive)"
        class="timeline-box"
      >
        <span class="round-flexbox">
          <span
            v-if="!props.isEdit && point.isActive"
            class="round"
            :style="{
              background: point.isActive
                ? point.activeColor
                : 'var(--white-monochrome)',
            }"
          >
            <suspense>
              <base-icon
                :name="`base/${point.icon}`"
                :color="
                  point.isActive
                    ? 'var(--white-contrast)'
                    : 'var(--violet-main)'
                "
              />
            </suspense>
          </span>
          <q-checkbox v-if="props.isEdit" v-model="point.isActive" size="58px">
            <q-popup-proxy
              transition-show="scale"
              transition-hide="scale"
              style="padding: 10px"
              @before-hide="
                () => {
                  if (!point.date) {
                    point.isActive = false;
                  } else {
                    callCalendar(point);
                  }
                }
              "
            >
              <strong>
                must be as date format YYYY-MM-DD, YYYY-QQ (e.g. 2024-Q1),
                YYYY-MM or YYYY
              </strong>
              <q-input filled v-model="point.date"></q-input>
            </q-popup-proxy>
          </q-checkbox>

          <span
            v-if="props.isEdit || point.isActive"
            class="text-black-monochrome"
            :class="[$device.isMobile ? 'font--b3' : 'font--b1']"
            style="display: flex; flex-direction: column"
          >
            {{ point.text }}
            <p
              v-if="point.isActive"
              class="point-date font--b5 text-black-monochrome-60"
            >
              {{ formatDateToWords(point.date) }}
            </p>
          </span>
        </span>
        <base-icon
          v-if="
            point.isActive &&
            timeLineArray.filter((q) => q.isActive).length !== index + 1
          "
          name="base/DashDeviderVertical"
          filled
          style="margin-left: 17px"
        />
      </span>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { EstateTimelineDTO } from "~/services/swagger/Api";
import { formatDateToWords } from "~/utilities/helpers/format-data/date";

interface IProps {
  isEdit?: boolean;
  timelineArr: EstateTimelineDTO[];
}

interface PropertyTimeline {
  icon: string;
  activeColor: string;
  isActive: boolean;
  text: string;
  date: string;
  stage: string;
}

const props = defineProps<IProps>();
const emit = defineEmits(["changeTimeline"]);

const { t } = useI18n();
const callCalendar = (point: PropertyTimeline) => {
  if (!props.isEdit) return;
  if (!point.isActive) {
    point.date = "";
  }
  nextTick(() => {
    emit("changeTimeline", point);
  });
};

const timeLineArray = ref<PropertyTimeline[]>([
  {
    icon: "construction",
    activeColor: "var(--black-monochrome)",
    isActive: false,
    text: t("timeline.stage_1"),
    date: "",
    stage: "ConstructionLaunched",
  },
  {
    icon: "CheckCircle",
    activeColor: "var(--violet-main)",
    isActive: false,
    text: t("timeline.stage_2"),
    date: "",
    stage: "ConstructionFinished",
  },
  {
    icon: "Clock",
    activeColor: "var(--violet-main)",
    isActive: false,
    text: t("timeline.stage_3"),
    date: "",
    stage: "ListingDate",
  },
  {
    icon: "Key",
    activeColor: "var(--violet-main)",
    isActive: false,
    text: t("timeline.stage_4"),
    date: "",
    stage: "PropertyAvaliableForRental",
  },
  {
    icon: "Coins",
    activeColor: "var(--violet-main)",
    isActive: false,
    text: t("timeline.stage_5"),
    date: "",
    stage: "StartOfFirstPayments",
  },
]);

const timelineComputed = computed(() => props.timelineArr);
const datherTimeline = () => {
  if (props.timelineArr) {
    props.timelineArr.forEach((q) => {
      timeLineArray.value.forEach((item) => {
        if (item.stage === q.stage) {
          item.isActive = true;
          item.date = q.date.split("T")[0];
        }
      });
    });
  }
};
watch(timelineComputed, () => {
  datherTimeline();
});

onMounted(() => {
  datherTimeline();
});
</script>

<style scoped lang="scss">
.timeline-container {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  border-radius: 16px;
  background: var(--white-contrast);
  box-shadow: var(--shadow-light);
  h5,
  p {
    cursor: default;
  }
  .timeline {
    display: grid;
    grid-template-columns: 1fr;
    width: fit-content;
    align-items: center;
    justify-items: center;

    &-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 6px;
      width: 100%;

      .round-flexbox {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 6px;
        gap: 16px;
        .round {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px;
          border-radius: 50%;
          border-radius: 40px;
          border: 1px solid var(--lilac-secondary);
        }
        .point-date {
        }
      }
    }
  }
}
.isEdit {
  box-shadow: none;
  background: transparent;
  margin-top: 10px;
}
</style>
