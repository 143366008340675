<template>
  <span class="custom-select-label custom-select-ellipsis">
    <span class="select-dot rotateable">
      <base-icon
        name="base/CurrencyDollar"
        size="14px"
        color="var(--white-contrast)"
      />
    </span>
    <p
      :class="[
        'font--b4',
        priceFilter?.length ? 'text-black-monochrome' : 'text-gray-monochrome',
      ]"
      style="white-space: nowrap"
    >
      {{ priceInput }}
    </p>
  </span>
  <base-icon
    name="base/ChevronDown"
    size="18px"
    color="var(--gray-monochrome)"
    :rot="currentSelectCard === 3 ? '180deg' : ''"
    class="arrow"
  />
  <transition name="fade">
    <div
      v-show="currentSelectCard === 3"
      class="select__card temp-card-no-checkbox"
      @mouseleave="emit('isUserInPriceRange',false)"
      @mouseenter="emit('isUserInPriceRange',true)"
    >
      <div class="wrapper">
        <transition name="fade">
          <base-loader v-if="isFiltersUpdating" :opacity="0.6" />
        </transition>
        <span class="custom-select-label">
          <span class="select-dot">
            <base-icon
              name="base/CurrencyDollar"
              size="14px"
              color="var(--white-contrast)"
            />
          </span>
          <p class="font--b2 text-black-monochrome">
            {{ $t("search_filters.fr_by_price") }}
          </p>
        </span>
        <span class="cards">
          <!-- <keep-alive> -->
          <base-price-range-slider
            v-if="prices && labels && maxPrice"
            :prices="prices"
            :labels="labels"
            :min-price="minPrice"
            :max-price="maxPrice"
            :update-func="updatePrice"
            no-delay
            :range="useFiltersStore.priseRange?.range"
            is-main
            @click.stop
            @emit-range="setPriceRange"
          />
        </span>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import type { PriceRangeProps } from "~/components/base/custom-select/types";
import { filtersStore } from "~/store/search_filters";
const props = defineProps<PriceRangeProps>();
const emit = defineEmits(['isUserInPriceRange'])

const useFiltersStore = filtersStore();
</script>

<style scoped lang="scss">
@import url(~/assets/styles/custom-select/index.scss);
</style>
