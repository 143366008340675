<template>
  <div class="admin-page fit-content">
    <div class="admin-container">
      <section class="content">
        <div>
          <item-editor />
        </div>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAdminStore } from "~/store/admin";

definePageMeta({
  layout: "admin-edit",
  middleware: "admin-auth-middleware",
});

useHead({
  title: "Admin",
  meta: [
    {
      name: "description",
      content: "Admin page.",
    },
  ],
});

const adminStore = useAdminStore();
const $q = useQuasar();

onMounted(async () => {
  $q.loading.show();
  await adminStore.getBlockchains();
  await adminStore.getCountries();
  await adminStore.fetchUnits();
  $q.loading.hide();
});
</script>

<style scoped>
.admin-page {
  width: 100%;
  max-width: 100%;
}

.admin-container {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  display: flex;
  flex-direction: column;
  flex-basis: 150px;
  border-right: 1px solid #ccc;
  padding: 20px;
  gap: 10px;
}

.unselected {
  opacity: 0.7;
}

.content {
  flex-grow: 1;
  padding: 20px;
}
</style>
