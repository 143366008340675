<script setup lang="ts">
const props = defineProps({
  maxWidth: {
    type: String,
    default: "510px",
  },
  percent: {
    type: Number,
    required: true,
  },
  type: {
    type: String,
    required: false,
    default: "default",
  },
});
</script>

<template>
  <section :class="[`progress__type--${props.type}`]">
    <div class="progress--container" :style="[{ 'max-width': props.maxWidth }]">
      <div class="progress--text-wrapper">
        <p class="font--b2 mobile-font--b4">Tokens Available For Purchase</p>
        <span class="progress--percent mobile-font--b2 font--b1"
          >{{ props.percent > 100 ? 100 : props.percent.toFixed(1) }}%</span
        >
      </div>
      <div class="wrapper">
        <div class="progress">
          <div
            class="progress--bar"
            :style="[
              {
                width: `${props.percent > 100 ? 100 : Math.trunc(props.percent)}%`,
              },
            ]"
          />
        </div>
        <span
          v-if="$props.type === 'line'"
          class="progress--percent mobile-font--b7 font--b1"
          >{{ props.percent > 100 ? 100 : props.percent.toFixed(1) }}%</span
        >
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.progress__type {
  &--line {
    .progress--text-wrapper {
      .progress--percent {
        display: none;
      }
    }

    .wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      .progress {
        width: 100%;
      }
    }
  }
}
.progress {
  height: 10px;
  border-radius: 20px;
  background: var(--gray-monochrome-40);
  overflow: hidden;
  &--container {
    width: 100%;
    overflow: hidden;
  }
  &--text-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 7px;
  }
  &--bar {
    height: 100%;
    border-radius: 20px;
    background: var(--purple-main);
    transition: 0.5s;
  }
  &--percent {
    display: block;
    text-align: center;
  }
}

@media (max-width: 32rem) {
  @include mobile-fonts;
}
</style>
