import type {
  CreateEstatePayloadDTO,
  CreateLandDTO,
  EstateResponseDTO,
} from "~/services/swagger/Api";
import type { IEstateFinance } from "~/types/unit-item-finance";

export interface ExtendedEstatePayloadDTO
  extends Omit<EstateResponseDTO, "finance"> {
  finance: IEstateFinance;
}

const unitObj: ExtendedEstatePayloadDTO = {
  countryId: undefined,
  location: undefined,
  coords: [],
  minimumInvestment: undefined,
  currency: undefined,
  price: undefined,
  name: undefined,
  description: undefined,
  area: undefined,
  ownershipStatus: undefined,
  ownershipText: undefined,
  stage: "complete",
  irr: undefined,
  apr: undefined,
  utilities:undefined,
  currentUse:undefined,
  finance: {
    projectTotalIncome: {
      value: 0,
      subfields: {
        projectedRentalIncome: 0,
        projectionAppreciation: 0,
      },
    },
    totalInvestmentValue: {
      value: 0,
      subfields: {
        underlyingAssetPrice: 0,
        legalFees: 0,
        platformFees: 0,
        auditFees: 0,
        registrationTax: 0,
        upfrontDAOLLCFees: 0,
        propertyUpgrades: 0,
      },
    },
    annualCashFlow: 0,
    monthCashFlow: 0,
    annualGrossIncome: 0,
    maintainingCosts: {
      value: 0,
      subfields: {
        propertyManagement: 0,
        maintenanceReserve: 0,
        utilities: 0,
        annualDAOLLCAdministrationAndFilingFees: 0,
        annualDAOAccountingService: 0,
        homeownersInsurance: 0,
      },
    },
    otherFees: 0,
  } as IEstateFinance,
  bathroom: undefined,
  bedroom: undefined,
  blockedField: [],
  referralLink: {
    id: "",
    createdAt: "",
    updatedAt: "",
    expiredAt: undefined,
    providerName: "",
    link: "",
    description: undefined,
    default: undefined,
  },
  translations: undefined,
  // tokenId: "",
  documents: [],
  partners: [],
  rating: 0,
  tags: [],
  token: {
    name: undefined,
    symbol: undefined,
    decimals: undefined,
    totalSupply: undefined,
    circulatingSupply: undefined,
    availableSupply: undefined,
    chainId: undefined,
    address: undefined,
    icon: undefined,
    currency: undefined,
    price: undefined,
    issuer: {
      name: "",
      description: "",
      url: "",
      logo: "",
      industry: "",
      foundingYear: 0,
    },
    jurisdiction: undefined,
    description: undefined,
    providerId: undefined,
  },
  timeline: [],
  files: [],
};


export const getUnitEmptyData = (): ExtendedEstatePayloadDTO => {
  return unitObj;
};
