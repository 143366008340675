import { apiGet } from "~/services/api";
import getRoute from "~/utilities/configs/apiMap";
import { getUnitEmptyData } from "~/utilities/helpers/admin/common";
import type {
  ChainDTO,
  CountryDTO,
  CreateEstatePayloadDTO,
  EstateResponseDTO,
  UpdateEstatePayloadDTO,
  CreateEstateTimelineDTO,
  ModerateReviewDTO,
  ReviewResponseDTO,
  CreatePartnerDTO,
  UpdatePartnerDTO,
  CreateCompanyDTO,
  UpdateCompanyDTO,
  UpdateUnitPayloadDTO,
  UnitFilePayloadDTO,
  PartnerResponseDTO,
  ApproveUnitUpdateDTO,
  EstateTimelineDTO,
  ProviderResponseDTO,
  UnitResponseDTO,
} from "~/services/swagger/Api";
import { Api } from "~/services/swagger/Api";
import { cloneDeep, isEqual } from "lodash";
import { Notification } from "~/services/notifications/toast";
import type { IEstateFinance } from "~/types/unit-item-finance";
import type { EstateStatus } from "~/types/base-property-info";
// import type { PropertyType } from "~/types/search-page-items";
import { PopUpServices } from "~/services/PopUp/callPopUp";
import type { UpdatedUnitResponseDTO } from "./unitPage";

const unitApproveFields: ApproveUnitUpdateDTO["fields"] = [
  "countryId",
  "location",
  "coords",
  "currency",
  "price",
  "minimumInvestment",
  "finance",
  "name",
  "description",
  "translations",
  "documents",
  "area",
  "ownershipStatus",
  "ownershipText",
  "referralLinkId",
  "stage",
  "irr",
  "apr",
  "bathroom",
  "bedroom",
  "utilities",
  "currentUse",
  "files",
];

type Field = (typeof unitApproveFields)[number];
const filterObjectByAllowedFields = (
  obj: Record<string, any>,
  allowedFields: readonly Field[]
): Record<string, any> => {
  return Object.keys(obj).reduce(
    (acc, key) => {
      if (allowedFields.includes(key as Field)) {
        acc[key] = obj[key];
      }
      return acc;
    },
    {} as Record<string, any>
  );
};

export interface ExtendedCreateUnitPayloadDTO
  extends Omit<CreateEstatePayloadDTO, "timeline"> {
  timeline: EstateTimelineDTO[];
}

export interface ExtendedCreateEstatePayloadDTO
  extends Omit<CreateEstatePayloadDTO, "finance"> {
  finance: IEstateFinance;
}
export interface ExtendedUpdateEstatePayloadDTO
  extends Omit<UpdateEstatePayloadDTO, "finance"> {
  finance: IEstateFinance;
}
export interface ExtendedEstateResponseDTO
  extends Omit<EstateResponseDTO, "finance"> {
  finance: IEstateFinance;
}

type PropertyType = "estate" | "land";

export interface CompanyResponseDTO {
  description: string;
  foundingYear: string;
  id?: string;
  industry: string;
  logo: string;
  name: string;
  url: string;
}

const nuxtApp = useNuxtApp();
const errorNotify = new Notification({
  type: "system",
});
const apiClient = new Api();
export const useAdminStore = defineStore("admin", {
  state: () => ({
    items: [] as UpdatedUnitResponseDTO[],
    currentItem: getUnitEmptyData() as unknown as UpdatedUnitResponseDTO,
    copyCurrentItem: null as null as unknown as UpdatedUnitResponseDTO,
    showModal: false,
    showUploadFilesModal: false as boolean,
    adminForm: "tokens" as "tokens" | "images" | "estates",
    formMode: "add" as "add" | "edit",
    currentPage: 1,
    limit: 12,
    totalPages: 0,
    totalItems: 0,
    blockchains: [] as ChainDTO[] | [],
    countries: [] as CountryDTO[] | [],
    images: [] as { fileId: string; url: string }[],
    docs: [] as { fileId: string; name: string }[],
    links: [] as { url: string; name: string }[],
    propertyType: "estate" as PropertyType,
    providers: [] as ProviderResponseDTO[],
  }),
  getters: {
    currentItemFiles: (state) => (fileType: string) =>
      state.currentItem.files?.filter(
        (file) => file && file.type === fileType
      ) || [],
  },
  actions: {
    getUnitClass() {
      const state = this;
      const callPopUp = PopUpServices();
      class Unit {
        async addProperty(item: UpdatedUnitResponseDTO) {
          const timeline = item.timeline;
          delete item.timeline;
          try {
            const token = useCookie("acc_token");
            const response = await apiClient.api.unitsControllerCreate(item, {
              headers: {
                Authorization: `Bearer ${token.value}`,
              },
            });
            if (response.ok && timeline && timeline.length) {
              timeline.forEach((q) => {
                state.updateTimeline(response.data.id, q);
              });
            }
            return response;
          } catch (error) {
            const errorMessage =
              (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
            errorNotify.createNewToast(errorMessage);
            throw error;
          }
        }
        async updateProperty(
          item: UpdatedUnitResponseDTO,
          partners: PartnerResponseDTO[] | undefined
        ) {
          try {
            const token = useCookie("acc_token");
            const estatePayload: UpdateUnitPayloadDTO =
              state.getChangedProperties(state.copyCurrentItem, item);

            delete estatePayload.timeline;
            delete estatePayload.providerId;
            delete estatePayload.partners;

            const files = item.files.map((item) => {
              if (item.file?.id) {
                return {
                  type: "image",
                  fileId: item.file?.id,
                };
              }
              return item;
            });
            this.updatePropertyPartners(item.id, partners);
            estatePayload.files = files as UnitFilePayloadDTO[];
            estatePayload.finance = item.finance;
            estatePayload.blockedField = item.blockedField;
            estatePayload.stage = item.stage;

            if (item.token?.issuer) {
              this.updatePropertyIssuer(item.token.id, item.token.issuer.id);
            }
            const response = await apiClient.api.unitsControllerUpdate(
              item.id,
              estatePayload,
              {
                headers: {
                  Authorization: `Bearer ${token.value}`,
                },
              }
            );
            await this.approveChanges(item.id, unitApproveFields);

            const index = state.items.findIndex((q) => q.id === item.id);
            if (index !== -1) {
              state.items[index].status = "draft";
            }
            return response;
          } catch (error) {
            const errorMessage =
              (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
            errorNotify.createNewToast(errorMessage);

            throw error;
          }
        }
        async onDeleteProperty(item: UpdatedUnitResponseDTO) {
          if (item && item.id) {
            try {
              const confirmed = await callPopUp.callPopUp({
                componentName: "BaseModalСonfirmation",
                data: {
                  message: "Are you sure you want to delete this property?",
                },
                persistent: true,
              });
              if (confirmed) {
                const token = useCookie("acc_token");
                try {
                  await apiClient.api.unitsControllerDelete(item.id, {
                    headers: {
                      Authorization: `Bearer ${token.value}`,
                    },
                  });
                } catch (error) {
                  const errorMessage =
                    (error as Error).message ||
                    nuxtApp.$i18n.t("errors.catch_all");
                  errorNotify.createNewToast(errorMessage);

                  throw error;
                }
                alert("Estate successfully deleted!");
                state.fetchUnits();
              }
            } catch (err: any) {
              alert(err?.error?.message || err?.message);
            }
          }
        }
        async onOpenEditPage(id: string) {
          try {
            const res = await apiClient.api.unitsControllerRetrieve(id);
            const partners = await apiClient.api.partnersControllerList2(id);
            const plainEstate = getUnitEmptyData();
            replaceNullValues(res.data, plainEstate);
            state.updateFinanceObject(plainEstate.finance, res.data.finance);
            res.data.files = res.data.files.map((file) => ({
              fileId: file.file.id,
              type: file.type,
              url: file.file.url,
              name: file.file.name,
            }));
            state.currentItem = res.data as UpdatedUnitResponseDTO;
            state.currentItem.partners = partners.data;
            state.copyCurrentItem = cloneDeep(
              state.currentItem as UpdatedUnitResponseDTO
            );
            state.formMode = "edit";
          } catch (error) {
            const errorMessage =
              (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
            errorNotify.createNewToast(errorMessage);
            throw new Error();
          }
        }
        async updatePropertyStatus(id: string, status: EstateStatus) {
          if (!status || !id) return;
          try {
            const token = useCookie("acc_token");

            const response = await apiClient.api.unitsControllerUpdateStatus(
              id,
              status,
              {
                headers: {
                  Authorization: `Bearer ${token.value}`,
                },
              }
            );
            return response;
          } catch (error) {
            const errorMessage =
              (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
            errorNotify.createNewToast(errorMessage);
            throw error;
          }
        }
        async getShadow(id: string) {
          if (!id) return;
          try {
            const token = useCookie("acc_token");
            const response = await apiClient.api.unitsControllerGetShadow(id, {
              headers: {
                Authorization: `Bearer ${token.value}`,
              },
            });
            return filterObjectByAllowedFields(
              response.data,
              unitApproveFields
            );
          } catch (error) {
            const errorMessage =
              (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
            errorNotify.createNewToast(errorMessage);
            throw error;
          }
        }
        async getBaseObj(id: string) {
          try {
            const res = await apiClient.api.unitsControllerRetrieve(id);

            return filterObjectByAllowedFields(res.data, unitApproveFields);
          } catch (error) {
            const errorMessage =
              (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
            errorNotify.createNewToast(errorMessage);
            throw new Error();
          }
        }
        async approveChanges(
          id: string,
          fields: ApproveUnitUpdateDTO["fields"]
        ) {
          try {
            await apiClient.api.unitsControllerApprove(id, {
              fields: [...fields],
            });
            this.updatePropertyStatus(id, "moderating");
            const index = state.items.findIndex((q) => q.id === id);
            if (index !== -1) {
              state.items[index].status = "moderating";
            }
            return true;
          } catch (error) {
            const errorMessage =
              (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
            errorNotify.createNewToast(errorMessage);
            return false;
          }
        }
        async updatePropertyIssuer(id: string, issuerId: string | undefined) {
          try {
            if (!issuerId) return null;
            const response =
              await apiClient.api.contractsControllerUpdateIssuer(id, {
                issuerId: issuerId,
              });
            if (!response.status.toString().startsWith("2")) {
              errorNotify.createNewToast("error adding issuer");
              return null;
            }
            return true;
          } catch (error) {
            errorNotify.createNewToast("error adding issuer");
            return null;
          }
        }
        async updatePropertyPartners(
          id: string,
          partners: PartnerResponseDTO[] | undefined
        ) {
          try {
            let ids = partners
              ?.map((item) => item.id)
              ?.filter((item) => item !== null && item !== undefined);
            if (!ids || !ids.length) {
              // ids = null;
            }

            const response = await apiClient.api.unitsControllerUpdatePartners(
              id,
              {
                partnerIds: ids,
                partners: [],
              }
            );
            if (!response.status.toString().startsWith("2")) {
              errorNotify.createNewToast("error adding partners");
              return null;
            }
            return true;
          } catch (error) {
            console.log("error: ", error);
            errorNotify.createNewToast("error adding partners");
            return null;
          }
        }
      }
      return new Unit();
    },
    async fetchUnits(args?: { isNoTable?: boolean }) {
      try {
        const data = await apiClient.api.unitsControllerListPost({
          limit: 10000,
        });
        if (Array.isArray(data?.data?.rows) && !args?.isNoTable) {
          this.items = data?.data?.rows as unknown as UpdatedUnitResponseDTO[];
        }
        return data?.data?.rows as unknown as UpdatedUnitResponseDTO[];
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        errorNotify.createNewToast(errorMessage);
        return [];
      }
    },

    async uploadFiles(files: File[], type: "image" | "document" | "covers") {
      if (type === "image" || type === "covers") {
        const images = await this.getUploadedFiles(files, type);
        //@ts-ignore
        this.currentItem.files.push(
          ...images.map((i) => ({
            fileId: i.fileId,
            type: i.type,
          }))
        );
        const newImg = images.map((i) => ({
          url: i.url,
          fileId: i.fileId,
        }));
        this.images.push(...newImg);
      } else if (type === "document") {
        const docs = await this.getUploadedFiles(files, type);
        //@ts-ignore
        this.currentItem.files.push(
          ...docs.map((i) => ({
            fileId: i.fileId,
            type: i.type,
          }))
        );
        const newDoc = docs.map((i) => ({
          name: i.name,
          fileId: i.fileId,
        }));
        this.docs.push(...newDoc);
      }
    },
    async updateQueue(item: string[]) {
      const token = useCookie("acc_token");
      try {
        const data = await apiClient.api.estatesControllerSetPriority(
          {
            estateIds: item,
          },
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          }
        );
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        errorNotify.createNewToast(errorMessage);
        throw error;
      }
    },

    async getUploadedFiles(
      files: File[],
      type: "image" | "document" | "covers"
    ) {
      const res = await Promise.all(files.map((file) => this.uploadFile(file)));
      return res.length
        ? res.map((file) => ({
            fileId: file.data.id,
            type,
            url: file.data.url,
            name: file.data.name,
          }))
        : [];
    },

    async uploadFile(file: File) {
      const token = useCookie("acc_token");
      // const config = useRuntimeConfig();
      // const baseUrl = config.public.baseURL as string;
      // const formData = new FormData();
      // console.log("formData: ", formData);
      // formData.append("file", file);
      // console.log("file: ", file);
      // for (let entry of formData.entries()) {
      //   console.log(entry);
      // }
      try {
        // return await useFetch(baseUrl + "/api/files", {
        //   method: "POST",
        //   body: { file: file },
        //   headers: {
        //     Authorization: `Bearer ${token.value}`,
        //   },
        // });
        return await apiClient.api.filesControllerUploadFile(
          { file: file },
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          }
        );
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        errorNotify.createNewToast(errorMessage);
        throw new Error("File upload failed");
      }
    },

    removeFile(fileId: string, type: "image" | "document" | "covers") {
      this.currentItem.files = this.currentItem.files?.filter(
        (f) => f.fileId !== fileId
      );
      if (type === "image" || type === "covers") {
        this.images = this.images.filter((i) => i.fileId !== fileId);
      } else if (type === "document") {
        this.docs = this.docs.filter((i) => i.fileId !== fileId);
      }
    },

    createNewProperty(id: string) {
      this.formMode = "add";
      this.currentItem =
        getUnitEmptyData() as unknown as UpdatedUnitResponseDTO;
    },

    async getBlockchains(): Promise<void> {
      try {
        const data = await apiClient.api.chainsControllerList();

        if (Array.isArray(data?.data)) {
          this.blockchains = data.data as ChainDTO[];
        } else {
          errorNotify.createNewToast("Invalid data format for blockchains");
          console.error("Invalid data format for blockchains");
        }
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        errorNotify.createNewToast(errorMessage);
        console.error(error);
      }
    },

    async getCountries(): Promise<void> {
      try {
        const data = await apiGet({
          url: getRoute({ endpont: "get_countries" }),
        });

        if (Array.isArray(data?.data?.value)) {
          this.countries = data.data.value as CountryDTO[];
        } else {
          errorNotify.createNewToast("Invalid data format for country list");
          console.error("Invalid data format for country list");
        }
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        errorNotify.createNewToast(errorMessage);
      }
    },

    updateUploadFilesModalState(value: boolean) {
      this.showUploadFilesModal = value;
    },
    async refreshAccessToken() {
      try {
        const refreshToken = useCookie("acc_refresh_token");

        if (!refreshToken) {
          throw new Error("No refresh token available");
        }

        const response = await apiClient.api.authControllerRefresh({
          headers: {
            Authorization: `Bearer ${refreshToken.value}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to refresh access token");
        }

        const newAccessToken = response.data?.accessToken;
        const token = useCookie("acc_token");
        token.value = newAccessToken;
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        errorNotify.createNewToast(errorMessage);
      }
    },
    getChangedProperties(obj1: any, obj2: any): any {
      if (isEqual(obj1, obj2)) {
        return {};
      }

      if (
        typeof obj1 !== "object" ||
        typeof obj2 !== "object" ||
        obj1 === null ||
        obj2 === null
      ) {
        return obj2;
      }

      const result: any = Array.isArray(obj1) ? [] : {};

      for (const key in obj2) {
        if (obj2.hasOwnProperty(key)) {
          if (!obj1.hasOwnProperty(key) || !isEqual(obj1[key], obj2[key])) {
            result[key] = this.getChangedProperties(obj1[key], obj2[key]);
          }
        }
      }

      return result;
    },
    async updateTimeline(id: string, item: CreateEstateTimelineDTO) {
      try {
        const token = useCookie("acc_token");
        const response = await apiClient.api.estatesControllerUpdateTimeline(
          id,
          item,
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          }
        );
        return response;
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        errorNotify.createNewToast(errorMessage);
        throw error;
      }
    },
    async deleteTimeline(id: string, item: CreateEstateTimelineDTO) {
      try {
        const token = useCookie("acc_token");
        const response = await apiClient.api.estatesControllerDeleteTimeline(
          id,
          item.stage,
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          }
        );
        return response;
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        errorNotify.createNewToast(errorMessage);
        throw error;
      }
    },

    updateFinanceObject(
      base: { [key: string]: any },
      received: { [key: string]: any } | undefined
    ): void {
      if (!received) return;
      for (const key in base) {
        if (base.hasOwnProperty(key)) {
          if (received.hasOwnProperty(key)) {
            // Если и base, и received по этому ключу содержат объекты, вызываем рекурсию
            if (
              typeof base[key] === "object" &&
              base[key] !== null &&
              typeof received[key] === "object" &&
              received[key] !== null
            ) {
              this.updateFinanceObject(base[key], received[key]);
            }
          } else {
            // Если ключа нет в received, добавляем его с базовым значением
            received[key] = base[key];
          }
        }
      }
    },

    async getAllReviews() {
      try {
        const res = await apiClient.api.reviewsControllerList2({ limit: 1000 });
        const rows = res?.data?.rows as ReviewResponseDTO[];
        if (!rows.length) return null;
        return rows;
      } catch (error) {
        return null;
      }
    },
    async acceptReview(args: { unitId: string; reviewId: string }) {
      try {
        const res = await apiClient.api.reviewsControllerModerate2(
          args.reviewId,
          { status: "Published" } as ModerateReviewDTO
        );
        if (!res.data) {
          return false;
        }
        return "Published";
      } catch (error) {
        return false;
      }
    },
    async deleteReview(args: { reviewId: string }) {
      try {
        await apiClient.api.reviewsControllerDelete2(args.reviewId);
        return true;
      } catch (error) {
        return false;
      }
    },
    async rejectReview(args: { reviewId: string; text: string }) {
      try {
        await apiClient.api.reviewsControllerModerate2(args.reviewId, {
          rejectText: args.text,
          status: "Rejected",
        });
        return true;
      } catch (error) {
        return false;
      }
    },
    async getProviders() {
      try {
        const res = await apiClient.api.providersControllerList();
        if (!res.data) return [];
        this.providers = res.data;
        return res.data;
      } catch (error) {
        return [];
      }
    },
    getHoldersClass() {
      const state = this;
      return new (class Holders {
        async fetchAllPartners(isUpdatePartnerId?: boolean) {
          try {
            const response = await apiClient.api.partnersControllerList({
              limit: 100,
            });
            if (!response.data.rows) return [];
            if (isUpdatePartnerId) {
              state.currentItem.partners.forEach((item) => {
                item.id = response.data.rows?.find(
                  (q) => q.name === item.name
                )?.id;
              });
            }
            return response.data.rows;
          } catch (error) {
            console.log("error: ", error);
          }
        }
        async createNewPartner(partner: CreatePartnerDTO) {
          try {
            const response =
              await apiClient.api.partnersControllerCreate(partner);
            if (!response.data.id) return null;
            return response.data.id;
          } catch (error) {
            console.log("error: ", error);
            return null;
          }
        }
        async createNewCompany(company: CreateCompanyDTO) {
          try {
            const response =
              await apiClient.api.companiesControllerCreate(company);
            if (!response.status.toString().startsWith("2")) return null;
            return true;
          } catch (error) {
            console.log("error: ", error);
            return null;
          }
        }
        async updateExistingPartner(id: string, partner: UpdatePartnerDTO) {
          try {
            const response = await apiClient.api.partnersControllerUpdate(
              id,
              partner
            );
            if (!response.data.id) return null;
            return response.data.id;
          } catch (error) {
            console.log("error: ", error);
            return null;
          }
        }
        async updateExistingCompany(id: string, company: UpdateCompanyDTO) {
          try {
            const response = await apiClient.api.companiesControllerUpdate(
              id,
              company
            );
            if (response.status !== 200) return null;
            return true;
          } catch (error) {
            console.log("error: ", error);
            return null;
          }
        }

        async deletePartner(id: string) {
          try {
            await apiClient.api.partnersControllerDelete(id);
            return errorNotify.createNewToast("Deleted!");
          } catch (error) {
            errorNotify.createNewToast("Error");
            return null;
          }
        }
        async deleteIssuer(id: string) {
          try {
            await apiClient.api.companiesControllerDelete(id);
            return errorNotify.createNewToast("Deleted!");
          } catch (error) {
            errorNotify.createNewToast("Error");
            return null;
          }
        }
        async fetchAllCompanies() {
          try {
            const response = await apiGet({
              url: getRoute({ endpont: "get_companies" }),
              headers: {
                Authorization: `Bearer ${useCookie("acc_token").value}`,
              },
            });
            const data = response?.data
              .value as unknown as CompanyResponseDTO[];
            if (!data) return [];
            return data;
          } catch (error) {
            errorNotify.createNewToast("Error");
            return [];
          }
        }
      })();
    },
    async getLoggerSnapshots(unitId: string) {
      try {
        const res = await apiGet({
          url: `/api/units/${unitId}/snapshots`,
          headers: {
            Authorization: `Bearer ${useCookie("acc_token").value}`,
          },
        });
        const unitsSnapshots = res?.data.value as unknown as UnitResponseDTO[];
        if (!unitsSnapshots) return null;
        return unitsSnapshots;
      } catch (error) {
        return null;
      }
    },
  },
});

const replaceNullValues = (data: any, currentItem: any) => {
  if (!data || !currentItem) return;

  for (const key in currentItem) {
    if (!(key in data)) {
      data[key] = currentItem[key];
    } else if (data[key] === null || data[key] === undefined) {
      data[key] = currentItem[key];
    } else if (typeof data[key] === "object" && data[key] !== null) {
      replaceNullValues(data[key], currentItem[key]);
    }
  }
};
