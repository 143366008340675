<template>
  <div
    v-if="tagsMap.includes(props.variant)"
    class="base-tag"
    :class="[
      `base-tag--${props.variant}`,
      `base-tag--${props.size}`,
      `base-tag--${props.sub_variant}`,
      {
        'base-tag--round': props.round,
      },
    ]"
    v-bind="$attrs"
  >
    <base-icon
      v-if="tagVariant.icon"
      size="100%"
      :name="tagVariant.icon"
      :class="[`base-tag--${props.variant}`, `base-tag--${props.size}-icon`]"
      style="border: none"
    />
    <p v-if="!props.round" :class="[`font--${props.font}`]">
      {{ tagVariant.name }}
    </p>
  </div>
</template>

<script setup lang="ts">
import type { BaseTagProps } from "~/types/base-tag";

const props = withDefaults(defineProps<BaseTagProps>(), {
  variant: "trending",
  font: "b5",
  size: "standart",
});

const tagsMap = ["new", "trending", "best", "favorites"];

const tagVariant = computed(() => {
  switch (props.variant) {
    case "new":
      return { name: "New", icon: "base/Sparkle" };
    case "best":
      return { name: "Best", icon: "base/Star" };
    case "favorites":
      return { name: "Favorites", icon: "base/Star" };
    case "trending":
      return { name: "Trending", icon: "base/Fire" };
    case "dark":
      return { name: "", icon: "" };
    default:
      return { name: "New", icon: "base/Sparkle" };
  }
});

const borderColor = computed(() => {
  switch (props.variant) {
    case "new":
      return "var(--green-main)";
    case "best":
      return "var(--purple-main)";
    case "favorites":
      return "var(--orange-sec)";
    case "trending":
      return "var(--orange-sec)";
    case "dark":
      return "var(--black-monochrome)";
    default:
      return "new";
  }
});
</script>

<style scoped lang="scss">
@mixin base-backdround {
  background-color: var(--white-contrast);
}
@mixin invisible-border {
  border-color: transparent;
}
@mixin visible-border {
  border-color: v-bind(borderColor);
}

@mixin best {
  color: var(--purple-main);
  border: 1px solid var(--purple-main);
  @include base-backdround;
  @include invisible-border;
}

@mixin trending {
  color: var(--orange-sec);
  border: 1px solid var(--orange-sec);
  @include base-backdround;
}
@mixin favorites {
  color: var(--black-monochrome);
  :deep(.base-icon) {
    color: var(--orange-sec);
  }
  border: 1px solid var(--gray-40);
  @include base-backdround;
}

@mixin new {
  color: var(--green-main);
  border: 1px solid var(--green-main);
  @include base-backdround;
}

@mixin dark {
  color: var(--black-monochrome);
  border: 1px solid var(--black-monochrome);
  background-color: var(--gray-60);
  @include invisible-border;
}
@mixin ghost {
  background-color: transparent;
  @include visible-border;
}

.base-tag {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  z-index: 2;
  overflow: hidden;
  cursor: default;
  &::before {
    content: "";
    position: absolute;
    background: var(--white-contrast);
    width: 60%;
    height: 100%;
    top: 0%;
    left: -125%;
    transform: skew(45deg);
    transition: left 500ms ease-out;
    z-index: 3;
    opacity: 0.5;
  }
  &:hover {
    &::before {
      left: 150%;
    }
  }
  &--standart {
    height: 36px;
    padding: 8px 16px 8px 12px;
  }
  &--big {
    height: 40px;
    padding: 6px 16px 6px 12px;
  }
  &--small {
    height: 24px;
    padding: 5px 8px;
    gap: 2px;
    font-size: 10px;
    font-weight: 400;
    line-height: 130%;
    &-icon {
      :deep(.icon) {
        width: 12px;
        height: 12px;
      }
    }
  }
  &--search {
    height: 30px;
    padding: 5px 12px 5px 8px;
    gap: 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    &-icon {
      :deep(.icon) {
        width: 16px;
        height: 16px;
      }
    }
  }
  &--best {
    @include best;
  }
  &--trending {
    @include trending;
  }
  &--favorites {
    @include favorites;
  }
  &--new {
    @include new;
  }
  &--dark {
    @include dark;
  }
  &--ghost {
    @include ghost;
  }
  &--round {
    padding: 10px;
    border-radius: 50%;
    gap: 0;
    min-width: 36px;
  }
}
:deep(.nuxt-icon) {
  svg {
    margin: 0;
  }
}
</style>
