<template>
  <section class="search-card__visitors">
    <div class="visitors-avatars">
      <base-icon
        name="base/Eye"
        color="var(--positive-secondary)"
        size="16px"
      />
    </div>
    <p class="day-counter font--b4 text-positive-secondary">
      {{
        $t("search_card.fr_visited_today", {
          visited: props.day?.toLocaleString(),
        })
      }}
    </p>
    <p
      v-if="!route.params.id"
      class="alltime-counter font--b2 text-black-monochrome"
    >
      {{
        $t("search_card.fr_visitors_total", {
          visited: props.allTime?.toLocaleString(),
        })
      }}
    </p>
  </section>
</template>

<script setup lang="ts">
interface IProps {
  day: number;
  allTime?: number;
}

const route = useRoute();

const props = defineProps<IProps>();
</script>

<style scoped lang="scss">
.search-card__visitors {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;
  p {
    cursor: default;
  }
  .visitors-avatars {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .overlapping {
      border: 2px solid white;
      position: absolute;
      left: 0;
      z-index: 999999;
    }
  }
  .alltime-counter {
    margin-left: auto;
  }
}
</style>
