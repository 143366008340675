<template>
  <div ref="mapBoxRef" class="map-box">
    <div
      v-if="mapBoxRef && Array.isArray(props.coords) && props.coords.length"
      class="map-backdrop"
    >
      <map-static
        :is-info="false"
        :coords="props.coords"
        :map-height="mapBoxRef.clientHeight + 'px'"
        no-controls
        no-gesture
      />
    </div>
    <span class="map-box-wrapper">
      <p class="font--b3 modile-font--b4 text-black-monochrome">
        {{ props.location || "Location is not provided =(" }}
      </p>
      <span class="country-flexbox">
        <suspense>
          <base-icon
            :name="`flags/${props.countryName?.split(' ').join('_').toLowerCase() || 'none'}`"
            size="20px"
            filled
          />
        </suspense>
        <p class="font--b5 modile-font--b7 text-black-monochrome">
          {{ props.countryName || "Unknown" }}
        </p>
      </span>
    </span>
    <button
      v-if="
        !$device.isMobile && Array.isArray(props.coords) && props.coords.length
      "
      class="map-icon"
      @click.stop="openMapPopUp"
    >
      <suspense>
        <base-icon :name="`base/${isMapOpen ? 'Close' : 'MapIcon'}`" />
      </suspense>
    </button>
  </div>
</template>

<script setup lang="ts">



interface IProps {
  location: string | undefined;
  countryName: string | undefined;
  coords: number[] | null | undefined;
  arrLength: number | undefined;
  parentHeight: HTMLElement | null;
  isMapOpen: boolean | null;
}

const props = defineProps<IProps>();
const emit = defineEmits(["open-mobile", "open-decktop"]);

const { isMobile } = useDevice();

const isMapOpen = computed(
  () => props.isMapOpen && typeof props.isMapOpen === "boolean"
);

const mapBoxRef = ref<HTMLElement | null>(null);

const openMapPopUp = () => {
  if (isMobile) {
    emit("open-mobile");
  } else {
    emit("open-decktop");
  }
};
</script>

<style scoped lang="scss">
.map-box {
  position: absolute;
  inset: 0;
  padding: 16px;

  .map-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
  }
  &-wrapper {
    display: grid;
    width: 80%;
    gap: 8px;
    border-radius: 8px;
    padding: 6px 8px;

    height: fit-content;
    background: rgba(243, 247, 254, 0.8);
    backdrop-filter: blur(2px);
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .map-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    outline: none;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
  }
  .country-flexbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
}

@media (max-width: 65rem) {
  @include mobile-fonts;
  .map-box {
    .map-icon {
      top: unset;
      bottom: 12px;
      right: 12px;
    }
    &-wrapper {
      width: 100%;
      height: 100%;
      background: transparent;
      padding: 0;
      backdrop-filter: none;
    }
  }
}
</style>
