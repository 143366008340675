export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.client) {
    nuxtApp.hooks.hookOnce("page:start", (page) => {
      const config = useRuntimeConfig();
      const isProduction = config.public.NUXT_SITE_ENV === "production";
      if (isProduction) {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-PXLLPJB19H";
        document.head.appendChild(script);
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-PXLLPJB19H");
        nuxtApp.provide("gtag", gtag);
      }
    });
  }
  
});
