<template>
  <div
    :class="[
      'portfolio__top-block',
      { 'portfolio__top-block--major': props.isMajot },
    ]"
  >
    <div class="info">
      <p class="font--b1-2 info-title">{{ props.title }}</p>
      <p class="font--b5">{{ props.subtitle }}</p>
    </div>
    <div v-if="props.isBadge" class="badge">
      <base-icon
        :name="`base/${props.isUp ? 'TrendUp' : 'TrendDown'}`"
        size="20px"
        :color="
          props.isUp ? 'var( --positive-secondary)' : 'var(--negative-sec)'
        "
      />
      <span
        class="font--b5 badge__value"
        :class="{ 'badge__value--up': props.isUp }"
        ><span>{{ props.isUp ? "+" : "-" }}</span>
        {{ formatPercentage(props.badgeValue) }}%</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { formatPercentage } from "~/utilities/helpers/format-data/number";

interface IProps {
  title: string;
  subtitle: string;
  isBadge?: boolean;
  isUp?: boolean;
  badgeValue?: number;
  isMajot?: boolean;
}

const props = defineProps<IProps>();
</script>

<style scoped lang="scss">
.portfolio__top-block {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  box-shadow: var(--shadow-light);
  width: 100%;
  max-width: 316px;
  height: 100%;
  min-height: 106px;
  cursor: default;
  border: 1px solid var(--gray-monochrome-40);
  background-color: var(--white-monochrome);
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  &--major {
    background-color: var(--white-contrast);
    .info {
      gap: 15px;
      &-title {
        font-size: 40px;
        font-weight: 700;
      }
    }
  }

  .badge {
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 30px;
    border: 1px solid var(--gray-monochrome-40);
    margin-bottom: auto;
    &__value {
      color: var(--negative-sec);
      &--up {
        color: var(--positive-secondary);
      }
    }
  }
}
</style>
