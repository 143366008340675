<template>
    <q-card>
      <q-card-section>
        <div class="text-h6">{{ data?.message }}</div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="negative" @click="cancel" />
        <q-btn flat label="Delete"  color="positive" @click="confirm" />
      </q-card-actions>
    </q-card>
</template>

<script setup>
const props = defineProps(['data']);
const emit = defineEmits(['hide_popup']);

const cancel = () => {
  emit('hide_popup', 'ca');
};

const confirm = () => {
  emit('hide_popup', 'ok');
};
</script>
