interface ReCaptcha {
  execute: (action: string) => Promise<string>;
}

const siteKey = "6LcFvZ8qAAAAANAY1gDN04rOfhQlbWJT_BEpQxTj";

const loadReCaptchaScript = () => {
  return new Promise((resolve, reject) => {
    if (document.getElementById("recaptcha-script")) {
      resolve(null);
      return;
    }
    const script = document.createElement("script");
    script.id = "recaptcha-script";
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}&badge=bottomleft`;
    script.async = true;
    script.defer = true;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

const createReCaptchaContainer = (): HTMLElement => {
  let container = document.getElementById("recaptcha-container");
  if (!container) {
    container = document.createElement("div");
    container.id = "recaptcha-container";
    container.style.display = "none"; // Чтобы контейнер был невидим
    document.body.appendChild(container);
  }
  return container;
};

const renderReCaptcha = () => {
  return new Promise<number>((resolve, reject) => {
    if (window.grecaptcha && window.grecaptcha.enterprise) {
      window.grecaptcha.enterprise.ready(() => {
        try {
          const container = createReCaptchaContainer();
          const widgetId = window.grecaptcha.enterprise.render(container, {
            sitekey: siteKey,
            size: "invisible",
            badge: "bottomleft",
          });
          resolve(widgetId);
        } catch (error) {
          reject(error);
        }
      });
    } else {
      reject(new Error("ReCaptcha Enterprise is not loaded."));
    }
  });
};

const executeReCaptcha = async (action: string) => {
  await loadReCaptchaScript();
  return renderReCaptcha()
    .then((widgetId) => {
      return window.grecaptcha.enterprise.execute(widgetId, { action });
    })
    .catch((error) => {
      throw new Error(`Failed to execute reCAPTCHA: ${error}`);
    });
};

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.client) {
    const recaptcha: ReCaptcha = {
      execute: executeReCaptcha,
    };
    nuxtApp.provide("recaptcha", recaptcha);
  }
});
