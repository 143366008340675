export const updateFaqSeo = () => {
  const title =
    "Frequently Asked Questions (FAQ) | RWA Estate - Learn About Tokenized Real Estate";
  const descriptions =
    "Find answers to your questions about RWA Estate and tokenized real estate. Learn how tokenization works, the benefits of investing, and how to track property growth. Explore the most frequently asked questions about real-world assets (RWA) and more.";
  useSchemaOrg([
    defineWebSite({
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "RWA Estate",
      url: "https://www.rwa-estate.com/faq",
    }),
  ]);

  useSeoMeta({
    ogTitle: title,
    ogDescription: descriptions,
    keywords: [
      "Frequently Asked Questions",
      "Tokenized Real Estate FAQ",
      "RWA Estate Questions",
      "Real Estate Tokenization",
      "Investing in Tokenized Properties",
      "Real-World Assets (RWA)",
      "Property Growth Tracking",
      "Tokenized Real Estate Investment Benefits",
    ].join(", "),
    ogImage: "https://www.rwa-estate.com/hero.png",
    ogUrl: "https://www.rwa-estate.com/faq",
    twitterCard: "summary_large_image",
    twitterTitle: title,
    twitterDescription: descriptions,
    twitterImage: "https://www.rwa-estate.com/hero.png",
  });

  useHead({
    title: title,
    link: [
      {
        rel: "canonical",
        href: `https://www.rwa-estate.com/faq`,
      },
    ],
  });
};
