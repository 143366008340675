<template>
  <q-tabs
    v-model="adminStore.$state.propertyType"
    style="margin-bottom: 20px"
    @update:model-value="handleTabsChange"
  >
    <q-tab name="estate" label="Estates" />
    <q-tab name="land" label="Lands" />
  </q-tabs>
  <span class="add-item">
    <base-button
      text="+ Add item"
      size="sm"
      variant="prime"
      class="add-item-btn"
      @click="openAddTokenPage"
    />
  </span>
  <div class="editor-container">
    <div class="header">
      <span class="header-side">
        <q-input
          v-model="newInput"
          label="New"
          style="width: 100%"
          filled
          hint="Hidden in search. All estates from parser end up here."
        />
      </span>
      <span class="header-side">
        <q-input
          v-model="draftInput"
          label="For review"
          style="width: 100%"
          filled
          hint="Hidden in search. For review only."
        />
      </span>
      <span class="header-side">
        <q-input
          v-model="moderatingInput"
          label="Moderating"
          style="width: 100%"
          filled
          hint="Hidden in search"
        />
      </span>
      <span class="header-side">
        <q-input
          v-model="previewInput"
          label="Preview"
          style="width: 100%"
          filled
          hint="Hidden in search. Available via direct link for everyone."
        />
      </span>
      <span class="header-side">
        <q-input
          v-model="publishedInput"
          label="Published"
          style="width: 100%"
          filled
          hint="Visible in search."
        />
      </span>
      <span class="header-side">
        <q-input
          v-model="soldOutInput"
          label="Sold out"
          style="width: 100%"
          filled
          hint="Visible in search. Available via direct link for everyone."
        />
      </span>
    </div>
    <section class="tables">
      <ul
        class="item-list"
        :class="{ 'dragging-over': currentDragOver === 'new' }"
        @dragover.prevent="onDragOver('new')"
        @drop="onDrop('new')"
      >
        <li
          v-for="item in filteredNewItems"
          :key="item.id"
          class="item-item"
          draggable="true"
          @dragstart="onDragStart(item, 'new')"
          @dragend="onDragEnd"
        >
          <span class="item-name">
            <span>{{ item.name }}</span>
          </span>
          <button class="priview-butt">
            <base-icon name="base/Menu" size="10px" />
            <q-menu>
              <q-list style="min-width: 100px">
                <q-item
                  v-close-popup
                  clickable
                  @click="openPreview(item.sanitizeName)"
                >
                  <q-item-section>Preview</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </button>
        </li>
      </ul>
      <ul
        class="item-list"
        :class="{ 'dragging-over': currentDragOver === 'draft' }"
        @dragover.prevent="onDragOver('draft')"
        @drop="onDrop('draft')"
      >
        <li
          v-for="(item, index) in filteredDraftItems"
          :key="item.id"
          class="item-item"
          draggable="true"
          @dragstart="onDragStart(item, 'draft')"
          @dragend="onDragEnd"
        >
          <span class="item-name">
            <span>{{ item.name }}</span>
          </span>

          <button class="priview-butt">
            <base-icon name="base/Menu" size="10px" />
            <q-menu>
              <q-list style="min-width: 100px">
                <q-item v-close-popup clickable @click="openCompare(item.id)">
                  <q-item-section>Compare</q-item-section>
                </q-item>
                <!-- <q-separator />
                <q-item
                  v-close-popup
                  clickable
                  @click="openEditTokenPage(item)"
                >
                  <q-item-section>Edit</q-item-section>
                </q-item>
                <q-separator />
                <q-item
                  v-close-popup
                  clickable
                  @click="currentPropertyType.onDeleteProperty(item)"
                >
                  <q-item-section style="color: red">Delete</q-item-section>
                </q-item> -->
              </q-list>
            </q-menu>
          </button>
          <q-badge v-if="item.newData" color="red" rounded floating />
        </li>
      </ul>
      <ul
        class="item-list"
        :class="{ 'dragging-over': currentDragOver === 'moderating' }"
        @dragover.prevent="onDragOver('moderating')"
        @drop="onDrop('moderating')"
      >
        <li
          v-for="(item, index) in filteredModeratingItems"
          :key="item.id"
          class="item-item"
          draggable="true"
          @dragstart="onDragStart(item, 'moderating')"
          @dragend="onDragEnd"
        >
          <span class="item-name">
            <span>{{ item.name }}</span>
          </span>
          <button class="priview-butt">
            <base-icon name="base/Menu" size="10px" />
            <q-menu>
              <q-list style="min-width: 100px">
                <q-item
                  v-close-popup
                  clickable
                  @click="openPreview(item.sanitizeName)"
                >
                  <q-item-section>Preview</q-item-section>
                </q-item>
                <q-separator />
                <q-item
                  v-close-popup
                  clickable
                  @click="openAdminLogger(item.id)"
                >
                  <q-item-section>Check logger</q-item-section>
                </q-item>
                <q-separator />
                <q-item
                  v-close-popup
                  clickable
                  @click="openEditTokenPage(item)"
                >
                  <q-item-section>Edit</q-item-section>
                </q-item>
                <q-separator />
                <q-item
                  v-close-popup
                  clickable
                  @click="UNIT.onDeleteProperty(item)"
                >
                  <q-item-section style="color: red">Delete</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </button>
          <q-badge v-if="item.newData" color="red" rounded floating />
        </li>
      </ul>
      <ul
        class="item-list"
        :class="{ 'dragging-over': currentDragOver === 'preview' }"
        @dragover.prevent="onDragOver('preview')"
        @drop="onDrop('preview')"
      >
        <li
          v-for="item in filteredPreviewItems"
          :key="item.id"
          class="item-item preview-clickable"
          draggable="true"
          @dragstart="onDragStart(item, 'preview')"
          @dragend="onDragEnd"
          @click.stop="openPreview(item.sanitizeName)"
        >
          <span class="item-name">
            <span>{{ item.name }}</span>
          </span>
        </li>
      </ul>
      <ul
        class="item-list"
        :class="{ 'dragging-over': currentDragOver === 'published' }"
        @dragover.prevent="onDragOver('published')"
        @drop="onDrop('published')"
      >
        <li
          v-for="item in filteredPublishedItems"
          :key="item.id"
          class="item-item"
          draggable="true"
          @dragstart="onDragStart(item, 'published')"
          @dragend="onDragEnd"
        >
          <span class="item-name">
            <span>{{ item.name }}</span>
          </span>
          <q-badge v-if="item.newData" color="red" rounded floating />
        </li>
      </ul>
      <ul
        class="item-list"
        :class="{ 'dragging-over': currentDragOver === 'sold_out' }"
        @dragover.prevent="onDragOver('sold_out')"
        @drop="onDrop('sold_out')"
      >
        <li
          v-for="(item, index) in filteredSoldOutItems"
          :key="item.id"
          class="item-item"
          draggable="true"
          @dragstart="onDragStart(item, 'sold_out')"
          @dragend="onDragEnd"
        >
          <span class="item-name">
            <span>{{ item.name }}</span>
          </span>
          <q-badge v-if="item.newData" color="red" rounded floating />
        </li>
      </ul>
    </section>
  </div>
</template>

<script setup lang="ts">
import { useAdminStore } from "~/store/admin";
import type {
  EstateResponseDTO,
  LandResponseDTO,
} from "~/services/swagger/Api";
import { PopUpServices } from "~/services/PopUp/callPopUp";
import type { EstateStatus } from "~/types/base-property-info";
import _ from "lodash";
import type { UpdatedUnitResponseDTO } from "~/store/unitPage";
// import type { PropertyType } from "~/types/search-page-items";

const callPopUp = PopUpServices();
const adminStore = useAdminStore();
const $q = useQuasar();
const UNIT = adminStore.getUnitClass();
const items = computed(() =>
  adminStore.items.filter((item) => item.type === filterParam.value)
);
const filterParam = ref<UpdatedUnitResponseDTO["type"]>("Estate");

const newItems = computed(
  () =>
    items.value.filter(
      (item) => item.status === "new"
    ) as UpdatedUnitResponseDTO[]
);
const draftItems = computed(
  () =>
    items.value.filter(
      (item) => item.status === "draft"
    ) as UpdatedUnitResponseDTO[]
);
const moderatingItems = computed(
  () =>
    items.value.filter(
      (item) => item.status === "moderating"
    ) as UpdatedUnitResponseDTO[]
);
const publishedItems = computed(
  () =>
    items.value.filter(
      (item) => item.status === "published"
    ) as UpdatedUnitResponseDTO[]
);
const soldOutItems = computed(
  () =>
    items.value.filter(
      (item) => item.status === "sold_out"
    ) as UpdatedUnitResponseDTO[]
);
const previewItems = computed(
  () =>
    items.value.filter(
      (item) => item.status === "preview"
    ) as UpdatedUnitResponseDTO[]
);

const route = useRoute();
const currentDragOver = ref<EstateStatus | null>();

const onDragOver = (type: EstateStatus) => {
  if (!type) return;
  currentDragOver.value = type;
};

const openEditTokenPage = async (item: UpdatedUnitResponseDTO) => {
  try {
    window.open(
      window.location.origin +
        "/admin/edit/" +
        `${item.id}?type=${adminStore.propertyType}`,
      "_blank"
    );
  } catch (err: any) {
    alert(err?.error?.message || err?.message);
  }
};

const openAddTokenPage = async () => {
  const res = await callPopUp.callPopUp({
    componentName: "item-editor-select-type",
    persistent: false,
  });
  try {
    window.open(window.location.origin + "/admin/create/" + res, "_blank");
  } catch (err: any) {
    alert(err?.error?.message || err?.message);
  }
};
const openCompare = async (id: string) => {
  if (!id) return;
  try {
    const [baseObj, updatedProps] = await Promise.all([
      UNIT.getShadow(id),
      UNIT.getBaseObj(id),
    ]);
    const compare = deepDiff(updatedProps, baseObj);

    const res = await callPopUp.callPopUp({
      componentName: "item-editor-compare-units",
      persistent: false,
      data: { compare: compare, unit: UNIT, unitId: id },
    });
  } catch (error) {}
};

type DiffResult = {
  [key: string]: { old: any; new: any } | DiffResult;
};

const deepDiff = (obj1: any, obj2: any): DiffResult | undefined => {
  const normalizeValue = (value: any) => {
    if (typeof value === "string" && !isNaN(Number(value))) {
      return Number(value); // Преобразуем строку в число, если это возможно
    }
    return value;
  };

  const findDifferences = (
    value1: any,
    value2: any
  ): DiffResult | { old: any; new: any } | undefined => {
    const normValue1 = normalizeValue(value1);
    const normValue2 = normalizeValue(value2);

    // Если значения равны, разницы нет
    if (_.isEqual(normValue1, normValue2)) return undefined;

    // Если оба значения массивы
    if (Array.isArray(normValue1) && Array.isArray(normValue2)) {
      if (_.isEqual(normValue1, normValue2)) return undefined;
      return { old: normValue1, new: normValue2 };
    }

    // Если один из массивов отсутствует
    if (Array.isArray(normValue1) || Array.isArray(normValue2)) {
      return { old: normValue1, new: normValue2 };
    }

    // Если оба значения объекты
    if (_.isObject(normValue1) && _.isObject(normValue2)) {
      const diff: DiffResult = {};

      _.forEach(normValue1, (val, key) => {
        const difference = findDifferences(val, normValue2?.[key]);
        if (difference !== undefined) {
          diff[key] = difference;
        }
      });

      _.forEach(normValue2, (val, key) => {
        if (!(key in normValue1)) {
          diff[key] = { old: undefined, new: val };
        }
      });

      return _.isEmpty(diff) ? undefined : diff;
    }

    // Если значения не равны и не объекты
    return { old: normValue1, new: normValue2 };
  };

  return findDifferences(obj1, obj2);
};

const draggedItem = ref<UpdatedUnitResponseDTO | null>(null);
const draggedFromList = ref<EstateStatus | null>(null);

const onDragStart = (item: UpdatedUnitResponseDTO, list: EstateStatus) => {
  draggedItem.value = item;
  draggedFromList.value = list;
};

const AvailableStatusMap: Record<string, string[]> = {
  new: ["moderating"],
  draft: [],
  moderating: ["published", "preview"],
  preview: ["published", "moderating"],
  published: ["sold_out", "moderating"],
  sold_out: ["published"],
};

const onDrop = async (dropList: EstateStatus) => {
  if (draggedItem.value !== null && draggedFromList.value !== null) {
    const allowedStatuses = AvailableStatusMap[draggedFromList.value];

    if (!allowedStatuses.includes(dropList!)) {
      alert(
        `Moving from status "${draggedFromList.value}" to status "${dropList}" is not allowed.`
      );
      draggedItem.value = null;
      draggedFromList.value = null;
      return;
    }
    const updateList = (status: EstateStatus) => {
      switch (status) {
        case "new":
          return newItems.value;
        case "draft":
          return draftItems.value;
        case "moderating":
          return moderatingItems.value;
        case "published":
          return publishedItems.value;
        case "sold_out":
          return soldOutItems.value;
        case "preview":
          return previewItems.value;
        default:
          return [];
      }
    };

    const fromList = updateList(draggedFromList.value);
    const toList = updateList(dropList);

    const itemIndex = fromList.findIndex(
      (item) => item.id === draggedItem.value?.id
    );

    if (itemIndex > -1) {
      const [item] = fromList.splice(itemIndex, 1);

      item.status = dropList!;

      toList.push(item);

      await UNIT.updatePropertyStatus(item.id, dropList);

      draggedItem.value = null;
      draggedFromList.value = null;
    }
  }
};

const onDragEnd = () => {
  draggedItem.value = null;
  draggedFromList.value = null;
  currentDragOver.value = null;
};

const openPreview = (id: string) => {
  if (!id) return;
  window.open(`${window.location.origin}/property/${id}`);
};

const draftInput = ref("");
const publishedInput = ref("");
const newInput = ref("");
const moderatingInput = ref("");
const previewInput = ref("");
const soldOutInput = ref("");

const searchEstateByName = (name: string, items: UpdatedUnitResponseDTO[]) => {
  if (!name) return items;
  const filteredItems = [
    ...items.filter((item) =>
      item.name?.toLowerCase().includes(name.toLowerCase())
    ),
  ];
  return filteredItems;
};
const filteredDraftItems = computed(() =>
  searchEstateByName(draftInput.value, draftItems.value)
);
const filteredPublishedItems = computed(() =>
  searchEstateByName(publishedInput.value, publishedItems.value)
);
const filteredNewItems = computed(() =>
  searchEstateByName(newInput.value, newItems.value)
);
const filteredModeratingItems = computed(() =>
  searchEstateByName(moderatingInput.value, moderatingItems.value)
);
const filteredSoldOutItems = computed(() =>
  searchEstateByName(soldOutInput.value, soldOutItems.value)
);
const filteredPreviewItems = computed(() =>
  searchEstateByName(previewInput.value, previewItems.value)
);

const openAdminLogger = (unitId: string) => {
  window.open(window.location.origin + `/admin/logger?id=${unitId}`, "_blank");
};

const handleTabsChange = async (tab: string) => {
  if (tab === "estate") {
    filterParam.value = "Estate";
  } else if (tab === "land") {
    filterParam.value = "Land";
  }
};
</script>

<style scoped lang="scss">
.add-item {
  display: block;
  margin: auto;
  margin-bottom: 10px;
  width: 100%;
  max-width: 1440px;

  .base-button {
    width: 100%;
  }
}

.tables {
  display: grid;
  align-items: flex-start;
  gap: 20px;
  overflow: hidden;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  padding-left: 20px;
  padding-right: 20px;
  .dragging-over {
    border: 1px dashed blue;
  }
}
.editor-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #e4e4e7;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 0 10px #00000014;
  max-width: 1440px;
  margin: auto;
}

.header {
  display: flex;
  gap: 20px;
  padding: 20px;
  &-side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    span {
      display: flex;
      gap: 10px;
    }
  }
}

.title {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
}

.add-item-btn {
  width: fit-content;
}

.item-list {
  padding: 0;
  display: flex;
  flex-direction: column;
  padding: 3px !important;
  float: left;
  list-style-type: none;
  overflow-y: auto;
  min-height: 100%;
  .preview-clickable {
    cursor: pointer;
    transition: background-color 0.4s ease;
    will-change: background-color;
    &:hover {
      background-color: var(--lilac-secondary);
    }
  }
}

.item-item {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px;
  border-top: 1px solid #e4e4e7;
  background: #f3f7fe;
  .priview-butt {
    border: none;
    outline: none;
    background: transparent;
    color: red;
    cursor: pointer;
    transition: scale 0.3 easy;
    &:hover {
      scale: (1.1);
    }
  }
}

.item-name {
  flex-grow: 1;
  font-size: 12px;
}

/* drop target state */
ul[aria-dropeffect="move"] {
  border-color: #68b;
  background: #fff;
}

/* drop target focus and dragover state */
ul[aria-dropeffect="move"]:focus,
ul[aria-dropeffect="move"].dragover {
  outline: none;
  box-shadow:
    0 0 0 1px #fff,
    0 0 0 3px #68b;
}

/* draggable items */
li {
  display: block;
  list-style-type: none;
  margin: 0 0 2px 0;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  line-height: 1.3;
}

li:hover {
  box-shadow:
    0 0 0 2px #68b,
    inset 0 0 0 1px #ddd;
}

/* items focus state */
li:focus {
  outline: none;
  box-shadow:
    0 0 0 2px #68b,
    inset 0 0 0 1px #ddd;
}

/* items grabbed state */
li[aria-grabbed="true"] {
  background: #5cc1a6;
  color: #fff;
}
</style>
