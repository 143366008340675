import { countryNames } from "~/utilities/constants/countryCodes";
import { Api } from "~/services/swagger/Api";
import type {
  EstateStatisticFilterResponseDTO,
  LandStatisticFilterResponseDTO,
  UnitStatisticsDTO,
} from "~/services/swagger/Api";
import { apiPost } from "~/services/api";
import getRoute from "~/utilities/configs/apiMap";
import { cleanQuery } from "~/utilities/helpers/query/cleanQuery";
import { cloneDeep } from "lodash";
import { Notification } from "~/services/notifications/toast";
const nuxtApp = useNuxtApp();

const transformObject = (obj: any) => {
  const code = Object.keys(obj);
  const result = code.map((key) => ({
    text: obj[key],
    actionType: "countryId",
    objAmount: 0,
    key: key,
    displayOnFirstLoad: false,
  }));
  const sortedResult = result?.toSorted((a, b) => a.text.localeCompare(b.text));

  return sortedResult;
};

const contryArr = ref(transformObject(countryNames));

export interface IFilters {
  text: string;
  isExpandable: boolean;
  type: string;
  data: IFiltersItem[] | string[];
  placeholder1?: string;
  placeholder2?: string;
  after?: string;
  key: string;
  values: any[];
  gte?: number;
  lte?: number;
  noArrow?: boolean;
}

export interface IFiltersItem {
  text: string;
  actionType: string;
  objAmount: number;
  key: string | number;
  checkbox_placeholder?: string;
  displayOnFirstLoad?: boolean;
  noArrow?: boolean;
}

interface IPriseRange {
  range: { [key: number]: number };
  priceMax: number;
  priceMin: number;
}

const apiService = new Api();

export type FetchFiltersParameters = Parameters<
  typeof apiService.api.unitsControllerGetStats
>;

export const filtersStore = defineStore("filtersStore", {
  state: () => ({
    currentStats: undefined as undefined | UnitStatisticsDTO,
    savedStats: getEmptyFilterStats() as UnitStatisticsDTO,
    priseRange: undefined as undefined | IPriseRange,
    isCountryFirstLoad: false,
    kostylLandsCount: 0,
  }),
  actions: {
    async updateEstateFilters(isInitial: boolean, args: any) {
      try {
        const response = await apiPost({
          url: getRoute({ endpont: "get_filter_stats_estate" }),
          body: cleanQuery(args),
        });
        if (!response?.data?.value) return;
        const res = response?.data.value as UnitStatisticsDTO;
        // Object.keys(res).forEach((key) => {
        //   if (typeof res[key] === "number") {
        //     if (!Number.isInteger(res[key])) {
        //       res[key] = parseFloat(res[key].toFixed(1));
        //     }
        //   }
        // });
        this.currentStats = res as UnitStatisticsDTO;
        this.currentStats.rating = transformRatings(
          this.currentStats.rating as RatingObject
        );
        this.priseRange = {
          range: cutStatsKeys(this.currentStats?.token?.prices),
          priceMax: this.currentStats?.token?.priceMax || 1,
          priceMin: this.currentStats?.token?.priceMin || 0,
        };
        //TODO ask backend to change the name of this prop
        if (this.currentStats.country) {
          this.currentStats.countryId = this.currentStats.country;
          const keysArray = Object.keys(this.currentStats.country);
          contryArr.value = contryArr.value.filter((c) =>
            keysArray.includes(c.key)
          );
        }
        //TODO ask backend to change the name of this prop

        // this.updateFiltersByProp(Boolean(isInitial));
        this.isCountryFirstLoad = true;
        if (isInitial) {
          this.savedStats = cloneDeep(this.currentStats);
        }
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        createErrorNotify({ message: errorMessage });
        throw error;
      }
    },
    // async updateLandFilters(isInitial: boolean, args: any) {
    //   try {
    //     const response = await apiPost({
    //       url: getRoute({ endpont: "get_filter_stats_land" }),
    //       body: cleanQuery(args),
    //     });
    //     if (!response?.data.value) return;
    //     response.data.value.total = this.currentStats.total;
    //     this.currentStats = response.data
    //       .value as LandStatisticFilterResponseDTO;
    //     this.priseRange = {
    //       range: cutStatsKeys(this.currentStats.tokenPrices),
    //       priceMax: this.currentStats.tokenPriceMax || 1,
    //       priceMin: this.currentStats.tokenPriceMin || 0,
    //     };
    //     //TODO ask backend to change the name of this prop
    //     if (this.currentStats.country) {
    //       this.currentStats.countryId = this.currentStats.country;
    //       const keysArray = Object.keys(this.currentStats.country);
    //       contryArr.value = contryArr.value.filter((c) =>
    //         keysArray.includes(c.key)
    //       );
    //     }
    //     //TODO ask backend to change the name of this prop

    //     // this.updateFiltersByProp(Boolean(isInitial));
    //     this.isCountryFirstLoad = true;
    //     if (isInitial) {
    //       this.savedStats = cloneDeep(this.currentStats);
    //     }
    //   } catch (error) {
    //     const errorMessage =
    //       (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
    //     createErrorNotify({ message: errorMessage });
    //     throw error;
    //   }
    // },
    // async kostylUpdateLandsCount() {
    //   try {
    //     const response = await apiPost({
    //       url: getRoute({ endpont: "get_filter_stats_land" }),
    //     });
    //     if (!response?.data.value) return;
    //     this.kostylLandsCount = response.data.value.total;
    //   } catch (error) {
    //     const errorMessage =
    //       (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
    //     createErrorNotify({ message: errorMessage });
    //     throw error;
    //   }
    // },
    convertToArray(obj: { [key: string]: any }) {
      Object.keys(obj).forEach((key) => {
        if (
          key.includes("[") &&
          key.includes("]") &&
          !key.includes("irr") &&
          !key.includes("apr")
        ) {
          const baseKey = key.split("[")[0];
          const index = parseInt(key.split("[")[1].split("]")[0], 10);

          if (!obj[baseKey]) {
            obj[baseKey] = [];
          }

          obj[baseKey][index] = obj[key];

          return true; // Indicates that the key has been processed
        }
        return false; // Indicates that the key hasn't been processed
      });
    },

    // updateFiltersByProp(initial: boolean) {
    //   const updateableKeys = ["bedroom", "bathroom", "countryId", "stage"];
    //   // if (exclude) {
    //   //   updateableKeys = updateableKeys.filter((item) => item !== exclude);
    //   // }
    //   this.searchFilters.forEach((filter, index) => {
    //     const filterKey = filter.key;
    //     const statsForKey = this.currentStats[filterKey];
    //     if (!statsForKey || !updateableKeys.includes(filterKey)) return;
    //     if (this.updateCheckboxFilters(statsForKey, index, filterKey, initial))
    //       return;
    //     filter.data.forEach((query) => {
    //       const statsForQuery = statsForKey[query.key];
    //       query.objAmount = statsForQuery || 0;
    //       if (!query.displayOnFirstLoad) {
    //         query.displayOnFirstLoad = Boolean(query.objAmount);
    //       }
    //     });
    //   });
    // },
    // updateCheckboxFilters(
    //   stats: Record<string, any>,
    //   index: number,
    //   type: string,
    //   initial: boolean
    // ) {
    //   if (!initial) return;
    //   const updateableKeys = ["bedroom", "bathroom"];
    //   if (!updateableKeys.includes(type)) return false;
    //   let outerArr: IFiltersItem[] = [];
    //   const restArr: IFiltersItem[] = [];
    //   const translatedType: Record<string, string> = {
    //     bedroom: "fr_bed",
    //     bathroom: "fr_bath",
    //     others: "fr_others",
    //   };
    //   Object.keys(stats).forEach((q) => {
    //     const newObj = {
    //       text: `${translatedType[type]}`,
    //       // text: `${translatedType[type]}${parseInt(q) > 1 ? "s" : ""}`.replace(
    //       //   /^./,
    //       //   type[0].toUpperCase()
    //       // ),
    //       actionType: type,
    //       objAmount: stats[q],
    //       key: parseInt(q),
    //       checkbox_placeholder: q,
    //       displayOnFirstLoad: true,
    //     };
    //     const keyToNum = parseInt(q);
    //     if (isNaN(keyToNum) || keyToNum <= 0) {
    //       newObj.text = "fr_others";
    //       newObj.checkbox_placeholder = "";
    //       newObj.key = 0;
    //       newObj.noArrow = false;
    //     }
    //     outerArr.push(newObj);
    //     const seenOthers = new Set();
    //     outerArr = outerArr.filter((q) => {
    //       if (q.text === "fr_others") {
    //         if (seenOthers.has("fr_others")) return false;
    //         seenOthers.add("fr_others");
    //       }
    //       return true;
    //     });
    //   });
    //   this.searchFilters[index].data = [...restArr.slice(0, 1), ...outerArr];

    //   return true;
    // },
  },
});

const getEmptyFilterStats = () => {
  const stats: UnitStatisticsDTO = {
    total: 0,
    priceMax: 1,
    priceMin: 0,
    prices: {},
    minimumInvestment: 0,
    maximumInvestment: 1,
    token: {
      priceMax: 1,
      priceMin: 0,
      prices: {},
    },
    country: {},
    provider: {},
    hasDocuments: {
      true: 0,
      false: 0,
    },
    tags: {},
    bathroom: {},
    bedroom: {},
    stage: {
      complete: 0,
      in_progress: 0,
    },
    irrMax: 1,
    irrMin: 0,
    aprMax: 1,
    aprMin: 0,
    countryId: {},
  };
  return stats;
};

const cutStatsKeys = (obj: { [key: number]: any }) => {
  const formattedObj: { [key: string]: any } = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const numericKey = Number(key);
      const formattedKey =
        numericKey % 1 !== 0 ? numericKey.toFixed(2) : numericKey.toString();
      formattedObj[formattedKey] = obj[key];
    }
  }

  return formattedObj;
};

const createErrorNotify = (args: { message: string; delay?: number }) => {
  const errorNotify = new Notification({
    type: "error",
    message: args.message,
    timeout: args.delay ?? 5000,
  });
  errorNotify.createNewToast();
};

type RatingObject = Record<string, number>;

const transformRatings = (ratings: RatingObject): RatingObject => {
  if(!ratings) return {}
  const result: RatingObject = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    null: ratings.null || 0,
  };

  Object.entries(ratings).forEach(([key, value]) => {
    const numericKey = parseFloat(key);

    if (isNaN(numericKey)) {
      return;
    }
    if ( numericKey < 1) {
      result["null"] += value;
    } else if (numericKey >= 1 && numericKey <= 1.49) {
      result[1] += value;
    } else if (numericKey >= 1.5 && numericKey <= 2.49) {
      result[2] += value;
    } else if (numericKey >= 2.5 && numericKey <= 3.49) {
      result[3] += value;
    } else if (numericKey >= 3.5 && numericKey <= 4.49) {
      result[4] += value;
    } else if (numericKey > 4.49) {
      result[5] += value;
    }
  });

  return result;
};
