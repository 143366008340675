<template>
  <section class="profile-container">
    <q-skeleton
      v-if="loading"
      width="100%"
      height="112px"
      style="border-radius: 16px"
    />

    <div v-else class="profile-container__user">
      <span class="profile-container__user-info">
        <q-avatar rounded size="64px">
          <img
            :src="displayUser?.avatarFile?.url ?? defaultAvatar"
            alt="avatar"
            style="border-radius: 50%"
          />
        </q-avatar>
        <span class="user-data">
          <p
            v-if="displayUser?.firstName || displayUser?.lastName"
            class="font--b1 mobile-font--b2 text-black-monochrome"
          >
            {{
              (displayUser?.firstName || "") +
              " " +
              (displayUser?.lastName || "")
            }}
          </p>
          <p v-else class="font--b1 mobile-font--b2 text-black-monochrome">
            {{ displayUser?.wallet?.slice(0, 5) }}...{{
              displayUser?.wallet?.slice(-4)
            }}
          </p>
          <p class="font--b2 mobile-font--b2 text-black-monochrome-60">
            {{ displayUser?.email }}
          </p>
        </span>
      </span>
      <span class="user-controls">
        <button
          class="icon-butt hover-rotate"
          @click="debouncedCallSettingsPopUp($device.isMobile)"
        >
          <base-icon name="base/GearSix" size="24px" />
        </button>
      </span>
    </div>
    <span v-if="isAdmin" class="profile-container__admin">
      <base-button
        text="Admin panel"
        size="sg"
        variant="prime"
        :loading="adminButtsLoading"
        @click="openAdminPanel"
      >
        <q-badge floating multi-line align="top"
          >{{ propsToModerateCount }} to moderate</q-badge
        >
      </base-button>
      <base-button
        text="Moderate reviews"
        size="sg"
        variant="dark_ghost"
        :loading="adminButtsLoading"
        @click="openReviewsPanel"
      >
        <q-badge floating align="top"
          >{{ reviewsToModerateCount }} to moderate</q-badge
        >
      </base-button>
      <base-button
        text="Moderate holders"
        size="sg"
        variant="dark_ghost"
        @click="openHoldersPopUp"
      >
      </base-button>
      
    </span>

    <div
      class="profile-container__tabs"
      :style="{
        maxHeight:
          activeTab === 'favorite' && $device.isMobile ? '92px' : '40px',
      }"
    >
      <nav class="tabs-wrapper">
        <div
          class="tab tab__favorite"
          :class="[
            { 'tab--active': activeTab === 'favorite' },
            { 'tab__favorite--active': activeTab === 'favorite' },
          ]"
          @click="handleProfileTabClick('favorite')"
        >
          <h3
            class="header-title font--b2 mobile-font--b2 text-black-monochrome"
          >
            {{ $t("header.h_favorites") }}
          </h3>
          <base-button
            class="tab-control"
            text="Property"
            size="tab"
            variant="prime"
            play-tabs
            :disabled-tab="activeFavoriteTab === 'favoriteLands'"
            @click="activeFavoriteTab = 'favoriteEstates'"
          />
          <base-button
            class="tab-control"
            text="Land"
            size="tab"
            variant="prime"
            play-tabs
            :disabled-tab="activeFavoriteTab === 'favoriteEstates'"
            @click="activeFavoriteTab = 'favoriteLands'"
          />
        </div>
        <base-separator vertical />
        <div
          class="tab tab__portfolio"
          :class="{ 'tab--active': activeTab === 'reviews' }"
          @click="handleProfileTabClick('reviews')"
        >
          <h3
            class="header-title font--b2 mobile-font--b2 text-black-monochrome"
          >
            {{ $t("header.h_reviews") }}
          </h3>
        </div>
        <base-separator vertical />
        <div
          class="tab tab__portfolio"
          :class="{ 'tab--active': activeTab === 'portfolio' }"
          @click="handleProfileTabClick('portfolio')"
        >
          <h3
            class="header-title font--b2 mobile-font--b2 text-black-monochrome"
          >
            {{ $t("header.h_portfolio") }}
          </h3>
        </div>
      </nav>
      <nav
        v-if="activeTab === 'favorite'"
        class="profile-container__tabs--mobile-favorite animate__animated animate__slideInLeft"
      >
        <base-button
          class="tab-control"
          text="Property"
          size="tab"
          variant="prime"
          play-tabs
          :disabled-tab="activeFavoriteTab === 'favoriteLands'"
          @click="activeFavoriteTab = 'favoriteEstates'"
        />
        <base-button
          class="tab-control"
          text="Land"
          size="tab"
          variant="prime"
          play-tabs
          :disabled-tab="activeFavoriteTab === 'favoriteEstates'"
          @click="activeFavoriteTab = 'favoriteLands'"
        />
      </nav>
    </div>

    <profile-favorites
      v-if="activeTab === 'favorite'"
      :active-favorite-tab="activeFavoriteTab"
      :loading="loading"
    />
    <profile-portfolio
      v-if="activeTab === 'portfolio'"
      :-t-e-s-t_-u-n-i-t="adminStore.items"
    />
    <profile-reviews
      v-if="activeTab === 'reviews'"
      :reviews="currentUser?.reviews"
    />
  </section>
</template>

<script setup lang="ts">
import { PopUpServices } from "~/services/PopUp/callPopUp";
import type { UserResponseDTO } from "~/services/swagger/Api";
import defaultAvatar from "~/assets/img/user/defaultAvatar.svg";
import { userStore } from "~/store/user";
import { debounce } from "lodash";
import eventBus from "~/utilities/composables/eventBus";
import { useAdminStore } from "~/store/admin";

definePageMeta({
  middleware: "auth-middleware",
});

type FavoriteTabs = "favoriteEstates" | "favoriteLands";
type Tabs = "favorite" | "portfolio" | "reviews";

const callPopUp = PopUpServices();
const useUserStore = userStore();
const adminStore = useAdminStore();
const $q = useQuasar();
const localePath = useLocalePath();
const route = useRoute();

const currentUser = ref<UserResponseDTO | null>(null);
const displayUser = computed(() => currentUser.value);
const activeFavoriteTab = ref<FavoriteTabs>("favoriteEstates");
const activeTab = ref<Tabs>("favorite");
const isAdmin = computed(
  () =>
    useUserStore.permissions.paths?.includes("admin") &&
    useUserStore.role === "admin"
);
const adminButtsLoading = ref(false);
const propsToModerateCount = ref(0);
const reviewsToModerateCount = ref(0);

if (route.query.tab && typeof route.query.tab === "string") {
  activeTab.value = route.query.tab as Tabs;
}

const callSettingsPopUp = async (mobile: boolean) => {
  if (mobile) {
    callPopUp.callPopUp({
      componentName: "profile-mobile-user-settings-pop-up",
      data: currentUser.value,
      persistent: false,
    });
  } else {
    const update: UserResponseDTO = await callPopUp.callPopUp({
      componentName: "profile-user-settings-pop-up",
      data: currentUser.value,
      persistent: false,
    });
    if (!update) return;
    if (update) {
      currentUser.value = { ...currentUser.value, ...update };
    }
  }
};

const debouncedCallSettingsPopUp = debounce((mobile: boolean) => {
  callSettingsPopUp(mobile);
}, 300);

const loading = ref(false);
const fetchUserData = async () => {
  try {
    loading.value = true;
    const user = await useUserStore.fetchUserData();
    if (!user) return;
    currentUser.value = user;
    // getFavorites();
    gatherAdminData();
  } catch (error) {
    console.log(error);
  } finally {
    setTimeout(() => {
      loading.value = false;
    }, 1000);
  }
};

const openAdminPanel = () => {
  window.open(window.location.origin + "/admin/table", "_blank");
};

const openReviewsPanel = () => {
  window.open(window.location.origin + "/admin/reviews", "_blank");
};

const openHoldersPopUp = () => {
  callPopUp.callPopUp({
    componentName: "item-editor-holders",
    persistent: false,
  });
};

const gatherAdminData = () => {
  if (!isAdmin.value) return;
  fetchAllProps();
};

const fetchAllProps = async () => {
  adminButtsLoading.value = true;
  const [units, reviews] = await Promise.all([
    adminStore.fetchUnits(),
    adminStore.getAllReviews(),
  ]);

  const propsModeratedCount = units?.filter(
    (item) => item.status === "draft"
  )?.length;
  const reviewsModeratedCount = reviews?.filter(
    (item) => item.status === "Moderating"
  )?.length;
  propsToModerateCount.value = propsModeratedCount ?? 0;
  reviewsToModerateCount.value = reviewsModeratedCount ?? 0;
  adminButtsLoading.value = false;
};

const handleProfileTabClick = (val: Tabs) => {
  activeTab.value = val;
};

onMounted(() => {
  fetchUserData();
  eventBus.on("open-user-from-header", callSettingsPopUp);
});
</script>

<style scoped lang="scss">
.profile-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 990px;
  height: auto;
  padding-top: 29px;
  padding-bottom: 40px;
  gap: 32px;
  &__user {
    display: flex;
    width: 100%;
    max-height: 112px;
    padding: 24px;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 16px;
    background: var(--white-contrast);
    box-shadow: var(--shadow-light);
    &-info {
      display: grid;
      align-items: center;
      justify-items: flex-start;
      grid-template-columns: 64px 1fr;
      width: fit-content;
      height: 100%;
      gap: 26px;
      .user-data {
        display: grid;
        gap: 8px;
      }
    }
    .user-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      .icon-butt {
        border: none;
        outline: none;
        background: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
      }
    }
  }
  &__tabs {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .tabs-wrapper {
      display: flex;
      width: 100%;
      height: 40px;
      align-items: flex-end;
      gap: 24px;
      flex-shrink: 0;
      border-bottom: 1px solid var(--gray-monochrome-40);
      .base-separator {
        max-height: 24px;
        margin-bottom: 10px;
      }
      .tab {
        padding-bottom: 5px;
        border-bottom: 2px solid transparent;
        h3 {
          white-space: nowrap;
          font-weight: 400;
          transition: font-weight 0.3s ease;
        }
        transition: border-color 0.3s ease-in-out;
        cursor: pointer;
        &--active {
          border-color: var(--violet-main);
          h3 {
            font-weight: 700;
          }
        }
        &__favorite {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: fit-content;
          overflow: hidden;
          width: 105px;
          transition: width 0.3s ease;
          &--active {
            width: 335px;
          }
          .tab-control {
            width: 90px;
          }
          h3 {
            margin-right: 16px;
          }
        }
        &__portfolio {
        }
      }
    }
    &--mobile-favorite {
      display: none;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
    }
  }

  // &__favorites {
  //   display: grid;
  //   align-items: flex-start;
  //   justify-items: flex-start;
  //   width: 100%;
  //   gap: 16px;
  //   overflow: hidden;

  //   .favorites-grid {
  //     width: 100%;
  //     display: grid;
  //     grid-template-columns: repeat(3, 1fr);
  //     gap: 24px;
  //   }
  //   .favorites-none {
  //     grid-template-columns: 1fr;
  //     text-align: center;
  //     @media (max-width: 65rem) {
  //       .base-button {
  //         width: 100%;
  //       }
  //     }
  //   }

  // }
  &__admin {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    flex-wrap: wrap;
    .q-badge--floating {
      top: -10px;
      right: -10px;
    }
  }
}
.admin-float-butt {
  display: block;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 999999;
}
@media (max-width: 65rem) {
  @include mobile-fonts;
  .wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
  .profile-container {
    max-width: 345px;
    padding-left: 0;
    padding-right: 0;
    gap: 24px;
    &__favorites {
      :deep(.favorites-grid) {
        grid-template-columns: 1fr;
      }
    }
    &__user {
      padding: 16px;
      &-info {
        gap: 12px;
        .user-data {
          gap: 2px;
        }
        .q-avatar {
          font-size: 56px !important;
        }
      }
      .user-controls {
        margin-top: 13px;
        flex-direction: column-reverse;
        align-items: flex-end;
        margin-top: 0;
        gap: 5px;
      }
    }
    &__tabs {
      // max-height: 40px;
      transition: max-height 0.3s ease;
      will-change: max-height;
      .tabs-wrapper {
        gap: 16px;
        .tab {
          &__favorite {
            &--active {
              width: fit-content;
            }
          }
          .tab-control {
            display: none;
          }
        }
      }
      &--mobile-favorite {
        display: flex;
      }
    }
  }
}
</style>
<style>
main {
  min-height: calc(100vh - 66px - 485px);
}
</style>
