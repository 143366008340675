import type { CreateReviewDTO } from "~/services/swagger/Api";

export const getServerStars = (rating: number): CreateReviewDTO["rating"] => {
  switch (rating) {
    case 1:
      return "Star_1";
    case 2:
      return "Star_2";
    case 3:
      return "Star_3";
    case 4:
      return "Star_4";
    case 5:
      return "Star_5";
    default:
      return "Star_1";
  }
};

export const convertServerStars = (rating?: CreateReviewDTO["rating"]) => {
  switch (rating) {
    case "null":
      return 'null';
    case "Star_1":
      return 1;
    case "Star_2":
      return 2;
    case "Star_3":
      return 3;
    case "Star_4":
      return 4;
    case "Star_5":
      return 5;
    default:
      return 0;
  }
};

export const calculateAverage = (ratings: number[]): number => {
  if (!ratings || ratings.length === 0) {
    return 0;
  }

  const total = ratings.reduce((sum, rating) => sum + rating, 0);
  const average = total / ratings.length;

  return parseFloat(average.toFixed(1));
};
