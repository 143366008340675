span
<template>
  <div class="input-range-wrapper">
    <vue-slider
      v-if="props.isSlider && !props.noMin"
      v-model="sliderRange"
      :enableCross="false"
      :silent="true"
      :max="max"
      :min="min"
      :interval="0.1"
      :tooltip="''"
      :dot-size="10"
      :height="4"
      @drag-end="updateRange"
      @change="updateInput"
      @dragging="handleDragging"
    />
    <vue-slider
      v-if="props.noMin"
      v-model="sliderValueNoMin"
      :max="props.sliderMax"
      :tooltip="''"
      :dot-size="10"
      :height="4"
      @drag-end="updateRange"
      @change="updateInput"
      @dragging="handleDragging"
    />
    <div class="input-range">
      <span
        class="custom-input"
        :style="{
          '--filter-after-content': `'${props.after ?? BASE_CURRENCY.symbol}'`,
        }"
      >
        <input
          v-model="inputStart"
          type="text"
          :placeholder="$props.placeholder1"
          :maxlength="props.maxlength || 10"
          @update:model-value="
            () => {
              const newVal = onlyNumbers(inputStart, true);
              $nextTick(() => {
                inputStart = newVal;
              });
            }
          "
          @blur="updateByInput"
        />
        <!-- <input
          v-model="inputStart"
          type="text"
          :style="{ width: getInputW.min + 'px' }"
          :placeholder="$props.placeholder1"
          @input="
            () => {
              const newVal = onlyNumbers(inputStart,true);
              $nextTick(() => {
                inputStart = newVal;
              });
            }
          "
          @change="updateByInput"
        /> -->
      </span>
      <base-separator />
      <span
        v-if="!props.noMin"
        class="custom-input"
        :style="{
          '--filter-after-content': `'${props.after ?? BASE_CURRENCY.symbol}'`,
        }"
      >
        <input
          v-model="inputEnd"
          type="text"
          :placeholder="$props.placeholder2"
          :maxlength="props.maxlength || 10"
          @update:model-value="
            () => {
              const newVal = onlyNumbers(inputEnd, true);
              $nextTick(() => {
                inputEnd = newVal;
              });
            }
          "
          @blur="updateByInput"
        />
      </span>
      <span v-else class="custom-input">
        <input
          v-model="sliderValueNoMin"
          type="text"
          :placeholder="$props.placeholder2"
          @input="
            () => {
              const newVal = onlyNumbers(inputEnd);
              $nextTick(() => {
                inputEnd = newVal;
              });
            }
          "
          @change="updateByInput"
        />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onlyNumbers } from "~/utilities/helpers/format-data/onlyNumbers";
import VueSlider from "vue-3-slider-component";
import { filtersStore } from "~/store/search_filters";
import { isEqual } from "lodash";
import { BASE_CURRENCY } from "~/composables/CURRENCIES";
import eventBus from "~/utilities/composables/eventBus";

interface IProps {
  name: string;
  placeholder1?: string | undefined;
  placeholder2?: string | undefined;
  isSlider?: boolean;
  sliderMax?: number;
  sliderMin?: number;
  noMin?: boolean;
  after?: string;
  maxlength?: number;
  converAble?: boolean;
  dropFunc: () => Function;
}

const emit = defineEmits(["change"]);
const route = useRoute();
const props = defineProps<IProps>();
const isDragging = ref(false);
const useFiltersStore = filtersStore();
const inputStart = ref<number | undefined>(props.sliderMin || 0);
const inputEnd = ref<number | undefined>(props.sliderMax);
const sliderRange = ref<number[]>([0, 1]);
const min = ref(0);
const max = ref(0);
const savedRange = ref<number[]>();

const sliderValueNoMin = ref(props.sliderMax);

const updateInput = () => {
  nextTick(() => {
    inputStart.value = sliderRange.value[0];
    inputEnd.value = sliderRange.value[1];
  });
};

let timeout: ReturnType<typeof setTimeout>;

const updateRange = () => {
  isDragging.value = false;
  if (isEqual(savedRange.value, sliderRange.value)) return;
  timeout = setTimeout(() => {
    if (isDragging.value) {
      clearTimeout(timeout);
      return;
    }
    if (!props.noMin) {
      emit("change", {
        [props.name]: {
          gte: ((inputStart.value || 0) * BASE_CURRENCY.value.usdRate).toFixed(
            1
          ),
          lte: ((inputEnd.value || 0) * BASE_CURRENCY.value.usdRate).toFixed(1),
        },
      });
    } else {
      emit("change", {
        [props.name]: {
          lte: sliderValueNoMin.value,
        },
      });
    }
    savedRange.value = [sliderRange.value[0], sliderRange.value[1]];
  }, 1500);
};

const updateByInput = () => {
  if (inputEnd.value && inputEnd.value > max.value) {
    inputEnd.value = max.value;
  }
  if (!props.noMin) {
    sliderRange.value[0] = inputStart.value || 0;
    sliderRange.value[1] = inputEnd.value || props.sliderMax || 1;
    min.value = inputStart.value || 0;
    max.value = inputEnd.value || props.sliderMax || 1;
  }
  updateRange();
};

const dropInputRange = (nums: [number, number]) => {
  sliderValueNoMin.value = props.sliderMax ?? 100;
  sliderRange.value[0] = nums[0] || 0;
  inputStart.value = nums[0] || 0;
  sliderRange.value[1] = nums[1] || 100;
  inputEnd.value = nums[1] || 100;
  min.value = inputStart.value;
  max.value = inputEnd.value;
};

const handleDragging = (event: any) => {
  isDragging.value = true;
};

const gatherCurrency = () => {
  const baseRate = BASE_CURRENCY.value.rate;

  const convert = (val: number) => {
    val = val ?? 0;
    if (!props.converAble) return val;
    return parseFloat((parseFloat(val.toString()) * baseRate).toFixed(1));
  };
  nextTick(() => {
    let gte =
      route.query[`${props.name}[gte]`] ??
      useFiltersStore.savedStats[`${props.name}Min`] ??
      props.sliderMin;
    gte = convert(gte);
    let lte =
      route.query[`${props.name}[lte]`] ??
      useFiltersStore.savedStats[`${props.name}Max`] ??
      props.sliderMax;
    lte = convert(lte);
    if (!props.noMin) {
      inputStart.value = gte ?? 0;
      inputEnd.value = lte ?? 100;
      const newRange = [gte || 0, lte || 100];
      sliderRange.value = newRange;
      // sliderRange.value[0] = gte ?? 0;
      // sliderRange.value[1] = lte ?? 100;
      const locmin =
        useFiltersStore.savedStats[`${props.name}Min`] ?? props.sliderMin;
      const locmax =
        useFiltersStore.savedStats[`${props.name}Max`] ?? props.sliderMax;
      min.value = convert(locmin);
      max.value = convert(locmax);
      savedRange.value = [sliderRange.value[0], sliderRange.value[1]];
    }
  });
};

onMounted(() => {
  const drop = props.dropFunc();
  drop(dropInputRange);
  nextTick(() => {
    gatherCurrency();
  });
  eventBus.on("change-currency", gatherCurrency);
});
</script>

<style scoped lang="scss">
.input-range-wrapper {
  display: grid;
  gap: 12px;
  width: 100%;
  :deep(.vue-slider-process) {
    background: var(--black-monochrome);
  }
  :deep(.vue-slider) {
    justify-self: center;
    width: 92% !important;
  }
  :deep(.vue-slider-dot-handle) {
    background: var(--black-monochrome);
    border: 1px solid white;
  }
}
.input-range {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .base-separator {
    width: 7px;
  }
  .custom-input {
    display: flex;
    width: 75px;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    gap: 1px;
    position: relative;
    height: 32px;
    border-radius: 6px;
    // padding: 6px 8px;
    padding-right: 6px;
    border: 1px solid var(--Monochrome-Black-60, rgba(18, 18, 18, 0.6));
    background: var(--Monochrome-White, #e9ebf8);
    input {
      height: 100%;
      max-width: 60px;
      min-width: 14px;
      background: none;
      outline: none;
      border: none;
      color: var(--Monochrome-Black-60, rgba(18, 18, 18, 0.6));
      font-family: "DM Sans";
      font-size: 14px;
      font-style: normal;
      padding: 6px 6px;
      font-weight: 400;
      font-variant-numeric: tabular-nums;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &::after {
      content: var(--filter-after-content);
      position: relative;
      top: 0px;
      color: var(--Monochrome-Black-60, rgba(18, 18, 18, 0.6));
      font-family: "DM Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }
  }
}
</style>
