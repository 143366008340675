<template>
  <div class="base-mobile-swiper-gallery">
    <swiper
      :modules="[Navigation, Pagination]"
      :slides-per-view="props.slidesPerView"
      :loop="true"
      :grab-cursor="true"
      v-bind="$attrs"
      :pagination="{ clickable: true }"
      @swiper="onSwiper"
    >
      <template v-if="$slots.default">
        <swiper-slide
          v-for="(child, index) in $slots.default()[0]?.children ?? []"
          :key="index"
        >
          <div
            class="swiper-slide-wrapper"
            @click.stop="navigateToUnitPage(props.goToUnit)"
          >
            <component :is="child" />
          </div>
        </swiper-slide>
      </template>
      <p class="font--b6 text-white-monochrome swiper-active-index">
        {{ activeIndex + 1 }}/{{ numberOfSlides?.value || 0 }}
      </p>
    </swiper>
  </div>
</template>

<script setup lang="ts">
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css/pagination";
import "swiper/css";

//look https://swiperjs.com/swiper-api#parameters for avaliable props

const route = useRoute();
const isUnitPage = computed(() => Boolean(route.params.id));
interface Props {
  slidesPerView?: number | "auto" | undefined;
  goToUnit: string | undefined | null;
}

const props = withDefaults(defineProps<Props>(), {
  slidesPerView: 1,
});

const activeIndex = ref(0);
const numberOfSlides = ref(null);
const localePath = useLocalePath();

const swiperInst = ref<typeof Swiper | null>(null);
const onSwiper = (swiper: any) => {
  swiperInst.value = swiper;
  swiper.on("slideChange", () => {
    activeIndex.value = swiperInst.value.realIndex;
  });
};

numberOfSlides.value = computed(() => {
  if (swiperInst.value) {
    return swiperInst.value.slides.length;
  }
});

const navigateToUnitPage = (id: string | null | undefined) => {
  if (!id || isUnitPage.value) return;
  const route = localePath(`/property/${id}`);
  window.open(window.location.origin + route, "_blank");
};

watch(
  () => props.slidesPerView,
  (newVal) => {
    if (swiperInst.value) {
      swiperInst.value.params.slidesPerView = newVal;
      swiperInst.value.update();
    }
  }
);
</script>

<style scoped lang="scss">
.base-mobile-swiper-gallery {
  position: relative;

  .swiper {
    max-width: 313px;
    height: 271px;
    margin: 0;

    :deep(.swiper-pagination-bullet-active) {
      width: 16px;
      border-radius: 6px;
    }

    &-active-index {
      position: absolute;
      bottom: 35px;
      right: 9px;
      z-index: 3;
      min-width: 32px;
      background-color: var(--black-monochrome-60);
      padding: 8px;
      border-radius: 8px;
    }
  }

  .swiper-slide {
    justify-content: stretch;
    align-items: stretch;

    &-wrapper {
      border-radius: 12px;
      overflow: hidden;
    }

    :deep(img) {
      width: 313px;
      height: 246px;
      object-fit: cover;
    }
  }

  --swiper-pagination-bottom: 0px;
  --swiper-pagination-bullet-size: 6px;
  --swiper-pagination-bullet-inactive-color: var(--gray-monochrome-40);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-vertical-gap: 0px;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  --swiper-pagination-color: var(--black-monochrome-60);
}
</style>
