<template>
  <div class="unit-block-wrapper unit-compare-block">
    <span class="compare-title">
      <p class="font--b4 text-black-monochrome">
        {{ $t("unit_page.un_add_property_to_compare") }}
      </p>
      <p class="font--b5 text-black-monochrome-60">
        {{ $t("unit_page.un_view_all_details") }}
      </p>
    </span>
    <base-button
      class="compare-butt"
      size="sg"
      icon-name="base/Compare"
      icon-size="24px"
      :text="
        isAvaliableToCompare
          ? $t('search_card.fr_compare')
          : $t('search_card.fr_remove')
      "
      :variant="isAvaliableToCompare ? 'dark_ghost' : 'dark'"
      style="width: 100%"
      @click="addDeleteComparison($event, props.propertyInfo.id)"
    />
  </div>
</template>

<script setup lang="ts">
import type { PropertyItem } from "~/types/search-page-items";
import {
  getLocalStorage,
  writePersistentLocalStorage,
} from "~/services/LocalStorage/localStorage";
import { Notification } from "~/services/notifications/toast";
import eventBus from "~/utilities/composables/eventBus";
import type { UpdatedUnitResponseDTO } from "~/store/unitPage";

interface IProps {
  propertyInfo: UpdatedUnitResponseDTO;
  propertyType: "estate" | "land";
}

const props = defineProps<IProps>();
const storageKey = computed(() =>
  props.propertyType === "estate" ? "match_estates" : "match_lands"
);

const isAvaliableToCompare = ref(true);
const localePath = useLocalePath();
const compareNotif = new Notification({
  type: "compare",
});
const addDeleteComparison = (
  event: MouseEvent,
  id: string | null | undefined
) => {
  if (!id) return;

  let propsIds: string[] | null = getLocalStorage(storageKey.value);
  if (!propsIds || (Array.isArray(propsIds) && !propsIds.includes(id))) {
    if (!checkAmountComparison(propsIds)) return;
    propsIds = propsIds ? [...propsIds, id] : [id];
    writePersistentLocalStorage(storageKey.value, propsIds);
    isAvaliableToCompare.value = false;
    const dynamicRoute = localePath(`/comparison`);
    compareNotif.createCompareToast({
      has: propsIds?.length,
      path: dynamicRoute,
    });
  } else if (propsIds) {
    propsIds = propsIds.filter((q) => q !== id);
    writePersistentLocalStorage(storageKey.value, propsIds);
    isAvaliableToCompare.value = true;
  }
  eventBus.emit("compare-counter", propsIds?.length || 0);
};

const checkAmountComparison = (propsIds: string[] | null): boolean => {
  if (!propsIds) return true;
  // eventBus.emit("is-compare-badge", propsIds.length > 0);
  if (Array.isArray(propsIds) && propsIds.length >= 4) {
    const errorNotify = new Notification({
      type: "system",
      message: "You can't have more than 4 estates in comparison.",
    });
    errorNotify.createNewToast();
    return false;
  } else return true;
};

const checkIfInMatching = () => {
  const propsIds: string[] | null = getLocalStorage(storageKey.value);
  if (propsIds && Array.isArray(propsIds) && propsIds.length) {
    isAvaliableToCompare.value = !propsIds.includes(
      props.propertyInfo.id || ""
    );
  }
};

onMounted(() => {
  checkIfInMatching();
});
</script>

<style scoped lang="scss">
.unit-compare-block {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  .compare-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }
  .compare-butt {
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
}
</style>
