import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__ior_2e6i7jxxyj5dugl5j4fxezp46m/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/vercel/path0/main-site/router.options.ts";
const configRouterOptions = {
  scrollBehaviorType: "smooth",
  hashMode: false
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}