<template>
  <span
    class="base-separator"
    :class="[{ 'base-separator--vertical': props.vertical }]"
  />
</template>

<script setup lang="ts">
interface IProps {
  vertical?: boolean;
}
const props = defineProps<IProps>();
</script>

<style scoped lang="scss">
.base-separator {
  width: 100%;
  height: 1px;
  background-color: var(--gray-monochrome-40);
  &--vertical {
    height: 100%;
    width: 1px;
  }
}
</style>
