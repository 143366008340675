<template>
  <div class="portfolio__investments">
    <span class="font--b1 portfolio__investments--span">2 Countries</span>
    <div class="investments">
      <Suspense>
        <q-tabs dense class="custom-hot-tabs" align="justify">
          <q-tab
            name="ID"
            no-caps
            :class="{ 'active-tab': currentCountryTab === 'ID' }"
            @click="handleCountryTabClick('ID')"
          >
            <span class="flag-flex">
              <base-icon
                :name="`flags/indonesia`"
                filled
                :size="!$device.isMobile ? '20px' : '16px'"
              />
              <p
                class="font--b5 mobile-font--b7"
                :class="{ 'font--b4-semi-bold': currentCountryTab === 'ID' }"
              >
                Indonesia
              </p>
            </span>

            <p class="font--b4-semi-bold mobile-font--b7">$678,000</p>
          </q-tab>
        </q-tabs>
      </Suspense>
      <base-separator />
    </div>
  </div>
  <div class="portfolio__investments">
    <p class="font--b1 portfolio__investments--span">2 Partners</p>
    <div class="investments">
      <Suspense>
        <q-tabs dense class="custom-hot-tabs" align="justify">
          <q-tab
            name="ID"
            no-caps
            :class="{ 'active-tab': currentPartnerTab === 'ID' }"
            @click="handlePartnerTabClick('ID')"
          >
            <span class="flag-flex">
              <img src="../../../assets/img/binaryx.svg" alt="" />
            </span>

            <p class="font--b4-semi-bold mobile-font--b7">$678,000</p>
          </q-tab>
        </q-tabs>
      </Suspense>
      <base-separator />
    </div>
  </div>
</template>

<script setup lang="ts">
const currentCountryTab = ref("");

const handleCountryTabClick = (val: string) => {
  currentCountryTab.value = val;
};
const currentPartnerTab = ref("");

const handlePartnerTabClick = (val: string) => {
  currentPartnerTab.value = val;
};
</script>

<style scoped lang="scss">
.portfolio__investments {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  &--span {
    min-width: 110px;
  }
  p {
    white-space: nowrap;
  }
  .investments {
    display: grid;
    align-items: center;
    justify-items: flex-start;
    width: 100%;
    :deep(.custom-hot-tabs) {
      .q-tabs__content {
        gap: 12px;
        flex-wrap: wrap;
        justify-content: center;
      }
      .q-tab__indicator {
        background: transparent;
      }
      .active-tab {
        .q-tab__indicator {
          opacity: 1;
          background: var(--violet-main);
        }
      }

      .q-tab__content {
        gap: 8px;
        padding: 0;
      }
    }
    :deep(.custom-hot-tabs) {
      .q-tab {
        display: flex;
        width: 188px;
        padding: 8px 0px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        border-radius: 16px 16px 0px 0px;
        background: transparent;
        .flag-flex {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          img {
            height: 17px;
          }
        }
      }
      .active-tab {
        background: var(--white-contrast);
      }
    }
  }
}
</style>
