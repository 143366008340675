import payload_plugin_Sl5flMGnms from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.30.0_typescript@5.5.4_vue@3.5.13_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_KUSYUwUPAi from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__ior_2e6i7jxxyj5dugl5j4fxezp46m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Lk7Oj9aPJJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__ior_2e6i7jxxyj5dugl5j4fxezp46m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Q2VzXhuAaB from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__ior_2e6i7jxxyj5dugl5j4fxezp46m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_AbhWayQNCy from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.30.0_vite@5.4.11_@types+node@22.10.5_sass@1.8_oez5qe52isixa7ptcubl6ssw2a/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_P4nwNKVAr0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__ior_2e6i7jxxyj5dugl5j4fxezp46m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_29iTB8m62N from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__ior_2e6i7jxxyj5dugl5j4fxezp46m/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GBgzXZD02g from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__ior_2e6i7jxxyj5dugl5j4fxezp46m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_RB3LMt6XOM from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__ior_2e6i7jxxyj5dugl5j4fxezp46m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/vercel/path0/main-site/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_SX2H4fsDNK from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.30.0_typescript@5.5.4_vue@3.5.13_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/main-site/.nuxt/components.plugin.mjs";
import prefetch_client_I7LHcHvzQ4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__ior_2e6i7jxxyj5dugl5j4fxezp46m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_K3g6y9es29 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.30.0_vue@3.5.13_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_DRHIirTfU8 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.30.0_vue@3.5.13_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import siteConfig_kMDsNtCgzc from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.30.0_vite@5.4.11_@types+node@22.10.5_sass@_ncsmjoefn5pouq6vbgd57lqgca/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_FPXRLYOIkF from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.30.0_vite@5.4.11_@types+node@22.10.5_sass@_ncsmjoefn5pouq6vbgd57lqgca/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_oS1oT0aPIL from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_gXLDZj4gCF from "/vercel/path0/node_modules/.pnpm/nuxt-quasar-ui@2.1.9_@quasar+extras@1.16.15_magicast@0.3.5_quasar@2.17.2_rollup@4.30.0/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import rest_api_zNWx1STPxo from "/vercel/path0/main-site/plugins/rest-api.js";
import gtm_MVzSxF8h1r from "/vercel/path0/main-site/plugins/gtm.js";
import recaptcha_85gNSCNFUU from "/vercel/path0/main-site/plugins/recaptcha.ts";
import gtag_bAM3HbiZET from "/vercel/path0/main-site/plugins/gtag.js";
import error_handler_kEP5FliEXj from "/vercel/path0/main-site/plugins/error-handler.ts";
import image_error_Tix9NvX1QD from "/vercel/path0/main-site/plugins/image-error.ts";
import google_auth_nRC6QNHmRt from "/vercel/path0/main-site/plugins/google_auth.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/main-site/plugins/sentry.ts";
import init_UH01tXfQ8u from "/vercel/path0/node_modules/.pnpm/nuxt-schema-org@3.4.1_@unhead+vue@1.11.14_vue@3.5.13_typescript@5.5.4___magicast@0.3.5_rollup_4o6oa4g7mnggeuka3nyjgnklgy/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/i18n/init.js";
export default [
  payload_plugin_Sl5flMGnms,
  revive_payload_client_KUSYUwUPAi,
  unhead_Lk7Oj9aPJJ,
  router_Q2VzXhuAaB,
  _0_siteConfig_AbhWayQNCy,
  payload_client_P4nwNKVAr0,
  navigation_repaint_client_29iTB8m62N,
  check_outdated_build_client_GBgzXZD02g,
  chunk_reload_client_RB3LMt6XOM,
  plugin_zbacBAXhj0,
  plugin_vue3_SX2H4fsDNK,
  components_plugin_KR1HBZs4kY,
  prefetch_client_I7LHcHvzQ4,
  switch_locale_path_ssr_K3g6y9es29,
  i18n_DRHIirTfU8,
  siteConfig_kMDsNtCgzc,
  inferSeoMetaPlugin_FPXRLYOIkF,
  plugin_oS1oT0aPIL,
  plugin_gXLDZj4gCF,
  rest_api_zNWx1STPxo,
  gtm_MVzSxF8h1r,
  recaptcha_85gNSCNFUU,
  gtag_bAM3HbiZET,
  error_handler_kEP5FliEXj,
  image_error_Tix9NvX1QD,
  google_auth_nRC6QNHmRt,
  sentry_3AyO8nEfhE,
  init_UH01tXfQ8u
]