<template>
  <item-editor-admin-unit is-timeline :tabs="tabs">
    <template #bubbles="{ currentItem }">
      <span class="bubble">
        <base-icon name="base/Bed" color="var(--violet-main)" />
        <p class="font--b2 mobile-font--b4 text-violet-main">Bedroom</p>
        <q-input
          v-model="currentItem.bedroom"
          standout="bg-black text-white"
          type="number"
        />
      </span>
      <span class="bubble">
        <base-icon name="base/Toilet" color="var(--violet-main)" />
        <p class="font--b2 mobile-font--b4 text-violet-main">Bathroom</p>
        <q-input
          v-model="currentItem.bathroom"
          standout="bg-black text-white"
          type="number"
        />
      </span>
      <span class="bubble">
        <base-icon name="base/Cube" color="var(--violet-main)" />
        <p class="font--b2 mobile-font--b4 text-violet-main">Area m2</p>
        <q-input
          v-model="currentItem.area"
          standout="bg-black text-white"
          type="number"
        />
      </span>
    </template>
    <template #finance="{ currentItem }">
      <section v-if="currentItem.finance" class="current__tab-finance">
        <div
          v-if="currentItem.finance.projectTotalIncome?.subfields"
          class="wrapper"
        >
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b2 text-black-monochrome-60">
                Project Total Income
              </p>
            </span>
            <p class="font--b2 text-black-monochrome-60">
              {{ currentItem.finance?.projectTotalIncome.value || 0 }}
            </p>
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">
                Projected Rental Income
              </p>
            </span>
            <q-input
              v-model="
                currentItem.finance.projectTotalIncome.subfields
                  .projectedRentalIncome
              "
              standout="bg-black text-white"
              label="Projected Rental Income"
              type="number"
            />
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">
                Projection Appreciation
              </p>
            </span>
            <q-input
              v-model="
                currentItem.finance.projectTotalIncome.subfields
                  .projectionAppreciation
              "
              standout="bg-black text-white"
              label="Projection Appreciation"
              type="number"
            />
          </div>
        </div>
        <base-separator style="margin-top: 16px; margin-bottom: 16px" />
        <div
          v-if="currentItem.finance.totalInvestmentValue?.subfields"
          class="wrapper"
        >
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b2 text-black-monochrome-60">
                Total Investment Value
              </p>
            </span>
            <p class="font--b2 text-black-monochrome-60">
              {{ currentItem.finance?.totalInvestmentValue?.value || 0 }}
            </p>
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">
                Underlying asset price
              </p>
            </span>
            <q-input
              v-model="
                currentItem.finance.totalInvestmentValue.subfields
                  .underlyingAssetPrice
              "
              standout="bg-black text-white"
              label="Underlying asset price"
              type="number"
            />
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">Legal fees</p>
            </span>
            <q-input
              v-model="
                currentItem.finance.totalInvestmentValue.subfields.legalFees
              "
              standout="bg-black text-white"
              label="Legal fees"
              type="number"
            />
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">Platform fees</p>
            </span>
            <q-input
              v-model="
                currentItem.finance.totalInvestmentValue.subfields.platformFees
              "
              standout="bg-black text-white"
              label="Platform fees"
              type="number"
            />
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">Audit fees</p>
            </span>
            <q-input
              v-model="
                currentItem.finance.totalInvestmentValue.subfields.auditFees
              "
              standout="bg-black text-white"
              label="Audit fees"
              type="number"
            />
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">Registration Tax</p>
            </span>
            <q-input
              v-model="
                currentItem.finance.totalInvestmentValue.subfields
                  .registrationTax
              "
              standout="bg-black text-white"
              label="Audit fees"
              type="number"
            />
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">
                Upfront DAO LLC fees
              </p>
            </span>
            <q-input
              v-model="
                currentItem.finance.totalInvestmentValue.subfields
                  .upfrontDAOLLCFees
              "
              standout="bg-black text-white"
              label="Upfront DAO LLC fees"
              type="number"
            />
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">Property Upgrades</p>
            </span>
            <q-input
              v-model="
                currentItem.finance.totalInvestmentValue.subfields
                  .propertyUpgrades
              "
              standout="bg-black text-white"
              label="Property Upgrades"
              type="number"
            />
          </div>
        </div>
        <base-separator style="margin-top: 16px; margin-bottom: 16px" />
        <div class="wrapper">
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b2 text-black-monochrome-60">Annual Cash Flow</p>
            </span>
            <q-input
              v-model="currentItem.finance.annualCashFlow"
              standout="bg-black text-white"
              label="Annual Cash Flow"
              type="number"
            />
          </div>
        </div>
        <base-separator style="margin-top: 16px; margin-bottom: 16px" />
        <div class="wrapper">
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b2 text-black-monochrome-60">Month Cash Flow</p>
            </span>
            <q-input
              v-model="currentItem.finance.monthCashFlow"
              standout="bg-black text-white"
              label="Month Cash Flow"
              type="number"
            />
          </div>
        </div>
        <base-separator style="margin-top: 16px; margin-bottom: 16px" />
        <div class="wrapper">
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b2 text-black-monochrome-60">
                Annual Gross Income
              </p>
            </span>
            <q-input
              v-model="currentItem.finance.annualGrossIncome"
              standout="bg-black text-white"
              label="Annual Gross Income"
              type="number"
            />
          </div>
        </div>
        <base-separator style="margin-top: 16px; margin-bottom: 16px" />
        <div
          v-if="currentItem.finance.maintainingCosts?.subfields"
          class="wrapper"
        >
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b2 text-black-monochrome-60">Maintaining costs</p>
            </span>
            <p class="font--b2 text-black-monochrome-60">
              {{ currentItem.finance?.maintainingCosts?.value || 0 }}
            </p>
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">
                Property Management
              </p>
            </span>
            <q-input
              v-model="
                currentItem.finance.maintainingCosts.subfields
                  .propertyManagement
              "
              standout="bg-black text-white"
              label="Property Management"
              type="number"
            />
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">
                Maintenance reserve
              </p>
            </span>
            <q-input
              v-model="
                currentItem.finance.maintainingCosts.subfields
                  .maintenanceReserve
              "
              standout="bg-black text-white"
              label="Maintenance reserve"
              type="number"
            />
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">Utilities</p>
            </span>
            <q-input
              v-model="currentItem.finance.maintainingCosts.subfields.utilities"
              standout="bg-black text-white"
              label="Utilities"
              type="number"
            />
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">
                Annual DAO LLC administration and filing fees
              </p>
            </span>
            <q-input
              v-model="
                currentItem.finance.maintainingCosts.subfields
                  .annualDAOLLCAdministrationAndFilingFees
              "
              standout="bg-black text-white"
              label="dministration and filing fees"
              type="number"
            />
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">
                Annual DAO Accounting service
              </p>
            </span>
            <q-input
              v-model="
                currentItem.finance.maintainingCosts.subfields
                  .annualDAOAccountingService
              "
              standout="bg-black text-white"
              label="Annual DAO Accounting service"
              type="number"
            />
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">
                Homeowners Insurance
              </p>
            </span>
            <q-input
              v-model="
                currentItem.finance.maintainingCosts.subfields
                  .homeownersInsurance
              "
              standout="bg-black text-white"
              label="Homeowners Insurance"
              type="number"
            />
          </div>
        </div>
        <base-separator style="margin-top: 16px; margin-bottom: 16px" />
        <div class="wrapper">
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b2 text-black-monochrome-60">Other fees</p>
            </span>
            <q-input
              v-model="currentItem.finance.otherFees"
              standout="bg-black text-white"
              label="Other fees"
              type="number"
            />
          </div>
        </div>
      </section>
    </template>
    <template #searchCard="{ currentItem }">
      <admin-search-card-estate-unit
        :property-info="currentItem"
        is-not-right-butt
      />
    </template>
  </item-editor-admin-unit>
</template>

<script setup lang="ts">
const tabs = [
  "About Property",
  "Finance",
  "Timeline",
  "Token",
  "Issuer",
  "Partners",
  "Legal",
  "Provider",
];
</script>

<style scoped lang="scss">
@import url(~/assets/styles/admin-unit-page/index.scss);
</style>
