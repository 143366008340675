export const userNames = [
  { firstName: "Mysterious", lastName: "Llama" },
  { firstName: "Unknown", lastName: "Penguin" },
  { firstName: "Nameless", lastName: "Fox" },
  { firstName: "Hidden", lastName: "Panda" },
  { firstName: "Silent", lastName: "Tiger" },
  { firstName: "Secret", lastName: "Owl" },
  { firstName: "Mystic", lastName: "Koala" },
  { firstName: "Enigmatic", lastName: "Dolphin" },
  { firstName: "Anonymous", lastName: "Cat" },
  { firstName: "Invisible", lastName: "Wolf" },
  { firstName: "Phantom", lastName: "Bear" },
  { firstName: "Shadow", lastName: "Falcon" },
  { firstName: "Cloaked", lastName: "Otter" },
  { firstName: "Masked", lastName: "Lion" },
  { firstName: "Hidden", lastName: "Elephant" },
  { firstName: "Stealthy", lastName: "Rabbit" },
  { firstName: "Mystery", lastName: "Parrot" },
  { firstName: "Unknown", lastName: "Leopard" },
  { firstName: "Secretive", lastName: "Deer" },
  { firstName: "Veiled", lastName: "Seal" },
  { firstName: "Shrouded", lastName: "Horse" },
  { firstName: "Obscure", lastName: "Zebra" },
  { firstName: "Disguised", lastName: "Crocodile" },
  { firstName: "Unseen", lastName: "Giraffe" },
  { firstName: "Covert", lastName: "Sparrow" },
  { firstName: "Foggy", lastName: "Turtle" },
  { firstName: "Clouded", lastName: "Hedgehog" },
  { firstName: "Invisible", lastName: "Eagle" },
  { firstName: "Silent", lastName: "Kangaroo" },
];
