<template>
  <component :is="currentPropertyType"/>
</template>

<script setup lang="ts">
import { unitPageStore } from "~/store/unitPage";

definePageMeta({
  layout: "default",
  middleware: ["auth-middleware", "unit-page-middleware"],
});

const useUnitpageStore = unitPageStore();

const currentPropertyType = computed(
  () => useUnitpageStore.currentElementInstance || "unit-page-estate-unit"
);
</script>

<style scoped lang="scss"></style>
