export const truncateStringMiddle = (
  str: string | null | undefined,
  maxLength: number = 14,
  frontLen: number = 6,
  backLen: number = 4
) => {
  if (!str) return "";
  if (str && str.length > maxLength) {
    return str.slice(0, frontLen) + "..." + str.slice(str.length - backLen);
  } else {
    return str;
  }
};
