<template>
  <div class="admit-type-select">
    <p class="font--b3">Select property type:</p>
    <div class="property-types">
      <base-button
        text="Estate"
        size="sm"
        variant="prime"
        @click="handleTypeSelect('estate')"
      />
      <base-button
        text="Land"
        size="sm"
        variant="prime"
        @click="handleTypeSelect('land')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropertyType } from "~/types/search-page-items";

const emit = defineEmits(["hide_popup"]);

const handleTypeSelect = (type: PropertyType) => {
  emit("hide_popup", "ok", type);
};
</script>

<style scoped lang="scss">
.admit-type-select {
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 15px;
  background-color: var(--white-monochrome);
  box-shadow: var(--shadow-light);
  .property-types {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }
}
</style>
