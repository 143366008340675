export const updateComparisonSeo = () => {
  const title =
    "Compare Tokenized Properties | RWA Estate - Find Your Investment Opportunity";
  const descriptions =
    "Compare tokenized real estate properties on RWA Estate. Analyze key factors like property price, token price, IRR, APR, growth value, and token availability. Make informed real estate investment decisions with side-by-side comparisons.";

  useSchemaOrg([
    defineWebSite({
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "RWA Estate",
      url: "https://www.rwa-estate.com/comparison",
    }),
  ]);

  useSeoMeta({
    ogTitle: title,
    ogDescription: descriptions,
    keywords: [
      "Compare Tokenized Properties",
      "Real Estate Property Comparison",
      "Tokenized Property Investment",
      "Compare Property Prices",
      "Real Estate Investment Metrics",
      "IRR and APR Comparison",
      "Property Growth Value",
      "Token Availability Comparison",
      "APR,",
    ].join(", "),
    ogImage: "https://www.rwa-estate.com/hero.png",
    ogUrl: "https://www.rwa-estate.com/comparison",
    twitterCard: "summary_large_image",
    twitterTitle: title,
    twitterDescription: descriptions,
    twitterImage: "https://www.rwa-estate.com/hero.png",
  });

  useHead({
    title: title,
    link: [
      {
        rel: "canonical",
        href: `https://www.rwa-estate.com/comparison`,
      },
    ],
  });
};
