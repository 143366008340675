<template>
  <div class="page-content">
    <div v-if="unitData" class="unit-container">
      <section class="unit__left">
        <div v-if="!$device.isMobile" class="wall-wrapper">
          <base-favorite-add
            class="add-favorite-unit"
            :id="unitData?.id"
            :favorite="unitData?.favorite"
            :add-func="UNIT.addToFavorite"
          >
          </base-favorite-add>
          <photo-wall
            :img-array="imageArray"
            :tag="unitData?.tags ? unitData?.tags[0] : ''"
            :issuer="unitData?.token?.issuer"
            :sold="unitData?.status === 'sold_out'"
            :provider-id="unitData?.provider?.id"
          />
        </div>
        <base-search-card-visitors
          v-if="unitData?.metrics.viewsPerDay"
          :day="unitData?.metrics.viewsPerDay"
        />
        <base-page-tabs
          :tabs="tabs"
          :current-tab="mostVisibleId"
          @change-tab="onTabClick"
        />

        <div class="sheet-scroll">
          <div :id="$t('unit_page.un_about_property')" class="current__tab">
            <section
              ref="scrollContainer"
              class="current__tab-dynamic"
              @mousedown.stop="onDragStart"
              @touchstart.stop="onDragStart"
            >
              <slot name="bubbles" />
            </section>
            <div class="current__tab-text">
              <p
                v-if="md.html"
                class="font--b4 mobile-font--b3"
                v-html="md.rendered || UNIT_PAGE_DESCRIPTION_PLACEHOLDER"
              />
              <p
                v-else
                class="font--b4 mobile-font--b3"
                v-html="md.rendered || UNIT_PAGE_DESCRIPTION_PLACEHOLDER"
              />
            </div>
            <div class="current__tab-map">
              <map-static
                v-if="
                  Array.isArray(unitData?.coords) &&
                  unitData?.coords?.length === 2
                "
                is-info
                :coords="unitData?.coords"
                :info-object="{
                  img: mapImg,
                  title: unitData?.name,
                  subtitle: unitData?.location,
                }"
                style="height: 360px"
              />
            </div>
          </div>
          <div class="block-token-chart">
            <token-stock-chart
              :chart-data="useUnitpageStore.unitCharts"
              :loading="tokenChartLoader"
              :total-supply="unitData.tokenomics.totalSupply"
              @change-period="updateStockChart"
            />
          </div>
          <div v-if="Object.keys(unitData?.finance).length" :id="$t('unit_page.un_finance')" class="current__tab">
            <p class="font--b1-2" style="padding-bottom: 16px">Financials</p>
            <slot name="finance" :unitData="unitData" />
          </div>

          <div
            :id="$t('unit_page.un_documents')"
            v-if="filesArray?.length"
            class="current__tab"
          >
            <p class="font--b1-2" style="padding-bottom: 16px">Documents</p>

            <section ref="pdfDocRef" class="current__tab-docs">
              <span
                v-for="doc in filesArray"
                class="doc doc-pdf"
                @click="openDoc(doc.file.url)"
              >
                <span class="doc-title">
                  <suspense>
                    <base-icon
                      :name="switchDocsIcon(doc.file.url)"
                      size="20px"
                      filled
                    />
                  </suspense>
                  <p
                    class="text-black-monochrome"
                    :class="[
                      { 'font--b5-1': !$device.isMobile },
                      { 'font--b5': $device.isMobile },
                    ]"
                  >
                    {{
                      truncateString(
                        doc.file.name,
                        getFileNameLength(doc.file.name)
                      )
                    }}
                  </p>
                </span>
                <span class="doc-controls">
                  <div class="hover-scale-light">
                    <suspense>
                      <base-icon name="base/Link" size="20px" filled />
                    </suspense>
                  </div>
                </span>
              </span>
            </section>
          </div>

          <div
            v-if="
              props.isTimeline &&
              unitData?.timeline &&
              unitData.timeline?.length
            "
            :id="$t('unit_page.un_timeline')"
            class="current__tab"
          >
            <p class="font--b1-2" style="padding-bottom: 16px">
              {{ $t("timeline.title") }}
            </p>

            <base-unit-timeline :timeline-arr="unitData?.timeline" />
          </div>

          <div
            v-if="unitData?.ownershipText"
            :id="$t('unit_page.un_legal')"
            class="current__tab"
          >
            <!-- <p class="font--b1-2" style="padding-bottom: 16px">
              Ownership rights
            </p> -->

            <article class="current__tab-legal">
              <!-- <span
                class="font--h5 mobile-font--b1 text-black-monochrome status-span"
                >Ownership rights:
                <p class="font--h5 mobile-font--b1 text-violet-main">
                  {{ unitData?.ownershipStatus }}
                </p></span
              > -->
              <p
                v-if="unitData?.ownershipText"
                class="font--b3-3 mobile-font--b2 text-black-monochrome"
                style="font-weight: 600"
              >
                Property Ownership in
                {{ unitData?.ownershipText.replaceAll("_", " ") }}
              </p>
              <p
                v-if="unitData?.ownershipText"
                v-html="
                  LegalTemplates[
                    unitData?.ownershipText as keyof typeof LegalTemplates
                  ]
                "
                class="font--b5 mobile-font--b4 text-black-monochrome"
              ></p>
            </article>
          </div>
        </div>
      </section>
      <section class="unit__right">
        <slot name="infoBlock" :unitData="unitData" :imageArray="imageArray" />
        <calculator
          v-if="props.isCalc"
          :token-price="unitData?.token.priceUSD"
          :rental-income="
            unitData?.finance?.projectTotalIncome?.subfields
              ?.projectedRentalIncome
          "
          :appreciation="
            unitData?.finance?.projectTotalIncome?.subfields
              ?.projectionAppreciation
          "
          :available-tokens="unitData.token.availableSupplyPercentage"
          :property-price="unitData.priceUSD"
          :min-invest-amount="unitData.minimumInvestmentUSD"
          :currency="unitData.currency"
        />
        <base-property-info
          :token="unitData?.token"
          :provider="unitData?.provider"
          :partners="unitData?.partners"
          :ref-link="unitData?.referralLink?.link"
          :external-url="unitData?.externalUrl"
          :images="unitData?.files"
        />
        <slot name="compareBlock" />
        <div class="radar">
          <p class="font--b4 radar__title">Property score</p>
          <radar-chart :chart-data="useUnitpageStore.unitCharts" />
        </div>
      </section>
      <base-get-in-touch
        :ref-link="unitData?.referralLink?.link"
        :provider="unitData?.provider"
        :external-url="unitData?.externalUrl"
        :images="unitData?.files"
      />
    </div>
    <div class="bottom-grid">
      <slot name="suggs" :suggsArr="suggArray" />
      <base-separator v-if="unitData" />
      <span v-if="unitData" class="bottom-grid--reviews">
        <base-reviews-leave-review
          :prop-id="unitData?.id"
          :reviews="unitData?.reviews"
          :prop-name="unitData?.name"
          :prop-imgs="unitData?.files"
          :external-url="unitData?.externalUrl"
        />
        <suspense>
          <template #default>
            <baseReviewsBlock :unit-id="unitData.id" />
          </template>
          <template #fallback>
            <q-skeleton width="100%" height="200px"></q-skeleton>
          </template>
        </suspense>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { unitPageStore } from "~/store/unitPage";
import { UNIT_PAGE_DESCRIPTION_PLACEHOLDER } from "~/utilities/constants/TEXTS";
import { truncateString } from "~/utilities/helpers/strings/truncateString";
import { LegalTemplates } from "~/types/admin";
import type { EstateSimilarDTO, FileResponseDTO } from "~/services/swagger/Api";
import { renderTextWithTranslation } from "~/utilities/helpers/strings/renderTextWithTranslation";
import noImage from "~/assets/img/no-image.svg";
import "swiper/css";
import { getThumbnail } from "~/utilities/helpers/thumbnails/getThumbnail";

interface IProps {
  isCalc?: boolean;
  isTimeline?: boolean;
}

const props = defineProps<IProps>();

const baseReviewsBlock = defineAsyncComponent(
  () => import("../../base/reviews/block/index.vue")
);

const useUnitpageStore = unitPageStore();

const UNIT = useUnitpageStore.createUnit();

const { t } = useI18n();

const tokenChartLoader = ref(false);

const gatherTabs = () => {
  if (useUnitpageStore.$state.unitData?.documents?.length) {
    tabs.value.push(t("unit_page.un_documents"));
  }
  if (
    useUnitpageStore.$state.unitData?.timeline &&
    useUnitpageStore.$state.unitData?.timeline.length
  ) {
    tabs.value.push(t("unit_page.un_timeline"));
  }
  if (useUnitpageStore.$state.unitData?.ownershipStatus) {
    tabs.value.push(t("unit_page.un_legal"));
  }
  if (Object.keys(useUnitpageStore.$state.unitData?.finance).length) {
    tabs.value.push(t("unit_page.un_finance"));
  }
};
const visibilityMap = ref<Record<string, number>>({});
const tabsIdArr = [
  t("unit_page.un_about_property"),
  t("unit_page.un_finance"),
  t("unit_page.un_timeline"),
  t("unit_page.un_legal"),
  t("unit_page.un_documents"),
];

const mostVisibleId = computed(() => {
  const maxId = Object.entries(visibilityMap.value).reduce(
    (maxId, [id, percent]) => {
      if (percent > (visibilityMap.value[maxId] || 0)) {
        return id;
      }
      return maxId;
    },
    ""
  );

  const index = tabs.value.findIndex((tab) => tab === maxId);

  return index !== -1 ? index : 0;
});

const initializeObserver = () => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const percentVisible = Math.round(entry.intersectionRatio * 100);
        const id = entry.target.id;
        if (id) {
          visibilityMap.value[id] = percentVisible;
        }
      });
    },
    { threshold: Array.from({ length: 101 }, (_, i) => i / 100) }
  );

  tabsIdArr.forEach((_, index) => {
    const element = document.getElementById(tabsIdArr[index]);
    if (element) observer.observe(element);
  });
  onUnmounted(() => {
    observer.disconnect();
  });
};

const unitData = computed(() => {
  if (useUnitpageStore.$state.unitData) {
    gatherTabs();
  }
  return useUnitpageStore.$state.unitData;
});

const pdfDocRef = ref<HTMLElement | null>(null);
const { isMobile } = useDevice();

const md = computed(() =>
  renderTextWithTranslation(unitData.value?.description)
);

const tabs = ref([t("unit_page.un_about_property")]);

const onTabClick = (tabName: string | undefined) => {
  if (!tabName) return;

  const targetElement = document.getElementById(tabName);
  const isFirst = tabs.value.indexOf(tabName) === 0;
  if (!targetElement) return;
  if (isFirst) {
    window.scrollTo({
      top: !isMobile ? 200 : 1500,
      behavior: "smooth",
    });
  } else {
    const offset = !isMobile ? 100 : 50;
    const elementTop =
      targetElement.getBoundingClientRect().top + window.scrollY;

    window.scrollTo({
      top: elementTop - offset,
      behavior: "smooth",
    });
  }
};

const imageArray = computed(() => {
  if (!unitData.value?.files || !unitData.value?.files.length)
    return [{ url: noImage as string }] as Partial<FileResponseDTO>[];
  const filteredImages = unitData.value.files
    .filter(
      (item) =>
        (item.type == "image" || item.type == "covers") &&
        !item.file.url?.endsWith("pdf")
    )
    .sort((a, b) => {
      if (a.type === "covers" && b.type !== "covers") return 1;
      if (a.type !== "covers" && b.type === "covers") return -1;
      return 0;
    })
    .map((item) => {
      return item.file;
    });

  return filteredImages;
});

const mapImg = computed(() => {
  const img = imageArray.value.find(
    (q) =>
      q.thumbnails?.length &&
      q.thumbnails.find((r) => r.thumbnailType === "thumbnail_130x100")
  );
  return img?.url || imageArray.value?.[0]?.url || noImage;
});

const filesArray = computed(() => {
  const fileLinksArray = [];
  if (unitData.value?.files) {
    const filteredfiles = unitData.value.files.filter(
      (item) => item.type == "document" && item.file.url?.endsWith("pdf")
    );
    fileLinksArray.push(...filteredfiles);
  }
  if (unitData.value?.documents && unitData.value?.documents.length) {
    const mappedDocs = unitData.value?.documents.map((q) => {
      return {
        file: {
          name: q.name,
          url: q.url,
          originUrl: q.url,
        },
      };
    });
    fileLinksArray.push(...mappedDocs);
  }
  return fileLinksArray;
});

const scrollContainer = ref<HTMLElement | null>(null);

const stopPropagation = (event: { stopPropagation: () => void }) => {
  event.stopPropagation();
};

const getFileNameLength = (str: string | null | undefined) => {
  if (!str || !pdfDocRef.value) return 0;
  const element = pdfDocRef.value;
  const { width } = element.getBoundingClientRect();
  const tempSpan = document.createElement("span");
  tempSpan.style.visibility = "hidden";
  tempSpan.style.whiteSpace = "nowrap";
  tempSpan.textContent = "W";
  document.body.appendChild(tempSpan);

  const charWidth = tempSpan.getBoundingClientRect().width;
  document.body.removeChild(tempSpan);
  const maxChars = Math.floor(width / charWidth);
  return maxChars;
};

const onDragStart = () => {
  scrollContainer.value?.addEventListener("mousemove", stopPropagation);
  scrollContainer.value?.addEventListener("mouseup", onDragEnd);
  scrollContainer.value?.addEventListener("mouseleave", onDragEnd);
  scrollContainer.value?.addEventListener("touchmove", stopPropagation);
  scrollContainer.value?.addEventListener("touchend", onDragEnd);
};

const onDragEnd = () => {
  scrollContainer.value?.removeEventListener("mousemove", stopPropagation);
  scrollContainer.value?.removeEventListener("mouseup", onDragEnd);
  scrollContainer.value?.removeEventListener("mouseleave", onDragEnd);
  scrollContainer.value?.removeEventListener("touchmove", stopPropagation);
  scrollContainer.value?.removeEventListener("touchend", onDragEnd);
};

const switchDocsIcon = (name: string | null | undefined) => {
  if (!name) name = "";
  const parts = name.split(".");
  const type = parts[parts.length - 1];
  switch (type) {
    case "pdf":
      return "docs/pdf";
    case "js":
    case "html":
    case "xml":
    case "json":
      return "docs/code";
    case "xlsx":
    case "xls":
    case "csv":
      return "docs/xls";
    case "doc":
    case "docx":
      return "docs/doc";
    default:
      return "base/ClipboardText";
  }
};

const openDoc = (url: string | null | undefined) => {
  if (!url) return;
  window.open(url, "_blank");
};

const suggArray = ref<EstateSimilarDTO[]>([]);

const gatherSimilarEstates = () => {
  useUnitpageStore.$state.unitData?.similar?.forEach((q) => {
    suggArray.value.push(q);
  });
};

const updateStockChart = async (period: "week" | "month" | "year") => {
  tokenChartLoader.value = true;

  const now = new Date();

  let from: string;
  switch (period) {
    case "week":
      from = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7
      ).toISOString();
      break;
    case "month":
      from = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate()
      ).toISOString();
      break;
    case "year":
      from = new Date(
        now.getFullYear() - 1,
        now.getMonth(),
        now.getDate()
      ).toISOString();
      break;
    default:
      from = new Date(
        now.getFullYear() - 1,
        now.getMonth(),
        now.getDate()
      ).toISOString();
      break;
  }

  await useUnitpageStore.getChartsData({
    resolution: "hour",
    period: period,
    unitId: useUnitpageStore.$state.unitData?.id,
    // from: from,
    // to: now.toISOString(),
  });

  tokenChartLoader.value = false;
};

onMounted(() => {
  gatherSimilarEstates();
  initializeObserver();
});

onUnmounted(() => {
  onDragEnd();
});
</script>

<style scoped lang="scss">
@import url(~/assets/styles/unit-page/index.scss);
</style>
