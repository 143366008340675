<template>
  <section class="cookie__popup">
    <div v-if="!isRejected" class="cookie__popup-close">
      <button @click="isRejected = true">
        <Suspense>
          <base-icon name="base/Close" size="24px" />
        </Suspense>
      </button>
    </div>
    <div v-if="!isRejected" class="cookie__popup-title">
      <Suspense>
        <base-icon name="Logo_light" />
      </Suspense>
      <p class="font--b3 text-black-monochrome" style="margin-top: 8px">
        The data provided on this platform is for informational purposes only
        and is not intended to be financial advice. RWA Scan offers real-time
        data and statistics on tokenized real-world assets and security tokens.
      </p>
      <h5 class="font--h5 text-black-monochrome" style="margin-top: 10px">
        We use cookies
      </h5>
      <p class="font--b3 text-black-monochrome">
        By clicking <strong>“Accept All”</strong>, you agree to the storing of
        cookies on your device to enhance site navigation. Cookies help us
        customize the website for you and make your experience on our website
        better. For more details, see our
        <nuxt-link href="/cookies-policy" target="_blank" class="text-black-monochrome">Cookies Policy</nuxt-link>.
      </p>
    </div>

    <div v-if="!isRejected" class="cookie__popup-controls">
      <base-button
        variant="dark_ghost"
        size="lg"
        text="Reject All"
        @click="isRejected = true"
      />
      <base-button
        v-close-popup
        variant="dark"
        size="lg"
        text="Accept All"
        @click="acceptCookie"
      />
    </div>
    <div v-if="isRejected">
      <h5 class="font--h5 text-black-monochrome" style="margin-bottom: 12px">
        We use cookies
      </h5>
      <p class="font--b3 text-black-monochrome">
        Due to unaccepted cookies we cannot display the site.
      </p>
    </div>
  </section>
</template>

<script setup lang="ts">
import { writePersistentLocalStorage } from "~/services/LocalStorage/localStorage";

const isRejected = ref(false);

const acceptCookie = () => {
  writePersistentLocalStorage("cookie_agreement", new Date());
};
</script>

<style scoped lang="scss">
.cookie__popup {
  position: relative;
  display: inline-flex;
  padding: 35px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 24px;
  background: linear-gradient(
      276deg,
      rgba(31, 7, 100, 0) 3.26%,
      rgba(115, 148, 255, 0.16) 98.67%
    ),
    #f3f7fe;
  width: 496px;
  margin-left: 10px;
  margin-right: 10px;
  &-close {
    position: absolute;
    right: 16px;
    top: 16px;
    button {
      border-radius: 50%;
      background: var(--white-monochrome);
      border: none;
      outline: none;
      cursor: pointer;
      margin: 0;
      padding: 4px;
    }
  }
  &-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    .with-margin {
      margin-top: 8px;
    }
    :deep(.base-icon) {
      width: 162px;
      height: 20px;
      .icon {
        width: 162px;
        height: 20px;
        svg {
          width: 162px;
          height: 20px;
        }
      }
    }
  }
  &-controls {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;
    .base-button {
      width: 100%;
    }
  }
}
</style>
