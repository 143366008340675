<template>
  <div
    v-if="props.displayOnFirstLoad || props.isZeroAmountDisplayed"
    class="badge-wrapper expanded-wrapper"
    :class="[`checkbox-${type}`]"
  >
    <div
      class="checkbox-wrapper"
      :class="[
        { 'checkbox-modile': props.isMobile },
        { 'placeholder-checked': props.placeholderChecked },
      ]"
    >
      <input type="checkbox" :checked="isChecked" v-bind="$attrs" />
      <label class="font--b6 text-black-monochrome">{{
        props.checkboxPlaceholder || ""
      }}</label>
      <p :class="{ 'font--b5-3': !props.isMobile, 'font--b5': props.isMobile }">
        {{
          $t(`search_filters.${props.checkboxLabel}`) !==
          `search_filters.${props.checkboxLabel}`
            ? $t(`search_filters.${props.checkboxLabel}`)
            : props.checkboxLabel
        }}
      </p>
    </div>
    <p class="font--b6 text-gray-base" style="margin-right: 3px">
      {{ props.labelAmount }}
    </p>
  </div>
</template>

<script setup lang="ts">
interface Props {
  checkboxLabel?: string | undefined;
  labelAmount?: number | string | undefined;
  checked: boolean;
  isMobile?: boolean;
  checkboxPlaceholder?: string;
  isZeroAmountDisplayed?: boolean;
  displayOnFirstLoad?: boolean;
  placeholderChecked?: boolean;
  type?: "24";
}
const props = defineProps<Props>();

const isChecked = computed(() => props.checked);
</script>

<style scoped lang="scss">
.badge-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkbox-24 {
  .checkbox-wrapper {
    input[type="checkbox"] + label {
      width: 24px;
      height: 24px;
    }
    input[type="checkbox"]:checked + label:after {
      top: 2px;
      left: 9px;
      width: 6px;
      height: 16px;
    }
  }
}
.checkbox-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100px;
  }
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border-radius: 3px;
    border: 2px solid;
    border-color: var(--gray-base);
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-family: "DM Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 13px */
  }

  input[type="checkbox"]:checked + label {
    background-color: var(--black-monochrome);
    border: none;
  }

  input[type="checkbox"]:checked + label:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 5px;
    width: 5px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
.checkbox-modile {
  input[type="checkbox"] + label {
    width: 24px;
    height: 24px;
  }
  input[type="checkbox"]:checked + label:after {
    top: 3px;
    left: 9px;
    width: 8px;
    height: 14px;
  }
}

.placeholder-checked {
  input[type="checkbox"]:checked + label:after {
    display: none;
  }
  input[type="checkbox"]:checked + label {
    color: white;
  }
}
</style>
