<template>
  <section class="admin-logger">
    <div
      v-if="isNoId || !logs || !logs.length || $q.loading.isActive"
      class="admin-logger__no-logs"
    >
      Nothing to show
    </div>
    <div v-else>
      <table class="admin-logger__table">
        <thead>
          <tr>
            <th>Property</th>
            <th>Old Value</th>
            <th>New Value</th>
            <th>Change Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="change in paginatedChanges" :key="change.id">
            <td>{{ change.property }}</td>
            <td>{{ change.oldValue }}</td>
            <td>{{ change.newValue }}</td>
            <td>{{ change.changeDate }}</td>
          </tr>
        </tbody>
      </table>
      <div class="admin-logger__pagination">
        <button @click="prevPage" :disabled="currentPage === 1">
          Previous
        </button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">
          Next
        </button>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { UnitResponseDTO } from "~/services/swagger/Api";
import { useAdminStore } from "~/store/admin";

definePageMeta({
  layout: "admin-edit",
  middleware: "admin-auth-middleware",
});

const adminStore = useAdminStore();
const $q = useQuasar();
const route = useRoute();
const unitId = route.query?.id;
const isNoId = ref(false);
const logs = ref<UnitResponseDTO[] | null>(null);
const itemsPerPage = 10;
const currentPage = ref(1);

onMounted(async () => {
  $q.loading.show();
  if (!unitId) {
    isNoId.value = true;
    $q.loading.hide();
    return;
  }
  logs.value = await adminStore.getLoggerSnapshots(unitId as string);
  $q.loading.hide();
});

const changes = computed(() => {
  if (!logs.value) return [];
  const changesList: Array<{
    id: string;
    property: string;
    oldValue: string | number | null;
    newValue: string | number | null;
    changeDate: string;
  }> = [];

  for (let i = 1; i < logs.value.length; i++) {
    const prev = logs.value[i - 1];
    const curr = logs.value[i];

    for (const key in curr) {
      if (curr[key] !== prev[key]) {
        changesList.push({
          id: `${i}-${key}`,
          property: key,
          oldValue: prev[key],
          newValue: curr[key],
          changeDate: curr.updatedAt,
        });
      }
    }
  }

  return changesList;
});

const totalPages = computed(() =>
  Math.ceil(changes.value.length / itemsPerPage)
);
const paginatedChanges = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage;
  return changes.value.slice(start, start + itemsPerPage);
});

function nextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
}

function prevPage() {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
}
</script>

<style scoped lang="scss">
.admin-logger {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  min-height: 100dvh;
  &__no-logs {
    font-size: 18px;
    color: #888;
  }

  &__table {
    width: 100%;
    max-width: 1200px;
    border-collapse: collapse;
    margin-top: 16px;

    th,
    td {
      padding: 8px 12px;
      text-align: left;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f4f4f4;
    }
  }

  &__pagination {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    button {
      padding: 6px 12px;
      border: 1px solid #ddd;
      background-color: #fff;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}
</style>
