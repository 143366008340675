<template>
  <section class="sign-in" @keydown.enter.prevent="handleEnter">
    <div class="sign-in__header">
      <h5 class="title-underlined font--h4 text-black-monochrome">
        {{ $t("login.log_forgot_password") }}
      </h5>
      <button v-close-popup class="close-butt hover-rotate">
        <Suspense>
          <base-icon name="base/Close" size="100%" />
        </Suspense>
      </button>
    </div>
    <div>
      <p v-if="isMailTokenRecived" class="font--b2 text-black-monochrome">
        {{
          $t("login.log_recovery_email_message", { email: userData.savedEmail })
        }}
      </p>
      <p class="font--b2 text-black-monochrome">
        {{
          !isMailTokenRecived
            ? $t("login.log_fill_email_for_recovery")
            : $t("login.log_fill_recovery_code")
        }}
      </p>
    </div>
    <q-form ref="formRef" class="sign-in__body">
      <span class="gridbox">
        <label class="font--b3-m text-black-monochrome"
          >{{
            !isMailTokenRecived
              ? $t("login.log_email")
              : $t("login.log_recovery_code")
          }}<sup>*</sup></label
        >
        <q-input
          v-model="userData.email"
          outlined
          :type="!isMailTokenRecived ? 'email' : 'text'"
          lazy-rules
          :rules="[
            (val) => !!val || $t('errors.login.empty'),
            (val: string) =>
              isMailTokenRecived ||
              /.+@.+\..+/.test(val) ||
              `${!isMailTokenRecived ? $t('errors.login.email') : $t('errors.login.or')}`,
          ]"
        >
          <template v-if="isUserTurnedBack && isMailTokenRecived" #append>
            <Suspense>
              <base-icon
                name="base/ClipboardText"
                class="hover-scale-light"
                @click="checkUserClipboard"
              />
            </Suspense>
          </template>
        </q-input>
      </span>

      <span
        v-if="isMailTokenRecived"
        class="gridbox"
        :style="{ marginBottom: passwordRef?.hasError ? '35px' : 0 }"
      >
        <span class="font--b3-m text-black-monochrome flexbox"
          >{{ $t("login.log_create_new_password") }}<sup>*</sup>
        </span>
        <q-input
          ref="passwordRef"
          v-model="userData.password"
          outlined
          lazy-rules
          :class="{ 'custom-error-input': passwordRef?.hasError }"
          :type="!showPassword ? 'password' : 'text'"
          :rules="[(val) => passwordValidation(val)]"
        >
          <template #append>
            <Suspense>
              <base-icon
                :name="`base/${showPassword ? 'Eye' : 'EyeClosed'}`"
                @click="showPassword = !showPassword"
              />
            </Suspense> </template
        ></q-input>
      </span>
      <span v-if="isMailTokenRecived" class="gridbox">
        <label class="font--b3-m text-black-monochrome"
          >{{ $t("login.log_repeat_new_password") }}<sup>*</sup></label
        >
        <q-input
          v-model="userData.repeatPassword"
          outlined
          :type="!showPassword ? 'password' : 'text'"
          lazy-rules
          :rules="[
            (val) => val === userData.password || $t('errors.login.passwords'),
          ]"
        >
          <template #append>
            <Suspense>
              <base-icon
                :name="`base/${showPassword ? 'Eye' : 'EyeClosed'}`"
                @click="showPassword = !showPassword"
              />
            </Suspense>
          </template>
        </q-input>
      </span>
    </q-form>
    <div class="sign-in__controls">
      <base-button
        class="sign-butt"
        :text="
          !isMailTokenRecived ? $t('login.log_cont') : $t('login.log_conf')
        "
        size="lg"
        variant="dark"
        type="submit"
        :disabled="isMailTokenRecived && !userData.email"
        @click="handleSignClick"
      />
      <span class="has-account font--b2 text-black-monochrome">
        {{
          !isMailTokenRecived
            ? $t("login.log_no_account")
            : $t("login.log_didnt_receive_code")
        }}
        <button v-if="!isCounter" @click="handleMultyButt(isMailTokenRecived)">
          {{
            !isMailTokenRecived ? $t("login.log_up") : $t("login.log_send_ag")
          }}
        </button>
        <p v-else class="font--b2 text-black-monochrome">
          {{ $t("login.log_send_again_in", { time: 30 - sentCounter }) }}
        </p>
      </span>
    </div>
  </section>
</template>

<script setup lang="ts">
import { userStore } from "~/store/user";
import { PopUpServices } from "~/services/PopUp/callPopUp";

const emit = defineEmits(["hide_popup"]);
const $q = useQuasar();

const useUserStore = userStore();
const usePopUpServices = PopUpServices();
const isMailTokenRecived = ref(false);
const showPassword = ref(false);
const formRef = ref<HTMLElement | null>(null);
const sentCounter = ref(0);
const isCounter = ref(false);
const isUserTurnedBack = ref(false);
let intervalId: NodeJS.Timeout;
const passwordRef = ref<HTMLElement | null>(null);

const userData = reactive({
  email: "",
  savedEmail: "",
  password: "",
  repeatPassword: "",
  userName: "",
});

const handleSignClick = () => {
  formRef.value?.submit();
  formRef.value?.validate().then((success) => {
    if (success) {
      if (!isMailTokenRecived.value) {
        handleSentEmail(userData.email);
      } else {
        handleSignIn();
      }
    } else {
      return;
    }
  });
};
const handleSentEmail = async (email: string) => {
  if (import.meta.client) {
    await useUserStore.restoreUserAccount({
      email: email,
    });
    isMailTokenRecived.value = true;
    userData.savedEmail = userData.savedEmail
      ? userData.savedEmail
      : userData.email;
    userData.email = "";
    handleMultyButt();
  }
};

const handleSignIn = async () => {
  $q.loading.show();
  const res = await useUserStore.restoreUserAccountConfirm({
    email: userData.savedEmail,
    password: userData.password,
    token: userData.email,
  });
  if (res) {
    $q.loading.hide();
    emit("hide_popup", "ca");
  } else {
    $q.loading.hide();
  }
};

const handleMultyButt = async (isManual?: boolean) => {
  if (isManual) {
    await handleSentEmail(userData.savedEmail);
  }

  if (isMailTokenRecived.value) {
    if (intervalId) {
      clearInterval(intervalId);
    }
    isCounter.value = true;
    sentCounter.value = 0;
    intervalId = setInterval(() => {
      if (sentCounter.value < 30) {
        sentCounter.value += 1;
      } else {
        clearInterval(intervalId);
        isCounter.value = false;
      }
    }, 1000);
  } else {
    emit("hide_popup", "ca");
    nextTick(() => {
      usePopUpServices.callPopUp({
        componentName: "login-login-pop-up",
        persistent: false,
        data: "signup",
      });
    });
  }
};

const handleEnter = () => {
  if (isMailTokenRecived.value && !userData.email) return;
  handleSignClick();
};

const { t } = useI18n();

const passwordValidation = (value: string) => {
  const regex =
    /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9a-zA-Z?!@#$%^&*`'"(){}+,-\~_.\/|J:;<>= ])[0-9a-zA-Z?!@#$%^&*`'"(){}+,-\~_.\/|J:;<>= ]{8,}/g;
  if (!regex.test(value)) {
    return t("login.log_password_rules");
  }
  return true;
};

const checkUserClipboard = async () => {
  try {
    const clipboardText = await navigator.clipboard.readText();
    if (typeof clipboardText === "string" && clipboardText.length === 12) {
      userData.email = clipboardText;
    }
  } catch (err) {
    console.error(err);
  }
};

onMounted(() => {
  watch(
    () => $q.appVisible,
    (val) => {
      if (val && isMailTokenRecived.value) {
        isUserTurnedBack.value = true;
      }
    }
  );
});
</script>

<style scoped lang="scss">
.sign-in {
  position: relative;
  display: inline-flex;
  min-width: 492px;
  max-width: 492px;
  height: fit-content;
  padding: 24px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 24px;
  border: 1px solid var(--gray-40);
  background: var(--white-contrast);
  max-height: 90dvh;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-40);
    border-radius: 6px;
    border: 2px solid transparent;
  }

  @supports not selector(::-webkit-scrollbar) {
    * {
      scrollbar-color: var(--violet-secondary-light)
        var(--violet-secondary-light);
    }
  }
  @media (max-width: 65rem) {
    min-width: unset;
    width: 90vw;
    padding: 24px;
  }
  &__header {
    .title-underlined {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 4px;
        bottom: -1px;
        left: 0;
        background-color: var(--violet-main);
      }
    }
    .close-butt {
      position: absolute;
      right: 16px;
      top: 16px;
      width: 28px;
      height: 28px;
      margin: 0;
      outline: none;
      border: none;
      border-radius: 50%;
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      background: var(--Monochrome-White, #e9ebf8);
      cursor: pointer;
    }
  }

  &__body {
    width: 100%;
    display: grid;
    gap: 5px;
    :deep(.gridbox) {
      .q-field--outlined .q-field__control {
        border-radius: 16px;
        max-height: 48px;
        &:after {
          max-height: 48px;
        }
      }
      .q-field__marginal {
        max-height: 48px;
      }
      .q-field__native {
        color: var(--Monochrome-Black, #121212);
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        max-height: 48px;
      }
      .q-field__control-container {
        max-height: 48px;
      }
      .q-field--error .q-field__bottom {
        color: var(--Color-Secondary-Negative, #ef4444);
        padding-left: 0px;
        font-family: "DM Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        padding: 3px 12px 0px;
      }
    }

    .gridbox {
      width: 100%;
      display: grid;
      gap: 8px;
    }
    .flexbox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    sup {
      font-family: "Cabinet Grotesk";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: var(--negative-sec);
      top: -0.2em;
    }
  }
  &__controls {
    width: 100%;
    display: grid;
    align-items: center;
    gap: 12px;
    margin-top: -20px;
    justify-items: center;
    .sign-butt.base-button {
      width: 100%;
    }

    .has-account {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      button {
        color: var(--Color-Main_Violet, #5a2fcf);
        outline: none;
        text-decoration: underline;
        margin: 0;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
      }
    }
  }
}
@media (max-width: 65rem) {
  @include mobile-fonts;
}
</style>
