<template>
  <div class="third-party-login" />
</template>

<script setup lang="ts">
import { userStore } from "~/store/user";

const $q = useQuasar();

const useUserStore = userStore();
const route = useRoute();
const code = route.query.code;
const localePath = useLocalePath();

onMounted(async () => {
  if (typeof code === "string") {
    $q.loading.show();
    useUserStore.singInWithGoogle(code);
  } else {
    navigateTo(localePath("/"));
  }
});
onBeforeUnmount(() => {
  $q.loading.hide();
});
</script>

<style scoped lang="scss">
.third-party-login {
  width: 100dvw;
  height: 100dvh;
}
</style>
