<template>
  <div class="get-in-touch">
    <div class="info">
      <p class="font--b1-2 text-black-monochrome">
        {{ $t("search_card.fr_ready") }}
      </p>
    </div>
    <base-button
      variant="prime"
      :text="$t('search_card.fr_contact')"
      size="sg"
      icon-name="base/Right"
      icon-position="right"
      @click="navigateToIssuer"
    />
  </div>
</template>

<script setup lang="ts">
import { redirectByProviderName } from "~/utilities/helpers/strings/redirectByProviderName";
import type {
  ProviderDTO, UnitFileDTO
} from "~/services/swagger/Api";


interface Props {
  refLink?: string;
  provider?: ProviderDTO;
  externalUrl?: string;
  images?: UnitFileDTO[];
}

const props = defineProps<Props>();

const navigateToIssuer = () => {
  redirectByProviderName({providerName: props.provider?.name, providerLink: props.provider?.url, utmLink: props.refLink, externalUrl: props.externalUrl,source:'unit__open_ref__ready_to_invest', images: props.images, logoFile: props.provider?.logoFile?.url})
};

// const openTG = () => {
//   window.open("https://t.me/rwasupport", "_blank")
// }
</script>

<style scoped lang="scss">
.get-in-touch {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  min-height: 180px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  align-self: flex-end;
  background-image: url("~/assets/img/linear.svg");
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: none;
  .info {
    p {
      text-transform: uppercase;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 35px;
    }
  }
}

// @media (max-width: 65rem) {
//   @include mobile-fonts;
// }
</style>
