<template>
 <main-f-a-q />
</template>

<script setup lang="ts">
import { updateFaqSeo } from "~/services/SEO/updateFaqPageSeo";

definePageMeta({
  layout: "default",
});
updateFaqSeo();
</script>

<style scoped lang="scss"></style>
