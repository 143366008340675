<template>
  <div class="portfolio-tokens-chart">
    <div class="title">
      <div class="chart-container">
        <Doughnut :data="chartData" :options="chartOptions" />
      </div>
      <span class="title-data">
        <p class="font--b1-2">{{ totalTokens.toLocaleString() }}</p>
        <p class="font--b3">Total No. of tokens</p>
      </span>
    </div>
    <div class="wrapper">
      <div class="chart-legend">
        <div
          v-for="(label, index) in chartData.labels"
          :key="index"
          class="legend-item"
        >
          <span
            class="legend-color"
            :style="{
              backgroundColor: chartData.datasets[0].backgroundColor[index],
            }"
          ></span>
          <span class="legend-label font--b5-3">{{ label }}</span>
          <span class="legend-value font--b4-semi-bold">{{
            chartData.datasets[0].data[index].toLocaleString()
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "vue-chartjs";
import { withDefaults, defineProps } from "vue";

ChartJS.register(Title, Tooltip, Legend, ArcElement);

interface ChartProps {
  labels: string[];
  data: number[];
  backgroundColor: string[];
  hoverBackgroundColor: string[];
  totalTokens: number;
}

const props = withDefaults(defineProps<ChartProps>(), {
  labels: [
    "Sobha Heartland II Villas",
    "Sobha Heartland II Villas",
    "Sobha Heartland II Villas",
    "Sobha Heartland II Villas",
  ],
  data: [45678, 67890, 32345, 67333],
  backgroundColor: [
    "rgba(103, 66, 208, 1)",
    "rgba(159, 122, 234, 1)",
    "rgba(196, 166, 249, 1)",
    "rgba(224, 204, 252, 1)",
  ],
  hoverBackgroundColor: [
    "rgba(103, 66, 208, 0.8)",
    "rgba(159, 122, 234, 0.8)",
    "rgba(196, 166, 249, 0.8)",
    "rgba(224, 204, 252, 0.8)",
  ],
  borderColor: "#ECECEC",
  borderWidth: 1,
  totalTokens: 50000,
});

// Генерируем данные для графика на основе пропсов
const chartData = ref({
  labels: props.labels,
  datasets: [
    {
      data: props.data,
      backgroundColor: props.backgroundColor,
      hoverBackgroundColor: props.hoverBackgroundColor,
      borderColor: "#ECECEC",
      borderWidth: 1,
      cutout: "50%",
    },
  ],
});

const chartOptions = ref({
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
});
</script>

<style lang="scss">
.portfolio-tokens-chart {
  padding: 24px;
  border-radius: 16px;
  background: var(--white-contrast);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  height: 312px;
  max-width: 314px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  gap: 16px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &-data {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;
    }
  }
  .wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 40px;

    .chart-legend {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
      max-width: 240px;
      .legend {
        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
        }
        &-color {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 4px;
        }
        &-label {
          flex: 1;
        }
        &-value {
          font-weight: bold;
        }
      }
    }
  }
  .chart-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 88px;
    height: 88px;
  }
}
@media (max-width: 65rem) {
  @include mobile-fonts;
  .portfolio-tokens-chart {
    max-width: 345px;
    height: fit-content;
    .wrapper {
      justify-content: flex-start;
      .chart-legend {
        max-width: 100%;
        height: fit-content;
      }
    }
    .chart-container {
    }
  }
}
</style>
