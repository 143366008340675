import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__ior_2e6i7jxxyj5dugl5j4fxezp46m/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__ior_2e6i7jxxyj5dugl5j4fxezp46m/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-auth-middleware": () => import("/vercel/path0/main-site/middleware/admin-auth-middleware.ts"),
  "auth-middleware": () => import("/vercel/path0/main-site/middleware/auth-middleware.ts"),
  "unit-page-middleware": () => import("/vercel/path0/main-site/middleware/unit-page-middleware.ts")
}