import { Api } from "~/services/swagger/Api";

const apiClient = new Api();

export const countryNames: { [key: string]: string } = {
  US: "US",
  DE: "Germany",
  FR: "France",
  GB: "UK",
  CA: "Canada",
  AU: "Australia",
  NZ: "New Zealand",
  JP: "Japan",
  CN: "China",
  RU: "Russia",
  IN: "India",
  BR: "Brazil",
  ZA: "South Africa",
  NG: "Nigeria",
  EG: "Egypt",
  IT: "Italy",
  ES: "Spain",
  PT: "Portugal",
  NL: "Netherlands",
  BE: "Belgium",
  DK: "Denmark",
  SE: "Sweden",
  NO: "Norway",
  FI: "Finland",
  PL: "Poland",
  GR: "Greece",
  CZ: "Czech Republic",
  RO: "Romania",
  HU: "Hungary",
  IE: "Ireland",
  MX: "Mexico",
  AR: "Argentina",
  CL: "Chile",
  CO: "Colombia",
  PE: "Peru",
  VE: "Venezuela",
  MY: "Malaysia",
  SG: "Singapore",
  TH: "Thailand",
  VN: "Vietnam",
  PH: "Philippines",
  KR: "South Korea",
  SA: "Saudi Arabia",
  AE: "Arab Emirates",
  IL: "Israel",
  TR: "Turkey",
  UA: "Ukraine",
  KZ: "Kazakhstan",
  ID: "Indonesia",
  AT: "Austria",
  CH: "Switzerland",
  LU: "Luxembourg",
  SM: "San Marino",
  MT: "Malta",
  IS: "Iceland",
  SK: "Slovakia",
  SI: "Slovenia",
  HR: "Croatia",
  BA: "Bosnia & Herzegovina",
  RS: "Serbia",
  ME: "Montenegro",
  MK: "North Macedonia",
  LV: "Latvia",
  LT: "Lithuania",
  EE: "Estonia",
  BY: "Belarus",
  MD: "Moldova",
  BG: "Bulgaria",
  AL: "Albania",
  CY: "Cyprus",
  GE: "Georgia",
  AM: "Armenia",
  AZ: "Azerbaijan",
  KE: "Kenya",
  TZ: "Tanzania",
  UG: "Uganda",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  GH: "Ghana",
  CD: "Congo",
  MA: "Morocco",
  DZ: "Algeria",
  TN: "Tunisia",
  LY: "Libya",
  JO: "Jordan",
  OM: "Oman",
  KW: "Kuwait",
  QA: "Qatar",
  BH: "Bahrain",
  LK: "Sri Lanka",
  BD: "Bangladesh",
  PK: "Pakistan",
  AF: "Afghanistan",
  NP: "Nepal",
  MM: "Myanmar",
  KH: "Cambodia",
  LA: "Laos",
  MN: "Mongolia",
  IR: "Iran",
  SY: "Syria",
  IQ: "Iraq",
  LB: "Lebanon",
  YE: "Yemen",
};

const fetchCountries = async () => {
  try {
    const response = await apiClient.api.countriesControllerList();
    if (!response.data) return;
    response.data.forEach((item) => {
      countryNames[item.code] = item.name;
    });
  } catch (error) {
    throw error;
  }
};

fetchCountries();
