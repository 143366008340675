<template>
  <div>
    <component :is="currentInstance" />
  </div>
</template>

<script setup lang="ts">
const route = useRoute();

const currentInstance = computed(() => {
  switch (route.query.type ?? route.params.id) {
    case "estate":
      return "item-editor-admin-unit-estate";
    case "land":
      return "item-editor-admin-unit-land";
    default:
      return "item-editor-admin-unit-estate";
  }
});
</script>

<style scoped lang="scss"></style>
