<template>
  <div class="external">
    Request
    <div class="external__icon">
      <base-icon name="base/External" size="9px" />
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style>
.external {
  display: flex;
  text-decoration: underline;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
}
.external__icon {
  padding-left: 8px;
}
</style>
