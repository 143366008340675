<template>
  <div class="profile-container__favorites">
    <div
      class="favorites-grid"
      v-if="
        useUserStore?.[props.activeFavoriteTab as keyof typeof useUserStore]
          ?.length && !props.loading
      "
    >
      <base-search-card-new
        v-for="item in useUserStore?.[
          props.activeFavoriteTab as keyof typeof useUserStore
        ]"
        :estate="item"
        size="suggestions"
        :property-type="
          props.activeFavoriteTab === 'favoriteEstates' ? 'estates' : 'lands'
        "
      />
    </div>
    <div class="favorites-grid" v-else-if="props.loading">
      <q-skeleton
        v-for="i in 3"
        width="100%"
        height="445px"
        style="border-radius: 16px; max-width: 314px"
      />
    </div>
    <div class="favorites-grid favorites-none" v-else>
      <search-page-no-objects>
        <template #default>
          <h3 class="font--h4-2 mobile-font--h4 text-black-monochrome">
            {{ $t("profile.p_no_favorite.no_props") }}
          </h3>
          <p class="font--b2 mobile-font--b2 text-black-monochrome">
            {{ $t("profile.p_no_favorite.browse") }}
          </p>
          <base-button
            :text="$t('profile.p_no_favorite.directory')"
            variant="dark"
            size="sg"
            icon-name="base/Right"
            icon-position="right"
            icon-size="20px"
            @click="goSearch"
          />
        </template>
      </search-page-no-objects>
    </div>
  </div>
</template>

<script setup lang="ts">
import { userStore } from "~/store/user";

interface IProps {
  activeFavoriteTab: string;
  loading: boolean;
}

const props = defineProps<IProps>();

const useUserStore = userStore();
const localePath = useLocalePath();

const goSearch = () => {
  return navigateTo(localePath("/directory"));
};
</script>

<style scoped lang="scss">
.profile-container__favorites {
  display: grid;
  align-items: flex-start;
  justify-items: flex-start;
  width: 100%;
  gap: 16px;
  overflow: hidden;
  .favorites-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
  .favorites-none {
    grid-template-columns: 1fr;
    text-align: center;
    @media (max-width: 65rem) {
      .base-button {
        width: 100%;
      }
    }
  }
}
</style>
