import { PopUpServices } from "~/services/PopUp/callPopUp";
import type { gtagReachGoal } from "~/services/SEO/goals/createCustomGoal";
import type { UnitFileDTO } from "~/services/swagger/Api";

export const redirectByProviderName = (item: {
  providerName: string;
  utmLink: string;
  providerLink: string;
  externalUrl: string;
  source?:keyof typeof gtagReachGoal,
  images: UnitFileDTO[],
  logoFile: string
}) => {
  const routes: Record<string, string> = {
    binaryx: item.utmLink ?? item.externalUrl ?? item.providerLink,
    sabaiecoverse: item.utmLink ?? item.externalUrl ?? item.providerLink,
    investbay: item.utmLink ?? item.externalUrl ?? item.providerLink,
    estateprotocol: item.utmLink ?? item.externalUrl ?? item.providerLink,
    goro: item.utmLink ?? item.externalUrl ?? item.providerLink,
    landshare: item.utmLink ?? item.externalUrl ?? item.providerLink,
    // digishares: '',
    // polibit: '',
  };

  const normalizedProviderName = item.providerName?.toLowerCase()?.replace(/\s+/g, '')

  const redirectUrl = routes[normalizedProviderName];

  const callPopUp = PopUpServices();

  callPopUp.callPopUp({
    componentName: "provider-provider-pop-up",
    persistent: false,
    data: {url:redirectUrl, source:item.source, images: item.images, logoFile: item.logoFile}
  });
};
