export enum LegalTemplates {
  Bali = `As a foreigner in Bali, two main options stand out: Leasehold, which typically runs for up to 25 years with possible extensions, and Right to Use, granting initial 25-year usage plus a renewal option for an additional 20 years. Both provide access without offering outright ownership.<br/><br/>
Freehold is reserved for Indonesian citizens. Although some foreigners use nominee agreements, these can be legally complex and risky. Another avenue is setting up a PMA (foreign-owned) company to hold the property, which involves meeting certain investment requirements.<br/><br/>
No matter which route you take, plan for time limits and confirm that the property’s zoning fits your intended use — be it residential, commercial, or otherwise. Always consult local legal experts to ensure compliance with Indonesian regulations.

`,
  Thailand = `Foreigners in Thailand most commonly buy condominiums, as you can own these outright provided at least 51% of the building is Thai-owned. For land, direct ownership is restricted to Thai nationals, but you can secure a long-term leasehold (up to 30 years, often renewable) for greater stability.<br/><br/>
Another option is forming a Thai Limited Company, though foreigners can hold only 49% of the shares. There’s also the Usufruct agreement, which grants the right to use and benefit from land, typically for life, without transferring title.<br/><br/>
Keep in mind that Thailand enforces strict laws to maintain local control of land. Whatever path you choose, consult a qualified legal professional and ensure all documentation is transparent and valid under Thai regulations.

`,
  Turkey = `Turkey has made it relatively straightforward for foreigners to buy real estate. As a non-Turkish citizen, you can own freehold property (houses, apartments, or land) in most areas. The main exceptions are military zones and certain strategic locations where foreign ownership is not allowed.<br/><br/>
There is a 30-hectare limit on the total amount of property a foreign individual can own across the country. If you plan to buy land for development, you must submit a project plan to local authorities within two years. This helps ensure the property is used in alignment with local regulations.<br/><br/>
Overall, Turkey provides a welcoming environment for foreign buyers, and the process is fairly direct. Just be mindful of regional restrictions, and consider getting professional advice before completing any transaction to ensure you’re fully compliant with Turkish laws.
`,
  Slovakia = `When considering real estate in Slovakia, the rules differ slightly depending on whether you’re an EU citizen or not. EU citizens enjoy almost the same rights as Slovak nationals when buying residential property — this includes apartments, houses, and even land (though agricultural land may have special conditions).<br/><br/>
For non-EU citizens, ownership is still possible, but you may face additional checks or restrictions, particularly for large plots or certain land types. Many foreign investors in Slovakia opt to purchase apartments or small homes in urban areas, where the process is quite straightforward.<br/><br/>
An alternative is setting up a local Slovak limited liability company to hold the property. This can make transactions smoother, especially if you plan to own multiple properties or engage in business activities. As always, it’s important to consult a local lawyer or real estate expert to ensure you’re compliant with the latest regulations and zoning laws.
`,
  Spain = `Spain is well-known for its welcoming attitude toward foreign property buyers. As a foreigner, you can directly own real estate (freehold) without any major restrictions. This applies whether you’re looking at a seaside apartment on the Costa del Sol, a villa in Mallorca, or a city property in Madrid or Barcelona.<br/><br/>
One attractive aspect of investing in Spanish real estate is the Golden Visa program, which grants residency to non-EU investors who purchase property worth at least 500,000 EUR. This can be an appealing option if you’re considering longer stays or eventual residency in the country.<br/><br/>
That said, be aware of the annual property taxes, community fees (if buying in a complex), and local regulations that may vary by region. It’s advisable to work with a reputable real estate agent and a local lawyer to navigate these specifics and ensure a smooth purchasing process.`,
  United_States = `In the United States, foreigners can generally buy and own property with few restrictions, whether it’s a house, condominium, or commercial real estate. There’s no blanket prohibition against non-U.S. citizens owning real estate, and title can typically be held in your name or through a legal entity (like a Limited Liability Company or trust).<br/><br/>
The main considerations for foreign buyers often revolve around financing and taxes. Some U.S. banks may require additional documentation or higher down payments for foreign nationals. Additionally, there’s a special tax law known as FIRPTA (Foreign Investment in Real Property Tax Act), which may require withholding a portion of the sale proceeds when a foreign owner eventually sells the property.<br/><br/>
Despite these nuances, the U.S. market offers diverse opportunities — from urban condos to suburban homes and vacation properties. Working with a real estate agent familiar with foreign transactions and consulting a tax professional can help you navigate the process more smoothly.`,
  United_Arab_Emirates = `In the UAE, particularly in Dubai, foreigners can buy freehold property but only in designated Freehold Zones. Outside these zones, property ownership is generally restricted to UAE or GCC nationals, though leasehold arrangements (often 99-year leases) may be available to foreigners.<br/><br/>
Each emirate has its own regulations, but Dubai is the most open to international buyers. You can purchase apartments, villas, or commercial spaces in freehold areas, enjoying full ownership rights. This system has made Dubai a popular destination for foreign property investment.<br/><br/>
That said, property transactions in the UAE can involve various fees — including registration fees, agency commissions, and service charges—so it’s crucial to factor these into your budget. Additionally, some buyers pursue the Golden Visa for long-term residence, which can be obtained by investing in property above a certain threshold. Always consult with a licensed real estate agent and a legal advisor to ensure you understand local laws and any residency benefits that might apply.`,
}
