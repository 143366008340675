import { apiGet } from "~/services/api";
import getRoute from "~/utilities/configs/apiMap";
import { Notification } from "~/services/notifications/toast";
import { updateUnitPageSeo } from "~/services/SEO/updateUnitPageSeo";
import {
  getLocalStorage,
  writePersistentLocalStorage,
} from "~/services/LocalStorage/localStorage";
import {
  Api,
  type ChartsResponseDTO,
  type EstateFinanceDTO,
  type EstateTimelineDTO,
  type GetChartsResponseDTO,
  type LandFinanceDTO,
  type UnitResponseDTO,
} from "~/services/swagger/Api";
import { userNames } from "~/utilities/constants/userNames";

const nuxtApp = useNuxtApp();
const apiClient = new Api();

export interface UpdatedUnitResponseDTO
  extends Omit<UnitResponseDTO, "finance"> {
  finance: LandFinanceDTO & EstateFinanceDTO;
  timeline: EstateTimelineDTO[];
}

export const unitPageStore = defineStore("unitPage", {
  state: () => ({
    unitData: null as UpdatedUnitResponseDTO | null,
    currentElementInstance: "" as string,
    unitCharts: {} as ChartsResponseDTO | null,
  }),
  actions: {
    async getAllTokens() {
      try {
        const data = await apiGet({
          url: getRoute({ endpont: "get_blockchains" }),
        });
        if (!data) return;
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        createErrorNotify({ message: errorMessage });
      }
    },
    createUnit() {
      const state = this;

      class Unit {
        async getPageData(
          id: string,
          not404?: boolean,
          dont_update_seo?: boolean
        ) {
          if (!id) {
            if (!not404) {
              navigateTo("/404");
            }
            return null;
          }
          try {
            const response = await apiClient.api.unitsControllerRetrieve(id);
            if (!response.data) {
              if (!not404) {
                navigateTo("/404");
              }
              return null;
            }
            state.unitData = response.data as UpdatedUnitResponseDTO;
            if (!dont_update_seo) {
              updateUnitPageSeo(
                state.unitData,
                state.unitData.sanitizeName as string
              );
            }
            state.getAllTokens();

            nextTick(() => {
              setVisitMetrics(state.unitData);
            });
            return state.unitData;
          } catch (error) {
            if (!not404) {
              navigateTo("/404");
            }
            return null;
          }
        }

        async addToFavorite(id: string) {
          try {
            await apiClient.api.unitsControllerFavorite(id);
            return true;
          } catch (error) {
            createErrorNotify({
              message: nuxtApp.$i18n.t("errors.catch_all"),
            });
            return false;
          }
        }
        async getReviews(
          id: string,
          query: {
            limit: number;
            offset: number;
            rating?: ("Star_1" | "Star_2" | "Star_3" | "Star_4" | "Star_5")[];
          } = {
            limit: 5,
            offset: 0,
          }
        ) {
          try {
            const res = await apiClient.api.reviewsControllerList(id, query);
            if (!res) return [];
            res.data.forEach((item) => {
              if (!item?.user?.firstName && !item?.user?.firstName) {
                const el =
                  userNames[Math.floor(Math.random() * userNames.length)];
                item.user.firstName = el.firstName;
                item.user.lastName = el.lastName;
              }
            });
            return res.data ?? [];
          } catch (error) {
            createErrorNotify({
              message: error.message ?? `Can't get reviews to this unit.`,
            });
            return [];
          }
        }
      }
      return new Unit();
    },

    async getChartsData(query: {
      resolution: "minute" | "hour" | "day" | "week" | "month";
      period: "year" | "half-year" | "month" | "week" | "day";
      from?: string;
      to?: string;
      unitId: string;
    }) {
      if (!query.unitId) return [];
      try {
        const response = await apiClient.api.chartsControllerGetCharts(query);
        this.unitCharts = response.data;
        return this.unitCharts;
      } catch (error) {
        return null;
      }
    },

    async proccessUnitPageData(data: UpdatedUnitResponseDTO) {
      this.unitData = data;
      // const now = new Date();
      updateUnitPageSeo(this.unitData, this.unitData.sanitizeName as string);
      this.getAllTokens();
      this.getChartsData({
        unitId: data.id,
        resolution: "minute",
        period: "week",
        // from: new Date(
        //   now.getFullYear() - 1,
        //   now.getMonth(),
        //   now.getDate()
        // ).toISOString(),
        // to: now.toISOString(),
      });
      nextTick(() => {
        setVisitMetrics(this.unitData);
      });
    },
  },
});

const createErrorNotify = (args: { message: string; delay?: number }) => {
  const errorNotify = new Notification({
    type: "error",
    message: args.message,
    timeout: args.delay ?? 5000,
  });
  errorNotify.createNewToast();
};

const setVisitMetrics = async (unit: UpdatedUnitResponseDTO | null) => {
  const apiClient = new Api();
  const today = new Date();
  const pageId = unit?.id || "";

  let metrics: {
    viewsPerDay: number;
    viewsForAllTime: number;
  } = unit?.metrics || {
    viewsPerDay: 0,
    viewsForAllTime: 0,
  };

  // Получаем информацию о посещениях из локального хранилища
  const visitData = getLocalStorage("unit_page_visit");

  if (!visitData) {
    // Если пользователь зашел на страницу первый раз за сегодня или не было никаких записей
    writePersistentLocalStorage("unit_page_visit", {
      date: today,
      pageIds: [pageId],
    });
    metrics = {
      viewsPerDay: metrics.viewsPerDay + 1,
      viewsForAllTime: metrics.viewsForAllTime + 1,
    };
    // Отправляем обновленные метрики на бэкэнд
    apiClient.api.unitsControllerUpdateMetrics(pageId);
  } else {
    const visitDate = new Date(visitData.date);

    // Проверяем, является ли текущая дата новой
    if (visitDate.toDateString() !== today.toDateString()) {
      // Обновляем дату и записываем текущую страницу как посещенную
      writePersistentLocalStorage("unit_page_visit", {
        date: today,
        pageIds: [pageId],
      });
      metrics = {
        viewsPerDay: metrics.viewsPerDay + 1,
        viewsForAllTime: metrics.viewsForAllTime + 1,
      };
      // Отправляем обновленные метрики на бэкэнд
      apiClient.api.unitsControllerUpdateMetrics(pageId);
    } else if (!visitData.pageIds.includes(pageId)) {
      // Если текущая дата совпадает, но страница еще не была посещена
      visitData.pageIds.push(pageId);
      writePersistentLocalStorage("unit_page_visit", visitData);
      metrics = {
        viewsPerDay: metrics.viewsPerDay + 1,
        viewsForAllTime: metrics.viewsForAllTime + 1,
      };
      // Отправляем обновленные метрики на бэкэнд
      apiClient.api.unitsControllerUpdateMetrics(pageId);
    }
  }
};
