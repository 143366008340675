<template>
  <section class="main-hero">
    <div class="main-hero__gridbox">
      <div class="title-box">
        <span>
          <p class="font--h3 text-white-monochrome mobile-font--h6">
            RWA Estate Aggregator
          </p>
          <span class="font--h5-3 text-white-monochrome mobile-font--b5">
            {{ $t("main.m_invest_just") }}
            <number-flow :value="flowNumber" :prefix="flowPrefix" />
          </span>
        </span>

        <base-button
          v-if="!$device.isMobile"
          :text="$t('main.m_find_properties')"
          variant="light"
          size="lg"
          @click="redirectToSearch"
        />
      </div>
      <div class="carousel-box">
        <main-custom-carousel />
      </div>
      <base-button
        v-if="$device.isMobile"
        :text="$t('main.m_find_properties')"
        variant="light"
        size="lg"
        class="hero-mobile-butt"
        @click="redirectToSearch"
      />
    </div>
    <span v-if="!$device.isMobile" class="main-hero__backdrop">
      <base-icon name="houses/backdrop" size="100%" filled />
    </span>
    <span v-show="!$device.isMobile" class="main-hero__select">
      <base-custom-select />
    </span>
  </section>
</template>

<script setup lang="ts">
import NumberFlow from "@number-flow/vue";

const flowNumber = ref(50);
const flowPrefix = ref("$");
const keys = ["USD", "EUR", "GBP", "CNY", "TRY"];

let intervalId: ReturnType<typeof setInterval> | null = null;
let currentIndex = 0;

const updateFlow = () => {
  const key = keys[currentIndex];
  const currency = CURRENCIES[key];
  if (currency) {
    flowNumber.value = Math.ceil(50 * currency.rate);
    flowPrefix.value = currency.symbol;
  }
  currentIndex = (currentIndex + 1) % keys.length;
};

const redirectToSearch = () => {
  const localePath = useLocalePath();
  const route = localePath("/directory");
  navigateTo(route);
};

onMounted(() => {
  intervalId = setInterval(updateFlow, 1500);
});

onBeforeUnmount(() => {
  if (intervalId) clearInterval(intervalId);
});
</script>

<style scoped lang="scss">
.main-hero {
  position: relative;
  width: 100%;
  height: 504px;
  max-width: 1320px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 40px;
  background: linear-gradient(180deg, #6742d0 0%, #1f0764 100%);

  &__gridbox {
    width: fit-content;
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    // padding-left: 164px;
    // padding-right: 164px;
    grid-template-columns: 1fr 1fr;
    .title-box {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
      z-index: 1;
      max-width: 425px;
      .base-button {
        width: 218px;
      }
    }
    .carousel-box {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__backdrop {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
  &__select {
    position: absolute;
    left: 50%;
    bottom: -36px;
    z-index: 1;
    transform: translateX(-50%);
    width: 100%;
    max-width: 990px;
  }
}

@media (max-width: 75rem) {
  @include mobile-fonts;
  .main-hero {
    &__gridbox {
      position: relative;
      padding: 16px;
      grid-template-columns: 1fr;
      width: 100%;
      .hero-mobile-butt {
        width: 100%;
        max-width: 85%;
        height: 40px;
        padding: 8px 28px;
      }
      .title-box {
        max-width: 214px;
        margin-left: 30px;
        justify-self: self-start;
      }
    }
  }
}
</style>
