<template>
  <div class="base-swiper-slider">
    <swiper
      :modules="[Navigation]"
      :slides-per-view="props.slidesPerView"
      :space-between="20"
      :loop="props.loop"
      :grab-cursor="true"
      v-bind="$attrs"
      @swiper="onSwiper"
    >
      <template v-if="$slots.default">
        <swiper-slide
          v-for="(child, index) in $slots.default()[0]?.children ?? []"
          :key="index"
        >
          <component :is="child" />
        </swiper-slide>
      </template>
    </swiper>
    <div v-if="$slots.default()[0]?.children?.length > 3" class="controls">
      <button class="controls-butt hover-scale-light" @click="slidePrev">
        <base-icon name="base/ChevronLeft" />
      </button>
      <button class="controls-butt hover-scale-light" @click="slideNext">
        <base-icon name="base/ChevronRight" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

//look https://swiperjs.com/swiper-api#parameters for avaliable props

interface Props {
  slidesPerView?: number | "auto" | undefined;
  loop?: boolean | undefined;
}

const props = withDefaults(defineProps<Props>(), {
  slidesPerView: 3,
});

const swiperInst = ref<typeof Swiper | null>(null);
const onSwiper = (swiper: any) => {
  swiperInst.value = swiper;
};

const slideNext = () => {
  swiperInst.value?.slideNext();
};
const slidePrev = () => {
  swiperInst.value?.slidePrev();
};

watch(
  () => props.slidesPerView,
  (newVal) => {
    if (swiperInst.value) {
      swiperInst.value.params.slidesPerView = newVal;
      swiperInst.value.update();
    }
  }
);
</script>

<style scoped lang="scss">
.base-swiper-slider {
  width: 100%;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content !important;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .controls {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 20px;
    &-butt {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      outline: none;
      background-color: transparent;
      border: 1px solid var(--gray-monochrome-40);
      cursor: pointer;
      &:hover {
        border-color: var(--gray-monochrome);
      }
    }
  }
}
</style>
