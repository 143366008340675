<template>
  <div
    v-if="!isOwnersTabHidden || !isPartnersTabHidden"
    class="property-info"
    :style="{ maxHeight: maxCardHeight + 'px' }"
  >
    <div class="property-info--toggle">
      <button
        class="property-info--toggle--button text-black-monochrome"
        :class="[
          showPartners ? 'active' : '',
          showPartners ? 'font--b5-1' : 'font--b5',
        ]"
        :disabled="isPartnersTabHidden"
        @click="showPartners = true"
      >
        Partners
      </button>
      <button
        class="property-info--toggle--button text-black-monochrome"
        :class="[
          !showPartners ? 'active' : '',
          !showPartners ? 'font--b5-1' : 'font--b5',
        ]"
        :disabled="isOwnersTabHidden"
        @click="showPartners = false"
      >
        {{ $t("unit_page.un_top_owners") }}
      </button>
    </div>
    <div
      v-if="showPartners && !isPartnersTabHidden"
      ref="partnersRef"
      :class="[
        'property-info--partners--column',
        'animate__animated',
        'animate__fadeIn',
      ]"
    >
      <div
        v-for="item in oneFromToPartners"
        class="card"
        @click="openPartner(item.link)"
      >
        <div class="logo-img">
          <img :src="item.logo || noIcon" alt="icon" />
        </div>

        <div class="partner-text-wrapper">
          <h4 class="font--b5-1 mobile-font--b3">
            {{ item.name || "Not provided" }}
          </h4>
          <p class="font--b5 text-black-monochrome-60">{{ item.sub }}</p>
        </div>
      </div>
      <base-separator v-if="props.partners?.length" />
      <div
        v-for="(partner, index) in props.partners ?? []"
        :key="index"
        class="card"
        @click="openPartner(partner.url)"
      >
        <div class="logo-img">
          <img :src="partner?.logoFile?.url || noIcon" alt="icon" />
        </div>

        <div class="partner-text-wrapper">
          <h4 class="font--b5-1 mobile-font--b3">
            {{ partner.name || "Not provided" }}
          </h4>
          <p class="font--b5 text-black-monochrome-60">
            {{ partner.subtitle }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="!showPartners"
      class="property-info--owners--update font--b6 text-black-monochrome-60"
    >
      Updated {{ updateDate }}
    </div>
    <div
      v-if="!showPartners"
      ref="ownersRef"
      class="property-info--owners animate__animated animate__fadeIn"
    >
      <div
        v-for="(owner, index) in props.token?.holders"
        :key="index"
        class="property-info--owners--card"
        :class="{ separated: index !== 0 }"
      >
        <div class="owner-address">
          <div class="owner-address--circle">
            <base-icon
              :name="`chain/${props.token?.chain?.name}`"
              filled
              size="100%"
            />
          </div>
          <a
            :href="`${getTokenScanUrl(props.token?.chain?.name)}/address/${owner.address}`"
            target="_blank"
            class="link"
          >
            <p class="font--b5 mobile-font--b5">
              {{ truncateStringMiddle(owner.address) }}
            </p>
          </a>
        </div>
        <div class="owner-amount">
          <!-- <p class="font--b3">{{ owner.title }}</p> -->
          <p class="font--b5">
            {{ abbreviateNumber(owner.balance) }}
          </p>
        </div>
      </div>
    </div>
    <div>
      <base-separator v-if="!showPartners" class="bottom-line" />
    </div>
    <div
      v-if="!showPartners && props.token?.address"
      class="property-info--owners--card separated wallet"
    >
      <div class="owner-address ml--2">
        <base-icon name="base/token" size="22px" filled />
        <p class="font--b5 text-black-monochrome">Token</p>
      </div>
      <nav class="controls">
        <button class="owner-amount amount-butt" @click="navigateToScan">
          <p class="font--b5-1 text-black-monochrome">
            {{ props.token?.address.slice(0, 5) }}...{{
              props.token?.address.slice(-4)
            }}
          </p>
          <base-icon name="base/ArrowLineUpRight" size="16px" />
        </button>
        <button class="wallet-list" @click="addToWallet">
          <base-icon name="base/wallet" size="14px" filled />
        </button>
      </nav>
    </div>
    <base-button
      class="scan-butt"
      :class="{ 'mt-0': !showPartners }"
      :variant="!showPartners ? 'dark_ghost' : 'prime'"
      :text="
        !showPartners
          ? $t('search_card.fr_v_more')
          : $t('search_card.fr_contact')
      "
      size="ls"
      @click="handleClick"
    />
  </div>
</template>

<script setup lang="ts">
import { truncateStringMiddle } from "~/utilities/helpers/strings/truncateStringMiddle";
import type { Owner } from "~/types/base-property-info";
import { abbreviateNumber } from "~/utilities/helpers/format-data/number";
import { Notification } from "~/services/notifications/toast";
import noIcon from "~/assets/icons/no-icon.svg";
import type {
  ProviderDTO,
  TokenIssuerResponseDTO,
  TokenResponseDTO,
  UnitPartnerDTO,
} from "~/services/swagger/Api";
import { redirectByProviderName } from "~/utilities/helpers/strings/redirectByProviderName";
import type { UnitFileDTO } from "~/services/swagger/Api";
// import starHalf from "~/assets/icons/base/ReviewStarHalf.svg";

interface Props {
  updateDate?: string;
  partners?: UnitPartnerDTO[] | null | undefined;
  provider?: ProviderDTO;
  refLink?: string;
  externalUrl?: string;
  token: TokenResponseDTO;
  images?: UnitFileDTO[];
}

const props = withDefaults(defineProps<Props>(), {
  updateDate: "two hours ago",
  partners: () => [],
  owners: () => [],
  chain: "ethereum",
});

const getTokenScanUrl = (network: string | undefined | null): string => {
  switch (network?.toLowerCase()) {
    case "ethereum":
      return "https://etherscan.io";
    case "bsc": // Binance Smart Chain
    case "binance smart chain":
      return "https://bscscan.com";
    case "polygon":
    case "matic":
      return "https://polygonscan.com";
    case "avalanche":
      return "https://snowtrace.io";
    case "fantom":
      return "https://ftmscan.com";
    case "arbitrum":
      return "https://arbiscan.io";
    case "optimism":
      return "https://optimistic.etherscan.io";
    case "tron":
      return "https://tronscan.org";
    case "solana":
      return "https://solscan.io";
    case "aptos":
      return "https://explorer.aptoslabs.com";
    default:
      throw new Error(`Unsupported network: ${network}`);
  }
};

const showPartners = ref(true);

const partnersRef = ref<HTMLElement | null>(null);
const ownersRef = ref<HTMLElement | null>(null);

const partnersHeight = computed(() => partnersRef.value?.clientHeight);

const maxCardHeight = computed(() => {
  const baseHeight = 154;
  const partnersHeightValue = partnersHeight.value || 0;
  return showPartners.value
    ? baseHeight + partnersHeightValue
    : baseHeight + 560;
});

const oneFromToPartners = computed(() => {
  if (!props.token?.issuer && !props.provider) return null;

  const buildPartner = (
    logo: string | undefined,
    link: string | undefined,
    name: string | undefined,
    sub: string
  ) => ({ logo, link, name, sub });

  const partners = [];

  if (props.token?.issuer) {
    partners.push(
      buildPartner(
        props.token?.issuer?.logoFile?.url,
        props.refLink ?? props.token?.issuer.url,
        props.token?.issuer.name,
        "Issuer"
      )
    );
  }

  if (
    props.provider &&
    !props.provider?.name.includes(props.token?.issuer?.name || "notIncludes")
  ) {
    partners.push(
      buildPartner(
        props.provider.logo,
        props.provider.url,
        props.provider.name,
        "Provider"
      )
    );
  }

  return partners;
});

const isPartnersTabHidden = computed(() => {
  const foo = Boolean(
    props.partners?.length || props.provider || props.token?.issuer
  );
  showPartners.value = foo;
  return !foo;
});

const isOwnersTabHidden = computed(() => {
  return !props.token?.holders?.length;
});

const navigateToScan = () => {
  if (!props.token?.url) return;
  const link = `${props.token?.url}#balances`;
  window.open(link, "_blank");
};

const addToWallet = () => {
  web3OnboardManager.addTokenToPrimaryWallet({
    address: props.token?.address,
    symbol: props.token.symbol,
    decimals: props.token.decimals,
    image: props.token.icon,
  });
};

const navigateToIssuer = () => {
  redirectByProviderName({
    providerName: props.provider?.name,
    providerLink: props.provider?.url,
    utmLink: props.refLink,
    externalUrl: props.externalUrl,
    source: "unit__open_ref",
    images: props.images,
    logoFile: props.provider?.logoFile?.url
  });
};

const openPartner = (link: string | undefined) => {
  if (!link) {
    const errorNotify = new Notification({
      type: "system",
      message: "We don't have a link to this partner for now =(",
    });
    errorNotify.createNewToast();
    return;
  }
  window.open(link, "_blank");
};

const handleClick = () => {
  if (!showPartners.value) {
    navigateToScan();
  } else {
    navigateToIssuer();
  }
};

const partnerSrc = (partner: UnitPartnerDTO) => {
  return partner.logoFile.url;
};
</script>

<style scoped lang="scss">
.property-info {
  width: 100%;
  box-shadow: var(--shadow-light);
  background: var(--white-contrast);
  border-radius: 16px;
  padding: 16px;
  transition: max-height 0.6s ease;
  overflow: hidden;
  .scan-butt {
    width: 100%;
    margin-top: 20px;
  }
  .mt-0 {
    margin-top: 0;
  }

  &--toggle {
    width: 100%;
    border: 1px solid var(--gray-monochrome-40);
    border-radius: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &--button {
      background: transparent;
      cursor: pointer;
      border: none;
      padding: 6px;
      width: 100%;
      text-align: center;
      border-radius: 22px;
      transition: all 0.3s ease-out;
    }

    .active {
      background-color: var(--gray-monochrome-40);
    }
  }

  &--partners--column {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .card {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      cursor: pointer;

      .logo-img {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-height: 40px;
          max-width: 40px;
          object-fit: cover;
        }
      }

      .partner-text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
      }
    }
  }

  &--partners--row {
    padding-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;

      .logo-img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 8px;
      }

      h4 {
        text-align: center;
      }

      .partner-text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &--owners {
    display: flex;
    flex-direction: column;
    max-height: 136px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      // height: 12px;
    }

    &::-webkit-scrollbar-track {
      border: 1px solid var(--Monochrome-Gray_40, #9ea7b966);
      background: transparent;
      border-radius: 10px;
    }

    /* Бегунок */
    &::-webkit-scrollbar-thumb {
      background: var(--Monochrome-Gray_40, #9ea7b966);
      border-radius: 12px;

      border: 20px solid transparent;
    }
    // @supports not selector(::-webkit-scrollbar) {
    //   * {
    //     scrollbar-color: var(---sky-200) var(--sky-100);
    //   }
    // }

    &--update {
      padding: 11px 0 16px 0;
      border-bottom: 1px solid var(--Monochrome-Gray_40, #9ea7b966);
      margin-bottom: 6px;
    }
    .owner-address {
      font-size: 3px;
    }
    .separated {
      border-top: 1px dashed var(--gray-monochrome-40);
      border-image: repeating-linear-gradient(
        to right,
        var(--gray-monochrome-40) 0,
        var(--gray-monochrome-40) 8px,
        transparent 8px,
        transparent 16px
      );
      border-image-slice: 1;
    }
    .property-info--owners--card:first-child {
      padding-top: 8px;
    }
    .separated:last-child {
      border-width: 1px;
      padding-bottom: 5px;
    }
    &--card {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
      margin-right: 16px;
      .controls {
        display: flex;
        align-items: center;
        gap: 4px;
        .wallet-button {
          @include drop-button-styles;
          display: flex;
          width: 24px;
          height: 24px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          border-radius: 50%;
          border: 1px solid var(--gray-monochrome-40);
          background: var(--white-monochrome);
          box-shadow: var(--shadow-light);
        }
      }
      .owner-address {
        display: flex;
        flex-direction: row;
        gap: 12px;
        align-items: center;

        .link {
          all: unset;
          cursor: pointer;
        }

        &--circle {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--gray-monochrome);
          border-radius: 50%;
          width: 24px;
          height: 24px;
        }
      }

      .owner-amount {
        display: flex;
        gap: 8px;
        align-items: center;
        cursor: pointer;
        p {
          font-weight: 600;
        }
      }
      .amount-butt {
        @include drop-button-styles;
        border-bottom: 1px solid var(--black-monochrome);
      }
    }

    &--card:last-child {
      // padding-bottom: 0;
      border-bottom: none;
    }
  }
  .ml--2 {
    margin-left: 2px;
  }
  .wallet {
    background: var(--Monochrome-White, #e9ebf8);
    margin: 16px -16px;
    padding: 16px;
    margin-bottom: 16px;
  }
  .wallet-list {
    @include drop-button-styles;
    background: var(--Monochrome-White_contrast, #f3f7fe);
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--gray-monochrome-40);
    margin-left: 12px;
  }
}
.bottom-line {
  display: block;
  margin: 6px 0 6px;
}
@media (max-width: 59rem) {
  @include mobile-fonts;

  .property-info {
    width: 100%;
    max-width: 345px;

    &--partners--row {
      .card {
        .logo-img {
          width: 32px;
          height: 32px;
        }
      }
    }

    &--owners {
      &--card {
        .owner-address {
          &--circle {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
}
</style>
