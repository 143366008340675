import type { FileResponseDTO } from "~/services/swagger/Api";
import noImage from "~/assets/img/no-image.svg";

export const getThumbnail = (
  file: FileResponseDTO | Partial<FileResponseDTO> | null | undefined,
  thumbnailType:
    | "original"
    | "thumbnail_470x370"
    | "thumbnail_130x100"
    | "thumbnail_370x240"
    | "thumbnail_200x140"
) => {
  if (!file) return noImage;
  if (thumbnailType === "original") return file.url;
  const thumbnail = file.thumbnails?.find(
    (item) => item.thumbnailType === thumbnailType
  );

  const url = thumbnail?.url || file?.url;
  return url;
};
