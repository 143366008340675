<template>
  <unit-page v-bind="$attrs">
    <template #bubbles>
      <div class="unit-bubbles">
        <span v-for="bubble in bubbles" v-show="bubble.isBubble" class="bubble">
          <base-icon :name="bubble.iconName" color="var(--black-monochrome)" />
          <p class="font--b5 mobile-font--b4">
            {{ bubble.label }}
          </p>
        </span>
      </div>
    </template>
    <template #finance="{ unitData }">
      <unit-page-finance-block-land :unit-page-data="unitData" />
    </template>
    <template #infoBlock="{ unitData, imageArray }">
      <unit-page-info-block :property-info="unitData" type="lands">
        <template v-if="$device.isMobile" #mobilt-swiper>
          <mobile-swiper-gallery
            class="base-serach-card--mobile-gallery"
            style="max-width: 100%"
            :go-to-unit="unitData?.sanitizeName"
          >
            <div v-for="file in imageArray">
              <img :src="file.url" alt="property photo" />
            </div>
          </mobile-swiper-gallery>
        </template>
      </unit-page-info-block>
    </template>
    <template #compareBlock>
      <unit-page-compare-block :property-info="unitData" property-type="land" />
    </template>
    <template #suggs="{ suggsArr }">
      <base-suggested-estates
        v-if="unitData"
        :estates="suggsArr"
        type="lands"
      />
    </template>
  </unit-page>
</template>

<script setup lang="ts">
import { unitPageStore } from "~/store/unitPage";
import type { PropertyBubblesBase } from "../types";

interface CorrectedPropertyBubblesBase extends PropertyBubblesBase {
  value: string | number;
}

const useUnitpageStore = unitPageStore();
const unitPage = defineAsyncComponent(
  () => import("~/components/unit-page/unit/index.vue")
);
const unitData = computed(() => useUnitpageStore.$state.unitData);
const { t } = useI18n();

const getUtilsLandName = (value: string | undefined) => {
  switch (value) {
    case "yes":
      return t("common.yes");
    case "no":
      return t("common.no");
    default:
      return t("common.unknown");
  }
};

const bubbles = ref<CorrectedPropertyBubblesBase[]>([
  {
    isBubble: true,
    label: t("search_filters.fr_acreage"),
    value: `${unitData.value?.area || 0} acre`,
    iconName: "base/Acreage",
  },
  {
    isBubble: true,
    label: t("unit_page.un_utils"),
    value: getUtilsLandName(unitData.value?.utilities),
    iconName: "base/Utilities",
  },
  {
    isBubble: true,
    label: t("unit_page.un_current_use.title"),
    value: t(
      `unit_page.un_current_use.${unitData.value?.currentUse || "other"}`
    ),
    iconName: "base/Farm",
  },
]);
</script>

<style scoped lang="scss">
@import url(~/assets/styles/unit-page/index.scss);
.page-content {
  .unit-container {
    .unit__left {
      .current__tab-dynamic {
        .unit-bubbles {
          .bubble {
            min-width: 180px;
            max-height: 56px;
            border-radius: 16px;
            .bubble-flexbox {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}
</style>
