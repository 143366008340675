<template>
  <section class="sign-in">
    <div class="sign-in__header">
      <h5 class="title-underlined font--h4 text-black-monochrome">
        {{ !isNewUser ? $t("login.log_in") : $t("login.log_up") }}
      </h5>
      <button v-close-popup class="close-butt hover-rotate">
        <Suspense>
          <base-icon name="base/Close" size="100%" />
        </Suspense>
      </button>
    </div>
    <p v-if="isMailTokenRecived" class="font--b2 text-black-monochrome">
      {{ $t("login.log_fill") }}
    </p>
    <q-form ref="formRef" class="sign-in__body">
      <span v-if="isNewUser && !isMailTokenRecived" class="gridbox">
        <label class="font--b3-m text-black-monochrome"
          >{{ $t("login.log_username") }}<sup>*</sup></label
        >
        <q-input
          ref="userNameRef"
          v-model="userData.userName"
          :placeholder="$t('login.log_type')"
          outlined
          :loading="checkingUserName"
          type="text"
          lazy-rules
          :debounce="300"
          :maxlength="20"
          :rules="[
            (val: string) => val.length >= 3 || $t('errors.login.chars_3'),
            () => !isUsernameTaken || $t('errors.login.taken'),
            () => validateUserName || $t('errors.login.username_valid'),
          ]"
          @update:model-value="checkUserNameTaken"
        />
      </span>
      <span v-if="!isMailTokenRecived" class="gridbox">
        <label class="font--b3-m text-black-monochrome"
          >{{
            !isNewUser
              ? $t("login.log_username_or_email")
              : $t("login.log_email")
          }}<sup>*</sup></label
        >
        <q-input
          ref="emailRef"
          v-model="userData.email"
          :placeholder="$t('login.log_type')"
          outlined
          type="email"
          lazy-rules
          :rules="[
            (val: string) =>
              !!val ||
              `${isNewUser ? $t('errors.login.email') : $t('errors.login.or')}`,
            (val: string) =>
              /.+@.+\..+/.test(val) ||
              `${isNewUser ? $t('errors.login.email') : $t('errors.login.or')}`,
            () => !isEmailTaken || $t('errors.login.email_taken'),
          ]"
        />
      </span>
      <span
        class="gridbox"
        :style="{ marginBottom: passwordRef?.hasError ? '35px' : 0 }"
      >
        <span class="flexbox">
          <span
            :class="[
              'font--b3-m',
              'text-black-monochrome',
              { 'flexbox-tide': isNewUser },
            ]"
            style="justify-content: flex-start; gap: 1px"
            >{{
              isMailTokenRecived
                ? $t("login.log_verif")
                : isNewUser
                  ? $t("login.log_cre_pass")
                  : $t("login.log_password")
            }}<sup>*</sup>
            <!-- <base-tooltip
              v-if="isNewUser"
              position="right"
              :tooltip-text="$t('login.log_password_rules')"
            >
              <template #icon>
                <suspense>
                  <base-icon
                    name="base/Info"
                    size="16px"
                    color="var(--black-monochrome-60)"
                  />
                </suspense> </template
            ></base-tooltip> -->
          </span>
          <button
            v-if="!isNewUser"
            class="forgot-pass"
            @click.prevent="restoreAcc"
          >
            {{ $t("login.log_forgot_password") }}
          </button>
        </span>
        <q-input
          ref="passwordRef"
          v-model="userData.password"
          outlined
          lazy-rules
          :placeholder="$t('login.log_type')"
          :class="{ 'custom-error-input': passwordRef?.hasError }"
          :type="!showPassword ? 'password' : 'text'"
          :rules="[(val) => passwordValidation(val)]"
        >
          <!-- <q-input
          ref="passwordRef"
          v-model="userData.password"
          outlined
          lazy-rules
          :placeholder="$t('login.log_type')"

          :placeholder="
            isMailTokenRecived ? 'Type here' : 'Type password here '
          "
          :type="!showPassword ? 'password' : 'text'"
          :rules="[(val) => passwordValidation(val)]"
        > -->
          <template v-if="!isMailTokenRecived" #append>
            <Suspense>
              <base-icon
                :name="`base/${showPassword ? 'Eye' : 'EyeClosed'}`"
                @click="showPassword = !showPassword"
              />
            </Suspense>
          </template>
          <template v-else-if="isUserTurnedBack" #append>
            <Suspense>
              <base-icon
                name="base/ClipboardText"
                class="hover-scale-light"
                @click="checkUserClipboard"
              />
            </Suspense>
          </template>
        </q-input>
      </span>
      <span v-if="isNewUser && !isMailTokenRecived" class="gridbox">
        <label class="font--b3-m text-black-monochrome"
          >{{ $t("login.log_repeat_password") }}<sup>*</sup></label
        >
        <q-input
          ref="repeatPasswordRef"
          v-model="userData.repeatPassword"
          outlined
          lazy-rules
          :placeholder="$t('login.log_type_password')"
          :type="!showPassword ? 'password' : 'text'"
          :rules="[
            (val) => val === userData.password || $t('errors.login.passwords'),
          ]"
        >
          <template #append>
            <Suspense>
              <base-icon
                :name="`base/${showPassword ? 'Eye' : 'EyeClosed'}`"
                @click="showPassword = !showPassword"
              />
            </Suspense>
          </template>
        </q-input>
      </span>
      <span v-if="isNewUser && !isMailTokenRecived" class="flexbox">
        <input type="checkbox" :checked="termsChecked" />
        <label @click="termsChecked = !termsChecked" />
        <span class="font--b5 text-black-monochrome"
          >{{ $t("login.log_agree_1") }}
          <nuxt-link class="text-violet-main" to="/terms" target="_blank">{{
            $t("login.log_agree_2")
          }}</nuxt-link>
          {{ $t("login.log_agree_3") }}
          <nuxt-link
            class="text-violet-main"
            to="/privacy-policy"
            target="_blank"
            >{{ $t("login.log_agree_4") }}</nuxt-link
          ></span
        >
      </span>
    </q-form>
    <div class="sign-in__controls">
      <base-button
        class="sign-butt"
        :text="
          !isNewUser
            ? $t('login.log_in')
            : isNewUser && !isMailTokenRecived
              ? $t('login.log_up')
              : $t('login.log_cont')
        "
        size="lg"
        variant="dark"
        type="submit"
        :loading="mainButtLoading"
        :disabled="(!termsChecked && isNewUser) || checkingUserName"
        @click="handleSignClick"
      />
      <p v-if="!isMailTokenRecived" class="font--b2 text-black-monochrome">
        {{ $t("login.log_continue_with") }}
      </p>
      <span v-if="!isMailTokenRecived" class="flexbox">
        <Suspense>
          <base-button
            text="Google"
            size="lg"
            variant="dark_ghost"
            icon-name="social/Google"
            icon-filled
            :loading="googleButtLoading"
            @click="singInWithGoogleSilent"
          />
        </Suspense>
        <!-- <Suspense>
          <base-button
            text="Apple"
            size="lg"
            variant="dark_ghost"
            icon-name="social/Apple"
            icon-filled
          />
        </Suspense> -->
      </span>
      <span class="has-account font--b2 text-black-monochrome"
        >{{
          isNewUser && !isMailTokenRecived
            ? $t("login.log_ex_account")
            : !isNewUser && !isMailTokenRecived
              ? $t("login.log_no_account")
              : $t("login.log_didnt_receive_code")
        }}
        <button
          v-if="!isCounter"
          :style="{ fontWeight: !isMailTokenRecived ? 700 : 400 }"
          @click.stop="handleMultyButt"
        >
          {{
            isNewUser && !isMailTokenRecived
              ? $t("login.log_in")
              : !isNewUser && !isMailTokenRecived
                ? $t("login.log_up")
                : $t("login.log_send_ag")
          }}
        </button>
        <p v-else class="font--b2 text-black-monochrome">
          {{ $t("login.log_send_again_in", { time: 30 - sentCounter }) }}
        </p>
      </span>
    </div>
  </section>
</template>

<script setup lang="ts">
import { userStore } from "~/store/user";
import { PopUpServices } from "~/services/PopUp/callPopUp";
import { QInput } from "quasar";
import { debounce } from "lodash";
import { writePersistentLocalStorage } from "~/services/LocalStorage/localStorage";

useHead({
  script: [
    {
      async: true,
      src: "https://accounts.google.com/gsi/client",
      defer: false,
    },
  ],
});

interface IProps {
  data: "login" | "signup";
}

const props = defineProps<IProps>();
const emit = defineEmits(["hide_popup"]);
const localePath = useLocalePath();
const $q = useQuasar();
const { t } = useI18n();

const useUserStore = userStore();
const usePopUpServices = PopUpServices();
const isNewUser = computed(() => props.data === "signup");
const isMailTokenRecived = ref(false);
const showPassword = ref(false);
const formRef = ref<HTMLElement | null>(null);
const termsChecked = ref(false);
const sentCounter = ref(0);
const isCounter = ref(false);
const isUserTurnedBack = ref(false);
const isUsernameTaken = ref(false);
const userNameRef: Ref<QInput | null> = ref(null);
const emailRef: Ref<QInput | null> = ref(null);
const passwordRef: Ref<QInput | null> = ref(null);
const mainButtLoading = ref(false);
const googleButtLoading = ref(false);
let intervalId: NodeJS.Timeout;

const userData = reactive({
  email: "",
  password: "",
  repeatPassword: "",
  userName: "",
});

const handleSignClick = () => {
  formRef.value?.submit();
  formRef.value?.validate().then((success) => {
    if (success) {
      if (!isNewUser.value) {
        debouncedLoginUser();
      } else {
        debouncedSingUpUser();
      }
    } else {
      return;
    }
  });
};

const debouncedLoginUser = debounce(
  () => {
    handleLogin();
  },
  300,
  { leading: true, trailing: false }
);

const debouncedSingUpUser = debounce(
  () => {
    handleSignUp();
  },
  300,
  { leading: true, trailing: false }
);
const handleLogin = async () => {
  if (import.meta.client) {
    const { $recaptcha } = useNuxtApp();
    const token = await $recaptcha.execute("login");
    mainButtLoading.value = true;
    const res = await useUserStore.loginUser({
      email: userData.email.toLowerCase(),
      password: userData.password,
      recaptcha: token,
    });
    if (res) {
      await useUserStore.fetchUserData();
      mainButtLoading.value = false;
      emit("hide_popup", "ca");
    }
  }
};

const isEmailTaken = ref(false);
const handleSignUp = async () => {
  if (import.meta.client) {
    const { $recaptcha } = useNuxtApp();
    const token = await $recaptcha.execute("login");
    isEmailTaken.value = false;
    writePersistentLocalStorage("user_email", userData.email.toLowerCase());
    mainButtLoading.value = true;
    const res = await useUserStore
      .signUpUser({
        email: userData.email.toLowerCase(),
        password: userData.password,
        userName: userData.userName.toLowerCase(),
        isMailTokenRecived: isMailTokenRecived.value,
        recaptcha: token,
      })
      .finally(() => {
        mainButtLoading.value = false;
      });
    if (res) {
      if (isMailTokenRecived.value) {
        emit("hide_popup", "ca");
        const route = localePath("/profile");
        navigateTo(route);
      }
      isMailTokenRecived.value = !isMailTokenRecived.value;
      userData.password = "";
    } else {
      isEmailTaken.value = true;
      userNameRef.value?.validate();
      emailRef.value?.validate();
    }
  }
};

const handleMultyButt = () => {
  if (isNewUser.value && !isMailTokenRecived.value) {
    emit("hide_popup", "ca");
    nextTick(() => {
      usePopUpServices.callPopUp({
        componentName: "login-login-pop-up",
        persistent: false,
        data: "login",
      });
    });
  }
  if (!isNewUser.value && !isMailTokenRecived.value) {
    emit("hide_popup", "ca");
    nextTick(() => {
      usePopUpServices.callPopUp({
        componentName: "login-login-pop-up",
        persistent: false,
        data: "signup",
      });
    });
  }
  if (isMailTokenRecived.value) {
    isCounter.value = !isCounter.value;
    if (isCounter.value) {
      useUserStore.refreshEmailSingUp(userData.email);
      intervalId = setInterval(() => {
        if (sentCounter.value < 30) {
          sentCounter.value += 1;
        } else {
          sentCounter.value = 0;
          clearInterval(intervalId);
          isCounter.value = !isCounter.value;
        }
      }, 1000);
    }
  }
};

const restoreAcc = () => {
  emit("hide_popup", "ca");
  nextTick(() => {
    usePopUpServices.callPopUp({
      componentName: "login-restore-pop-up",
      persistent: false,
    });
  });
};

const singInWithGoogleSilent = async () => {
  googleButtLoading.value = true;
  if (window.google?.accounts) {
    useUserStore.initGoogleAuth();
    window.google?.accounts?.id?.prompt();
  } else {
    singInWithGoogle();
    googleButtLoading.value = false;
  }
  setTimeout(() => {
    googleButtLoading.value = false;
  }, 2000);
};

const singInWithGoogle = async () => {
  const config = useRuntimeConfig();
  const baseUrl = config.public.baseURL as string;
  window.location.href = baseUrl + "/api/auth/google";
};

const passwordValidation = (value: string) => {
  if (props.data === "login") return true;
  if (isMailTokenRecived.value)
    return Boolean(value) || "Please, paste the code from email.";
  const regex =
    /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9a-zA-Z?!@#$%^&*`'"(){}+,-\~_.\/|J:;<>= ])[0-9a-zA-Z?!@#$%^&*`'"(){}+,-\~_.\/|J:;<>= ]{8,}/g;
  if (!regex.test(value)) {
    return `${t("login.log_password_rules")}`;
  }
  return true;
};

const checkUserClipboard = async () => {
  try {
    const clipboardText = await navigator.clipboard.readText();
    if (typeof clipboardText === "string" && clipboardText.length === 12) {
      userData.password = clipboardText;
    }
  } catch (err) {
    console.error(err);
  }
};

const checkingUserName = ref(false);
const checkUserNameTaken = async (value: string | number | null) => {
  if (!value) return;
  value = userData.userName = value.toString().replace(/\s+/g, "-");
  checkingUserName.value = true;
  const res = await useUserStore.checkUserName(value.toString());
  checkingUserName.value = false;
  if (
    res?.errors &&
    Array.isArray(res?.errors?.errors) &&
    res?.errors.errors.length
  ) {
    isUsernameTaken.value = true;
  } else {
    isUsernameTaken.value = false;
  }
  userNameRef.value?.validate();
};

const validateUserName = computed(() => {
  if (!userData.userName) return false;
  const regex = /^[a-zA-Z\-_]+$/;
  return regex.test(String(userData.userName));
});

onMounted(() => {
  watch(
    () => $q.appVisible,
    (val) => {
      if (val && isMailTokenRecived.value) {
        isUserTurnedBack.value = true;
      }
    }
  );
  // watch(
  //   () => userData.userName,
  //   () => {
  //     // isUsernameTaken.value = false;
  //     userNameRef.value?.validate();
  //   }
  // );
});
</script>

<style scoped lang="scss">
.sign-in {
  position: relative;
  display: inline-flex;
  min-width: 492px;
  max-width: 492px;
  height: fit-content;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 24px;
  border: 1px solid var(--gray-40);
  background: var(--white-contrast);
  max-height: 90dvh;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-40);
    border-radius: 6px;
    border: 2px solid transparent;
  }

  @supports not selector(::-webkit-scrollbar) {
    * {
      scrollbar-color: var(--violet-secondary-light)
        var(--violet-secondary-light);
    }
  }
  @media (max-width: 65rem) {
    min-width: unset;
    width: 90vw;
  }
  &__header {
    .title-underlined {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 4px;
        bottom: -1px;
        left: 0;
        background-color: var(--violet-main);
      }
    }
    .close-butt {
      position: absolute;
      right: 16px;
      top: 16px;
      width: 28px;
      height: 28px;
      margin: 0;
      outline: none;
      border: none;
      border-radius: 50%;
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      background: var(--Monochrome-White, #e9ebf8);
      cursor: pointer;
    }
  }

  &__body {
    width: 100%;
    display: grid;
    gap: 5px;
    :deep(.gridbox) {
      .q-field--outlined .q-field__control {
        border-radius: 16px;
        max-height: 48px;
        &:after {
          max-height: 48px;
        }
      }
      .q-field__marginal {
        max-height: 48px;
      }
      .q-field__native {
        color: var(--Monochrome-Black, #121212);
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        max-height: 48px;
      }
      .q-field__control-container {
        max-height: 48px;
      }
      .q-field--error .q-field__bottom {
        color: var(--Color-Secondary-Negative, #ef4444);
        padding-left: 0px;
        font-family: "DM Sans";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        padding: 6px 12px 0px;
        text-align: left;
      }
    }

    .gridbox {
      width: 100%;
      display: grid;
      gap: 6px;
      .flexbox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-tide {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 1px;
        }
        .forgot-pass {
          border: none;
          background: none;
          outline: none;
          margin: 0;
          padding: 0;
          cursor: pointer;
          transition: scale 0.3s ease;
          &:hover {
            scale: 1.05;
          }
        }
      }
    }
    .flexbox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      input[type="checkbox"] {
        display: none;
      }

      input[type="checkbox"] + label {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border-radius: 3px;
        border: 2px solid;
        border-color: var(--gray-base);
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-family: "DM Sans";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 13px */
      }

      input[type="checkbox"]:checked + label {
        background-color: var(--black-monochrome);
        border: none;
      }

      input[type="checkbox"]:checked + label:after {
        content: "";
        position: absolute;
        top: 1px;
        left: 6px;
        width: 7px;
        height: 13px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    sup {
      font-family: "Cabinet Grotesk";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      color: var(--negative-sec);
      top: -0.2em;
    }
  }
  &__controls {
    width: 100%;
    display: grid;
    align-items: center;
    gap: 12px;
    justify-items: center;
    .sign-butt.base-button {
      width: 100%;
    }
    .flexbox {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      .base-button {
        width: 100%;
      }
    }
    .has-account {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      button {
        color: var(--Color-Main_Violet, #5a2fcf);
        outline: none;
        text-decoration: underline;
        margin: 0;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
      }
    }
  }
}
@media (max-width: 65rem) {
  @include mobile-fonts;
}
</style>
