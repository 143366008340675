<template>
  <div
    class="backdrop"
    :style="{
      backgroundColor: props.noBackground
        ? 'transparent'
        : 'var(--white-monochrome)',
    }"
  >
    <span class="loader" />
    <span v-if="props.hint" class="hint font--b5 text-black-monochrome">{{
      props.hint
    }}</span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  opacity: {
    type: Number,
    required: false,
    default: 1,
  },
  hint: {
    type: String,
    required: false,
  },
  noBackground: {
    type: Boolean,
    required: false,
  },
});
</script>

<style scoped lang="scss">
.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99999999999999;
  opacity: v-bind(opacity) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid var(--black-monochrome-60);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}
</style>
