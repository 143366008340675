<template>
  <item-editor-admin-unit :tabs="tabs" >
    <template #bubbles="{ currentItem }">
      <span class="bubble">
        <base-icon name="base/Farm" color="var(--violet-main)" />
        <p class="font--b2 mobile-font--b4 text-violet-main">Current use</p>
        <q-select
          v-model="currentItem.currentUse"
          standout
          :options="[
            'residential',
            'commercial',
            'industrial',
            'educational',
            'entertainment',
            'other',
          ]"
          emit-value
          map-options
          style="width: 100%"
        />
      </span>
      <span class="bubble">
        <base-icon name="base/Utilities" color="var(--violet-main)" />
        <p class="font--b2 mobile-font--b4 text-violet-main">Utilities</p>
        <q-select
          v-model="currentItem.utilities"
          standout
          :options="['yes', 'no', 'unknown']"
          emit-value
          map-options
          style="width: 100%"
        />
      </span>
      <span class="bubble">
        <base-icon name="base/Cube" color="var(--violet-main)" />
        <p class="font--b2 mobile-font--b4 text-violet-main">Acreage</p>
        <q-input
          v-model="currentItem.area"
          standout="bg-black text-white"
          type="number"
        />
      </span>
    </template>
    <template #finance="{ currentItem }">
      <section v-if="currentItem.finance" class="current__tab-finance">
        <div class="wrapper">
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">Land Value</p>
            </span>
            <q-input
              v-model="currentItem.finance.value"
              standout="bg-black text-white"
              label="Projected Rental Income"
              type="number"
            />
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">
                Tax assessed value
              </p>
            </span>
            <q-input
              v-model="currentItem.finance.taxAssessedValue"
              standout="bg-black text-white"
              label="Projection Appreciation"
              type="number"
            />
          </div>
          <div class="flexbox">
            <span class="icon-flex">
              <p class="font--b3 text-black-monochrome-60">Annual Tax Amount</p>
            </span>
            <q-input
              v-model="currentItem.finance.annualTaxAmount"
              standout="bg-black text-white"
              label="Projection Appreciation"
              type="number"
            />
          </div>
        </div>
      </section>
    </template>
    <template #searchCard="{ currentItem }">
      <admin-search-card-land-unit
          :property-info="currentItem"
          is-not-right-butt
        />
    </template>
  </item-editor-admin-unit>
 
</template>

<script setup lang="ts">
const tabs = [
  "About Property",
  "Finance",
  "Token",
  "Issuer",
  "Partners",
  "Legal",
  "Provider",
];
</script>

<style scoped lang="scss">
@import url(~/assets/styles/admin-unit-page/index.scss);
</style>
