// import { useI18n } from "vue-i18n";
import MarkdownIt from "markdown-it";

const md = new MarkdownIt();

export function renderTextWithTranslation(content: string | null | undefined) {
  // const { t } = useI18n();

  const translatedContent = content ?? "";
  // const translatedContent = t(content);
  const isHtml = /<\/?[a-z][\s\S]*>/i.test(translatedContent);

  if (isHtml) {
    return {
      html: true,
      rendered: translatedContent,
    };
  } else {
    return {
      html: false,
      rendered: md.render(translatedContent),
    };
  }
}
