<template>
  <section class="main-faq">
    <h2 class="main-faq__title">FAQs</h2>
    <section class="main-faq-tabs__hot">
      <q-tabs
        v-model="currentHotTab"
        dense
        class="custom-hot-tabs"
        align="justify"
      >
        <q-tab
          v-for="tab in faqsHeaders"
          :name="$rt(tab.key)"
          no-caps
          :class="{ 'active-tab': currentHotTab === $rt(tab.key) }"
        >
          {{ $rt(tab.title) }}
        </q-tab>
      </q-tabs>
    </section>
    <div class="main-faq--wrapper">
      <base-faq v-for="item in faqs" :key="item.title">
        <template #title>{{ $rt(item.title) }} </template>
        <template #details>
          {{ $rt(item.description) }}
        </template>
      </base-faq>
    </div>
    <base-button
      style="margin-top: 40px"
      :text="$t('main.m_find_properties')"
      variant="dark"
      size="sl"
      class="action-butt"
      @click="navigateToSearch"
    />
  </section>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
const currentHotTab = ref("general")
const { tm } = useI18n()
const localePath = useLocalePath()

const faqsHeaders = computed(() => {
  const faqList = tm("faq.list_headers") as unknown as {
    title: string
    key: string
  }[]
  return faqList ?? []
})

const faqs = computed(() => {
  const faqList = tm(`faq.${currentHotTab.value}`) as unknown as {
    title: string
    description: string
  }[]
  return faqList ?? []
})

const navigateToSearch = () => {
  navigateTo(localePath("/directory"))
}
</script>

<style scoped lang="scss">
.main-faq {
  width: 100dvw;
  max-width: 990px;
  min-width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 112px;
  margin-bottom: 80px;

  &--wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__title {
    width: 100%;
    padding-bottom: 20px;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
  }

  &--link {
    all: unset;
    margin-top: 60px;
  }
  :deep(.custom-hot-tabs) {
    .q-tabs__content {
      display: flex;
      white-space: nowrap;
    }
    .q-tab__indicator {
      background: var(--violet-main) !important;
    }

    .q-tab__content {
      gap: 8px;
      padding: 0;
      // flex-wrap: nowrap;
      word-break: break-word;
      overflow-wrap: break-word;
      white-space: normal;
      min-width: 0;
    }
  }
}
.main-faq-tabs {
  &__hot {
    margin-top: 8px;
    border-bottom: 1px solid var(--gray-monochrome-40);
    margin-bottom: 32px;
    width: 100%;
    :deep(.custom-hot-tabs) {
      .q-tab {
        display: flex;
        // width: 188px;
        padding: 17px 20px;
        flex-direction: column;
        align-items: center;
        // gap: 8px;
        border-radius: 16px 16px 0px 0px;
        background: transparent;
        font-size: 18px;
        font-weight: 400;
        min-width: fit-content;
      }
      .active-tab {
        background: var(--white-contrast);
        font-weight: 500;
      }
    }
    :deep(.q-tabs--horizontal .q-tabs__arrow) {
    height: 100%;
    box-shadow: var(--shadow-light);
    background: var(--white-contrast);
    max-width: 20px;
    min-width: 20px;
    border-radius: 2px;
    }
  }
}

@media (max-width: 88rem) {
  .main-faq-tabs {
    &__hot {
      :deep(.custom-hot-tabs) {
        .q-tab {
          padding: 17px 20px 15px;
        }
      }
    }
  }
}

@media (max-width: 75rem) {
  .main-faq {
    &__title {
      font-size: 22px;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .action-butt {
      width: 100%;
    }
  }
  .main-faq-tabs {
    gap: 32px;
    :deep(.custom-hot-tabs) {
      .q-tab__content {
        // gap: 8px;
        padding: 0;
      }
    }
    &__hot {
      margin-top: 4px;
      border-bottom: 1px solid var(--gray-monochrome-40);
      margin-bottom: 16px;
      overflow-x: auto;
      width: 100%;
      :deep(.custom-hot-tabs) {
        .q-tab {
          display: flex;
          // max-width: 100px;
          min-width: 100px;
          padding: 7px 8px 7px;
          font-size: 12px;
          font-weight: 400;
          white-space: wrap;
          line-height: 1.1;
        }
        .active-tab {
          background: var(--white-contrast);
        }
      }
    }
  }
}

@media (max-width: 32rem) {
  @include mobile-fonts;

  .main-faq {
    align-items: flex-start;
    padding: 0 24px;
    margin-top: 53px;
    &--title {
      padding-bottom: 32px;
    }
    &--link {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}
</style>
