<template>
  <div class="third-party-login" />
</template>

<script setup lang="ts">
import { userStore } from "~/store/user";
import { useQuasar } from "quasar";
import { getLocalStorage } from "~/services/LocalStorage/localStorage";

const $q = useQuasar();
const useUserStore = userStore();
const route = useRoute();
const token = route.query.token;
const startParam = route.query.startParam;
const email = getLocalStorage("user_email");
const localePath = useLocalePath();

onMounted(async () => {
  const decodedQuery = decodeURIComponent(atob(startParam as string));
  if (typeof token === "string" && typeof email === "string") {
    try {
      $q.loading.show();
      await useUserStore.checkSignUpEmailToken({ token: token, email: email });
      if (route.query.startParam) {
        return navigateTo(localePath(`/directory?${decodedQuery}`));
      }
      navigateTo(localePath("/profile"));
    } catch (error) {
      console.log("error: ", error);
      navigateTo(localePath("/"));
    } finally {
      $q.loading.hide();
    }
  } else {
    navigateTo(localePath("/"));
  }
});

onBeforeUnmount(() => {
  $q.loading.hide();
});
</script>

<style scoped lang="scss">
.third-party-login {
  width: 100dvw;
  height: 100dvh;
}
</style>
