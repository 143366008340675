<template>
  <section class="tokenization">
    <div class="card card__first" v-intersection="onIntersectionFirstBlock">
      <p class="font--h3 mobile-font--h6">
        {{ $t("main.tokenization.card__first") }}
      </p>
    </div>
    <div class="blocks blocks--first">
      <div v-if="animateFirstBlock" class="block-wrapper" style="left: 0">
        <span
          v-for="item in firstBlockArr"
          class="blocks__block animate__animated"
          :class="[
            { animate__slideInRight: !$device.isMobile },
            { animate__slideInUp: $device.isMobile },
          ]"
          :style="{ marginLeft: item.margin, animationDelay: item.delay }"
        >
          <p class="font--b2-3 mobile-font--b5-1">{{ item.title }}</p>
          <p class="font--b5 mobile-font--b7">
            {{ item.text }}
          </p>
        </span>
      </div>
    </div>
    <div class="blocks blocks--second">
      <div
        v-if="animateSecondBlock"
        class="block-wrapper"
        style="right: 0; justify-items: end"
      >
        <span
          v-for="item in secondBlockArr"
          class="blocks__block animate__animated"
          :class="[
            { animate__slideInLeft: !$device.isMobile },
            { animate__slideInUp: $device.isMobile },
          ]"
          :style="{ marginRight: item.margin, animationDelay: item.delay }"
        >
          <p class="font--b2-3 mobile-font--b5-1">
            {{ item.title }}
          </p>
          <p class="font--b5 mobile-font--b7">
            {{ item.text }}
          </p>
        </span>
      </div>
    </div>
    <div class="card card__second" v-intersection="onIntersectionSecondBlock">
      <p class="font--h3 mobile-font--h6">
        {{ $t("main.tokenization.card__second") }}
      </p>
    </div>
  </section>
</template>

<script setup lang="ts">
const { isMobile } = useDevice();

const { t } = useI18n();

const animateFirstBlock = ref(false);
const animateSecondBlock = ref(false);

const onIntersectionFirstBlock = (entry: IntersectionObserverEntry) => {
  if (entry.isIntersecting) {
    animateFirstBlock.value = true;
  }
};
const onIntersectionSecondBlock = (entry: IntersectionObserverEntry) => {
  if (entry.isIntersecting) {
    animateSecondBlock.value = true;
  }
};

const firstBlockArr = computed(() => [
  {
    title: t("main.tokenization.first_block.process.title"),
    text: t("main.tokenization.first_block.process.text"),
    margin: !isMobile ? "-60px" : "0",
    delay: "0s",
  },
  {
    title: t("main.tokenization.first_block.income.title"),
    text: t("main.tokenization.first_block.income.text"),
    margin: !isMobile ? "-100px" : "0",
    delay: "100ms",
  },
  {
    title: t("main.tokenization.first_block.market.title"),
    text: t("main.tokenization.first_block.market.text"),
    margin: !isMobile ? "-140px" : "0",
    delay: "200ms",
  },
]);

const secondBlockArr = computed(() => [
  {
    title: t("main.tokenization.second_block.easy.title"),
    text: t("main.tokenization.second_block.easy.text"),
    margin: !isMobile ? "-60px" : "0",
    delay: "0s",
  },
  {
    title: t("main.tokenization.second_block.clear.title"),
    text: t("main.tokenization.second_block.clear.text"),
    margin: !isMobile ? "-100px" : "0",
    delay: "100ms",
  },
  {
    title: t("main.tokenization.second_block.diverse.title"),
    text: t("main.tokenization.second_block.diverse.text", {
      price: `${BASE_CURRENCY.value.symbol}${Math.trunc(BASE_CURRENCY.value.rate * 50).toLocaleString()}`,
    }),
    margin: !isMobile ? "-140px" : "0",
    delay: "200ms",
  },
]);
</script>

<style scoped lang="scss">
.tokenization {
  position: relative;
  display: grid;
  margin-top: 119px;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: auto;
  padding-left: 156px;
  padding-right: 156px;
  row-gap: 160px;
  overflow: hidden;

  .card {
    width: 514px;
    height: 640px;
    flex-shrink: 0;
    border-radius: 16px;
    display: flex;
    align-items: flex-start;
    padding: 24px;

    &__first {
      background: url(~/public/img/main/card-1.png) lightgray 50% / cover
        no-repeat;
      justify-self: flex-end;
      text-align: left;
    }
    &__second {
      background: url(~/public/img/main/card-2.png) lightgray 50% / cover
        no-repeat;
      justify-self: end;
      text-align: right;
    }
  }
  .blocks {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 22px;
    align-self: flex-start;
    z-index: 2;
    &--first {
      margin-top: 49px;
    }
    &--second {
      margin-top: 143px;
    }
    &__block {
      display: flex;
      width: 536px;
      max-width: 536px;
      height: fit-content;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      border-radius: 24px;
      border: 2px solid var(--gray-monochrome-40);
      background: var(--white-contrast);
      box-shadow: 0px 0px 6.877px 0px rgba(0, 0, 0, 0.08);
    }
    .block-wrapper {
      position: absolute;
      top: 0;
      display: grid;
      align-items: center;
      justify-content: center;
      gap: 22px;
      align-self: flex-start;
    }
  }
}
@media (max-width: 75rem) {
  @include mobile-fonts;
  .tokenization {
    margin-top: 58px;
    padding: 0;
    grid-template-columns: 1fr;
    gap: 0;
    justify-items: center;
    .card {
      width: 345px;
      height: 427px;
      justify-self: center;
      &__second {
        order: 3;
        margin-top: 60px;
      }
    }
    .blocks {
      &--second {
        order: 4;
        margin-top: -68px;
        max-width: 88%;
      }
      &--first {
        margin-top: -68px;
        max-width: 88%;
      }
      .block-wrapper {
        position: relative;
        gap: 8px;
      }
      &__block {
        padding: 16px;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
</style>
